import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
// @ts-ignore
import { SearchController } from '@sdv-streaming/web';

import { singleton } from '@sdv/commons/utils/singleton';

import { Resources } from 'web/src/resources';
import { AlertsService } from 'web/src/services/alerts';

export class SearchService {
    static readonly shared = singleton(() => new SearchService());

    private controller = SearchController.shared();

    private readonly loadingSubject = new BehaviorSubject(false);

    readonly opened = this.controller.isSearchBarOpened;

    readonly resultsVisible = this.controller.resultsVisible;

    readonly searchResults = this.controller.searchedStreamersWithViewers.pipe(
        tap(() => this.loadingSubject.next(false)),
    );

    readonly loading = this.loadingSubject.asObservable();

    private constructor() {
        this.controller.error.pipe(distinctUntilChanged()).subscribe((err: any) => {
            if (err) {
                this.loadingSubject.next(false);
                AlertsService.shared().show(Resources.strings.network.errors.common);
            }
        });
    }

    openSearchBar = () => {
        return this.controller.openSearchBar();
    };

    closeSearchBar = () => {
        this.loadingSubject.next(false);
        return this.controller.closeSearchBar();
    };

    setQuery = (query: string) => {
        if (query.length >= this.controller.MIN_QUERY_LENGTH) {
            this.loadingSubject.next(true);
        }
        return this.controller.setQuery(query);
    };
}
