import Alt from '@sdv/alt';

type State = {
    amount?: number;
    totalSpent?: number;
};

export type UpdatePayload = {
    balance: number;
    change?: number;
};

type Actions = {
    get: () => (dispatch: Alt.Dispatch) => void;
    update: (payload: UpdatePayload) => (dispatch: Alt.Dispatch) => void;
};

// eslint-disable-next-line global-require
const actions = require('./actions').default as (userId: string) => new () => Actions;
// eslint-disable-next-line global-require
const store = require('./store').default as (userId: string) => new (flux: Alt) => Alt.Store<State>;

export const PaymentCoinsBalanceModel = {
    store,
    actions,
};
