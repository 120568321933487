import { Link } from 'web/src/components/commons/link';
import { ScreenRoutes } from 'web/src/constants/routing';
import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

// TODO: update the screen with an actual design
export const NotFoundScreen = () => {
    return (
        <div className={classes.container}>
            <h2>{Resources.strings['not-found'].title}</h2>
            <p>
                {Resources.strings.formatString(
                    Resources.strings['not-found'].text,
                    <Link to={ScreenRoutes.Dashboard}>{Resources.strings['main-page']}</Link>,
                )}
            </p>
        </div>
    );
};
