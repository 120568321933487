import classNames from 'classnames';
import Lottie from 'lottie-react';
import { HTMLAttributes, memo } from 'react';

import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type Props = HTMLAttributes<HTMLDivElement>;

export const ActivityIndicator = memo((props: Props) => {
    const { className, ...otherProps } = props;
    return (
        <div className={classNames(classes.loader, className)}>
            <Lottie animationData={Resources.animations.activityIndicator()} loop {...otherProps} />
        </div>
    );
});
