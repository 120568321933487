import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextInput } from 'web/src/components/commons/textInput';
import { Button } from 'web/src/components/commons/button';
import { AuthService } from 'web/src/services/auth';
import { TermsText } from 'web/src/screens/signIn/form/terms-text';
import { Resources } from 'web/src/resources';

import { validationSchema } from './validation-schema';
import { FormFields } from './types';

import classes from './styles.module.scss';

export const SignUpForm = memo(() => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<FormFields>({
        resolver: yupResolver(validationSchema),
    });

    const handleFormSubmit = useCallback(
        ({ email, password }: FormFields) => AuthService.shared().signUp(email, password),
        [],
    );

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form} noValidate>
            <TextInput
                {...register('email')}
                type="email"
                placeholder={Resources.strings.email}
                error={errors.email?.message}
            />
            <TextInput
                {...register('password')}
                type="password"
                placeholder={Resources.strings.password}
                error={errors.password?.message}
            />
            <TermsText className={classes.terms} />
            <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isDirty}
                className={classes.signUpBtn}
                appearance="primary"
            >
                {Resources.strings['sign-up']}
            </Button>
        </form>
    );
});
