import { memo, PropsWithChildren } from 'react';

import { useMatchMedia, MediaSize } from 'web/src/hooks/mediaQuery';

type Props = {
    size: MediaSize;
};

export const Media = memo((props: PropsWithChildren<Props>) => {
    const { size, children } = props;

    const visible = useMatchMedia(size);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{visible && children}</>;
});
