import { of } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import { Config as ConfigType } from '@sdv/domain/app/config.contracts';
import { NetworkEnvironment } from '@sdv/network/config';
import { ConfigService } from '../services/config';

export class Config implements ConfigType {
    static shared = singleton(() => new Config());

    private clientConfig = ConfigService.shared().config;

    private constructor() {
        //
    }

    readonly analytics = of([]);

    readonly appId = of(this.clientConfig.appId);

    readonly iosAppId = of('');

    readonly currentVersion = of(this.clientConfig.appVersion);

    readonly actualVersion = of(this.clientConfig.version.actual);

    readonly criticalVersion = of(this.clientConfig.version.critical);

    readonly networkEnvironment = of(this.clientConfig.networkEnvironment as NetworkEnvironment);

    readonly streamingModuleEnabled = of(true);

    readonly productName = of(this.clientConfig.productName);

    readonly currentDevice = of(this.clientConfig.currentDevice);

    readonly minLogLevel = of(this.clientConfig.logger['min-log-level']);

    readonly purchasesAutoRestoringEnabled = of(
        this.clientConfig.features['purchases-auto-restoring-enabled'],
    );

    readonly activeExperiments = of(this.clientConfig.activeExperiments);

    readonly streamingProduct = of(this.clientConfig.streaming.product);

    readonly countryTaxes = of(this.clientConfig.countryTaxes);

    readonly google = of(this.clientConfig.google);
}
