import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { App } from './app';

import './index.scss';
import { ROOT_ID } from './constants/selectors';
import { injectVersion } from './utils/inject-version';

const root = createRoot(document.getElementById(ROOT_ID) as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

injectVersion();
