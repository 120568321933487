import { Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { IdentityModel } from '@sdv/domain/identity/model';
import { Flux } from '@sdv/domain/flux';
import { unwrap } from '@sdv/commons/rx/operators/unwrap';
import { CredentialsStorage } from '@sdv/domain/authorization/credentials/storage';
import { singleton } from '@sdv/commons/utils/singleton';
import { Service } from '@sdv/commons/service';

export class CredentialsSaver implements Service {
    static shared = singleton(() => new CredentialsSaver())

    start(unsubscription: Subscription) {
        unsubscription.add(
            Flux.get(IdentityModel).store
                .rxState()
                .pipe(
                    map(it => it.token),
                    unwrap(),
                    distinctUntilChanged(),
                )
                .subscribe((token) => {
                    CredentialsStorage.shared().setToken(token);
                })
        )
    }
}
