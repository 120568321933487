import classNames from 'classnames';
import {
    ComponentProps,
    memo,
    PropsWithChildren,
    Ref,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { useMatchMedia } from 'web/src/hooks/mediaQuery';

import { Header } from './header';
import { Sidebar } from './sidebar';

import classes from './styles.module.scss';

type Props = PropsWithChildren<{
    onSignOut: ComponentProps<typeof Sidebar>['onSignOut'];
    /**
     * `true` by default
     */
    scrollEnabled?: boolean;
    scrollContainerRef?: Ref<HTMLDivElement>;
}> &
    Pick<ComponentProps<typeof Header>, 'user' | 'userBalance'>;

export default memo((props: Props) => {
    const {
        children,
        user,
        userBalance,
        onSignOut,
        scrollEnabled = true,
        scrollContainerRef,
    } = props;

    const isTabletOrDesktop = useMatchMedia('tabletOrDesktop');

    const [sidebarVisible, setSidebarVisible] = useState(isTabletOrDesktop);

    useEffect(() => {
        setSidebarVisible(isTabletOrDesktop);
    }, [isTabletOrDesktop]);

    const closeSidebar = useCallback(() => {
        setSidebarVisible(false);
    }, []);

    const toggleSidebar = useCallback(() => {
        setSidebarVisible(prev => !prev);
    }, []);

    const disableScroll =
        !scrollEnabled ||
        // disable scroll when mobile screen size and the sidebar is opened (cause it covers the screen)
        (!isTabletOrDesktop && sidebarVisible);

    return (
        <div className={classes.dashboard}>
            <Sidebar
                isLoggedIn={!!user}
                onSignOut={onSignOut}
                onClose={closeSidebar}
                visible={sidebarVisible}
            />
            <div
                className={classNames(classes.rightContent, {
                    [classes.noScroll]: disableScroll,
                })}
                ref={scrollContainerRef}
            >
                <Header
                    userBalance={userBalance}
                    user={user}
                    sidebarVisible={sidebarVisible}
                    onToggleSidebar={toggleSidebar}
                />
                <div className={classes.contentContainer}>{children}</div>
            </div>
        </div>
    );
});
