import { FacebookLoginClient, InitParams, LoginResponse } from '@greatsumini/react-facebook-login';
import { api } from '@sdv/commons/api';

import { Gender } from '@sdv/domain/user/gender';

import { clientConfig } from 'web/src/app/config/client';

import { SocialLoginClient, SocialUserProfile } from './types';

export type FacebookProfile = {
    email?: string;
    id: string;
    first_name: string;
    gender?: string;
    birthday?: string;
    picture?: {
        data?: {
            is_silhouette?: boolean;
            url?: string;
        };
    };
};

class CustomFacebookLoginClient implements SocialLoginClient {
    private client: typeof FacebookLoginClient | null = null;

    constructor() {
        FacebookLoginClient.loadSdk('en_US').then(() => {
            FacebookLoginClient.init({
                appId: clientConfig.facebook.appId,
                version: 'v16.0' as InitParams['version'],
                xfbml: true,
            });
            this.client = FacebookLoginClient;
        });
    }

    login = async () => {
        try {
            const { authResponse } = await new Promise<LoginResponse>(resolve => {
                this.client?.login(resolve, {
                    scope: clientConfig.facebook.scopes.join(','),
                });
            });
            if (!authResponse) {
                return { data: null };
            }
            const { accessToken: token } = authResponse;

            const user = await new Promise<FacebookProfile>(resolve =>
                FacebookLoginClient.getProfile(response => resolve(response as FacebookProfile), {
                    fields: clientConfig.facebook['user-fields'].join(','),
                }),
            );

            const socialUser: SocialUserProfile = {
                name: user.first_name,
                email: user.email || `fb-${user.id}@${api.baseHost.split('.')[1]}.com`,
            };

            if (user.gender && ['male', 'female'].indexOf(user.gender) >= 0) {
                socialUser.gender = user.gender === 'male' ? Gender.Male : Gender.Female;
            }

            if (user.birthday) {
                socialUser.birthday = new Date(user.birthday).toISOString();
            }

            const userPictureData = user.picture?.data;

            if (userPictureData) {
                const isUserPhoto = !userPictureData.is_silhouette;
                const userPhotoUrl = userPictureData.url;

                if (isUserPhoto && userPhotoUrl) {
                    socialUser.picture = userPhotoUrl;
                }
            }

            return {
                data: {
                    token,
                    user: socialUser,
                },
            };
        } catch (error) {
            return {
                error,
            };
        }
    };

    logout = () => {
        this.client?.logout(() => {});
    };
}

export { CustomFacebookLoginClient as FacebookLoginClient };
