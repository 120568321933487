// @ts-expect-error
import XMLHttpRequest from 'xhr2';

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type Request = {
    method?: RequestMethod;
    headers?: {
        [key: string]: any;
    };
    body?: any;
};

export default function request(
    url: string,
    options: Request = { headers: {}, body: null, method: 'GET' },
    onProgress?: (event: any) => void,
) {
    return new Promise<{
        responseText?: string;
        response: string;
        status: number;
    }>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open(options.method || 'get', url, true);

        for (const key in options.headers) {
            if (options.headers.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, options.headers[key]);
            }
        }

        xhr.onload = (e: any) => {
            resolve(e.target);
        };
        xhr.onerror = reject;

        if (xhr.upload && onProgress) {
            const handler = (event: ProgressEvent) => {
                onProgress(event);

                if (!event.lengthComputable) {
                    xhr.upload.removeEventListener('progress', handler);
                }
            };

            xhr.upload.addEventListener('progress', handler, false);
        }

        xhr.send(options.body);
    });
}

export { request };
