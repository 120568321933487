import axios from 'axios';

import { context } from '../api-context';
import { ApiGetCoinsBalance, StockPackage } from './types';

export const credits = {
    credits: {
        accounts: (userId: string) => ({
            balance: {
                get: () =>
                    axios.get<ApiGetCoinsBalance>(
                        `${context.server}/credits/accounts/${userId}/balance`,
                        {
                            headers: context.authHeaders,
                        },
                    ),
            },
        }),
        mall: {
            credits: (userId: string) => ({
                get: (shop: string) =>
                    axios.get<StockPackage[]>(
                        `${context.server}/credits/mall/${userId}/credits?shop=${shop}`,
                        {
                            headers: context.authHeaders,
                        },
                    ),
            }),

            exchanges: (userId: string) => ({
                post: (body?: any) =>
                    axios.post(`${context.server}/credits/mall/${userId}/exchanges`, body, {
                        headers: context.patchHeaders,
                    }),
            }),

            receipts: (userId: string) => ({
                post: (body?: any) =>
                    axios.post(`${context.server}/credits/mall/${userId}/receipts`, body, {
                        headers: context.patchHeaders,
                    }),
            }),
        },
    },
};
