import axios from 'axios';

import { context } from '../api-context';
import { ApiGetNotificationsSubscriptions, ApiPatchNotificationsSubscriptionsBody } from './types';

export const notifications = {
    notifications: {
        addresses: (userId: string) => ({
            post: (body?: any) =>
                axios.post(`${context.server}/notifications/addresses/${userId}`, body, {
                    headers: context.patchHeaders,
                }),

            delete: ({ address, channel }: { address: string; channel: string }) =>
                axios.delete(
                    `${context.server}/notifications/addresses/${userId}/${channel}/${address}`,
                    {
                        headers: context.patchHeaders,
                    },
                ),
        }),

        subscriptions: (userId: string, channel: string) => ({
            get: () =>
                axios.get<ApiGetNotificationsSubscriptions>(
                    `${context.server}/notifications/subscriptions/${channel}/${userId}`,
                    {
                        headers: {
                            ...context.authHeaders,
                            accept: 'application/vnd.subscriptions.v2+json',
                        },
                    },
                ),
            patch: (body: ApiPatchNotificationsSubscriptionsBody) =>
                axios.patch(
                    `${context.server}/notifications/subscriptions/${channel}/${userId}`,
                    body,
                    {
                        headers: context.patchHeaders,
                    },
                ),
        }),

        cloudmessages: {
            badges: (userId: string) => ({
                put: (body?: any) =>
                    axios.put(
                        `${context.server}/notifications/cloudmessages/badges/${userId}`,
                        body,
                        {
                            headers: context.patchHeaders,
                        },
                    ),
            }),
        },
    },
};
