import jwtDecode from 'jwt-decode';

import { singleton } from '@sdv/commons/utils/singleton';

import { clientConfig } from 'web/src/app/config/client';

import { SocialLoginClient, SocialLoginResponse } from './types';

type DecodedIdToken = {
    /**
     * The unique ID of the user's Google Account
     */
    sub: number;
    name: string;
    given_name: string;
    family_name: string;
    email: string;
    picture?: string;
    locale: string;
};

export type SuccessCallback = (data: SocialLoginResponse) => void;

// eslint-disable-next-line no-undef
type RenderOptions = google.accounts.id.GsiButtonConfiguration & {
    onSuccess?: SuccessCallback;
};

export class GoogleLoginClient implements SocialLoginClient {
    static readonly shared = singleton(() => new GoogleLoginClient());

    private client: typeof window.google.accounts.id | null = null;

    private cb: SuccessCallback | null = null;

    constructor() {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            this.client = window.google?.accounts?.id;

            this.client?.initialize({
                client_id: clientConfig.google.clientId,
                context: 'signin',
                callback: ({ credential }) => {
                    try {
                        const user = jwtDecode<DecodedIdToken>(credential);

                        this.cb?.({
                            data: {
                                token: credential,
                                user: {
                                    name: user.name,
                                    email: user.email,
                                    picture: user.picture,
                                },
                            },
                        });
                    } catch (error) {
                        this.cb?.({
                            error: new Error('Damaged id_token'),
                        });
                    }
                },
            });
        };

        document.body.appendChild(script);
    }

    login = async () => {
        return {
            error: new Error('Method not implemented. Use renderButton onSuccess option instead'),
        };
    };

    logout = () => {
        this.client?.disableAutoSelect();
    };

    renderButton = (parent: HTMLDivElement, { onSuccess, ...options }: RenderOptions) => {
        this.client?.renderButton(parent, options);
        this.cb = onSuccess || null;
    };

    unmountButton = () => {
        this.cb = null;
    };
}
