import { useEffect } from 'react';

import { ScreenRoutes } from 'web/src/constants/routing';
import { useNavigate, useLocation } from 'web/src/hooks/navigation';
import { QueryParams } from 'web/src/screens/signIn/types';

const ONLY_LOGGED_IN: string[] = [
    ScreenRoutes.CompleteProfile,
    ScreenRoutes.Live,
    ScreenRoutes.Purchase,
];

const ONLY_LOGGED_OUT: string[] = [ScreenRoutes.SignIn, ScreenRoutes.SignUp];

const ONLY_INCOMPLETE_PROFILE: string[] = [
    ScreenRoutes.Terms,
    ScreenRoutes.Privacy,
    ScreenRoutes.CompleteProfile,
];

/**
 * Protects private routes
 */
export const useRoutesGuard = (
    authenticated: boolean,
    profileCompleted: boolean,
    loading: boolean,
) => {
    const navigate = useNavigate();
    const {
        pathname,
        hash,
        search,
        query: { cameFrom },
    } = useLocation<QueryParams>();

    const fullPath = `${pathname}${hash}${search}`;
    useEffect(() => {
        if (loading) {
            return;
        }

        if (authenticated) {
            if (!profileCompleted && !ONLY_INCOMPLETE_PROFILE.includes(pathname)) {
                // if the user hasn't completed his onboarding we force him to complete it
                navigate(
                    {
                        pathname: ScreenRoutes.CompleteProfile,
                        query: { cameFrom: cameFrom || fullPath },
                    },
                    { replace: true },
                );
            } else if (
                ONLY_LOGGED_OUT.includes(pathname) ||
                (profileCompleted && pathname === ScreenRoutes.CompleteProfile)
            ) {
                navigate(cameFrom || ScreenRoutes.Dashboard, { replace: true });
            }
        } else if (ONLY_LOGGED_IN.includes(pathname)) {
            navigate(
                {
                    pathname: ScreenRoutes.SignIn,
                    query: { cameFrom: fullPath },
                },
                { replace: true },
            );
        }
    }, [authenticated, loading, navigate, pathname, cameFrom, fullPath, profileCompleted]);
};
