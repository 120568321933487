import { OS, Platform, Browser } from '@sdv/domain/system/platform.contracts';

export function operatingSystem(): OS {
    // TODO
    return 'win';
}

export function platform(): Platform {
    return 'web';
}

export function browser(): Browser | null {
    // TODO
    return 'chrome';
}
