import { RefCallback, useCallback, useRef, memo } from 'react';
import classnames from 'classnames';

import { Resources } from 'web/src/resources';
import { GoogleLoginClient, SuccessCallback } from 'web/src/services/auth/social-providers/google';

import classes from './styles.module.scss';

type Props = {
    onSuccess: SuccessCallback;
    disabled?: boolean;
    className?: string;
};

export const GoogleButton = memo((props: Props) => {
    const { onSuccess, disabled, className } = props;

    const parentRef = useRef<HTMLDivElement | null>(null);

    const handleRef: RefCallback<HTMLDivElement | null> = useCallback(
        ref => {
            if (!parentRef.current && ref) {
                GoogleLoginClient.shared().renderButton(ref, {
                    type: 'icon',
                    shape: 'circle',
                    size: 'large',
                    onSuccess,
                });
            }
            if (parentRef.current && !ref) {
                GoogleLoginClient.shared().unmountButton();
            }
            parentRef.current = ref;
        },
        [onSuccess],
    );

    return (
        <div
            className={classnames(classes.container, className, {
                [classes.disabled]: !!disabled,
            })}
        >
            <img src={Resources.images.googleIcon} alt="google icon" />
            {/* we cover our icon button with a google action button but set it's opacity to 0 */}
            <div ref={handleRef} className={classes.button} />
        </div>
    );
});
