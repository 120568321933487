import { memo } from 'react';

import { Button } from 'web/src/components/commons/button';
import { Resources } from 'web/src/resources';
import { useCurrentUserBalance } from 'web/src/hooks/user';
import { PurchaseNote } from 'web/src/screens/purchase/form/purchase-note';

import { COINS_PACKS } from './constants';

import classes from './styles.module.scss';

type Props = {
    onPackSelected: (id: number) => void;
};

export default memo((props: Props) => {
    const { onPackSelected } = props;

    const balance = useCurrentUserBalance();

    const handlePackSelected = (id: number) => () => onPackSelected(id);

    return (
        <div className={classes.container}>
            <p className={classes.have}>
                {Resources.strings['you-have']}
                <img src={Resources.images.coinIcon} alt="coin icon" />
                <span className={classes.amount}>{balance}</span>
            </p>
            {COINS_PACKS.map(pack => {
                const { id, coins, popular, price, currency, discount } = pack;
                return (
                    <Button
                        onClick={handlePackSelected(id)}
                        appearance="primary"
                        key={id}
                        className={classes.packItem}
                    >
                        <span className={classes.coins}>
                            <img src={Resources.images.coinIcon} alt="coin icon" />
                            {coins}
                            {popular && (
                                <span className={classes.popular}>
                                    <img src={Resources.images.favoriteIconFilled} alt="" />
                                    {Resources.strings.popular}
                                </span>
                            )}
                        </span>
                        <span className={classes.secondaryText}>
                            {price} {currency}
                        </span>
                        {!!discount && <span className={classes.discount}>-{discount}%</span>}
                    </Button>
                );
            })}
            <PurchaseNote className={classes.terms} />
        </div>
    );
});
