import { AxiosError, AxiosResponse } from 'axios';

export const isOK = (response: AxiosResponse) => response.status >= 200 && response.status < 400;

export const getToken = (xhr: XMLHttpRequest) => xhr.getResponseHeader('X-Token');

export const axiosErrorAdapter = (error: AxiosError | undefined) => {
    if (!error) return undefined;

    const { response, message } = error;

    if (response) {
        const { status, data } = response;
        const dataFields = typeof data === 'object' ? data : { data };

        return {
            message,
            status,
            ...dataFields,
        };
    }

    if (error.toJSON) {
        return error.toJSON();
    }

    return error;
};
