import { Flux } from '@sdv/domain/flux';
import { IdentityModel } from '@sdv/domain/identity/model';
import { singleton } from '@sdv/commons/utils/singleton';
import { UserRepository } from '@sdv/domain/user/repositories';
import { combineLatest, Observable } from 'rxjs';
import Session from '@sdv/domain/authorization/session';
import { map, filter, switchMap } from 'rxjs/operators';

export class UserAuthStatus {
    static shared = singleton(() => new UserAuthStatus());

    readonly importantFieldsIsFilled: Observable<boolean>;

    readonly needToCredUpdating: Observable<boolean>;

    constructor() {
        const identityModel = Flux.get(IdentityModel);
        const identityRxState = identityModel.store.rxState();

        const { userId } = Session.shared();

        this.importantFieldsIsFilled = userId.pipe(
            filter(Boolean),
            switchMap(currentUserId =>
                combineLatest([
                    UserRepository.shared(currentUserId).user,
                    identityRxState.pipe(map(identity => identity.roles || [])),
                ]),
            ),
            map(([user = {}, userRoles = []]) => {
                const userTags = user.tags || [];
                const userIsPromoter =
                    (userRoles as Array<string>).includes('promoter') ||
                    userTags.includes('dialogs.messages.promoter');

                if (userIsPromoter) {
                    return !!user.name && user.name !== 'Anonymous';
                }

                return !!user.birthday && !!user.gender && !!user.name && user.name !== 'Anonymous';
            }),
        );

        this.needToCredUpdating = combineLatest([
            this.importantFieldsIsFilled,
            Session.shared().userIsUsingDemoMode,
        ]).pipe(
            map(([importantFieldsIsFilled, userIdDemo]) => {
                return !importantFieldsIsFilled || userIdDemo;
            }),
        );
    }
}
