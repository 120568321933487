/* eslint-disable no-console */
import { CredentialsStorage } from '@sdv/domain/authorization/credentials/storage';
import { api, axiosErrorAdapter } from '@sdv/commons/api';
import { composeEmail, isDemoEmail } from '@sdv/domain/utils/demo';
import { generateGuid } from '@sdv/commons/utils/guid';
import { updateStreamingEmail } from '@sdv/domain/streaming/email-updating';
import { Logger } from '@sdv/domain/logger';
import { Buffer } from 'buffer';

export const OAuthSystems = {
    Apple: 'apple',
    Google: 'google',
    Facebook: 'facebook',
};

export const oauthSystems = {
    [OAuthSystems.Google]: 'Google',
    [OAuthSystems.Apple]: 'Apple',
    [OAuthSystems.Facebook]: 'Facebook',
};

function getToken(responseHeaders) {
    return responseHeaders['x-token'];
}

function createIdentityActions(id = 'default') {
    class Actions {
        auth = token => dispatch => {
            api.identity(`Token token="${token}"`)
                .get()
                .then(response => {
                    const identity = response.data;

                    api.setCredentials(identity.id, token);

                    identity.token = token;

                    dispatch({
                        ...identity,
                        authorizationMethod: 'signin:token',
                        demo: isDemoEmail(identity.email, api.baseHost),
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        updatePassword = password => dispatch => {
            api.identity(api.authHeaders.authorization)
                .patch({ password })
                .then(() => {
                    return dispatch({
                        password,
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        signUp = (email, password) => (dispatch, flux) => {
            const currentIdentity = flux.getStore(Actions.displayName).getState();
            const isDemo = currentIdentity.id && currentIdentity.demo;
            const trimmedEmail = email.trim();

            if (isDemo) {
                api.identity(api.authHeaders.authorization)
                    .patch({ email: trimmedEmail, password })
                    .then(response => {
                        const token = getToken(response.headers);
                        api.setCredentials(currentIdentity.id, token);

                        updateStreamingEmail(trimmedEmail);

                        dispatch({
                            token,
                            email: trimmedEmail,
                            password,
                            authorizationMethod: 'signup:password',
                            demo: false,
                        });
                    })
                    .catch(err => dispatch(null, axiosErrorAdapter(err)));
            } else {
                const basicCredentials = Buffer.from(`${trimmedEmail}:${password}`).toString(
                    'base64',
                );

                api.identity(`Basic ${basicCredentials}`)
                    .put({ email: trimmedEmail, password })
                    .then(response => {
                        const identity = response.data;
                        const token = getToken(response.headers);

                        api.setCredentials(identity.id, token);

                        identity.token = token;

                        dispatch({
                            ...identity,
                            authorizationMethod: 'signup:password',
                            demo: false,
                        });
                    })
                    .catch(err => dispatch(null, axiosErrorAdapter(err)));
            }
        };

        changeEmailToAnonymous = () => (dispatch, flux) => {
            const email = composeEmail(api.baseHost);
            const currentIdentity = flux.getStore(Actions.displayName).getState();

            api.identity(api.authHeaders.authorization)
                .patch({ email })
                .then(() => {
                    return dispatch({
                        ...currentIdentity,
                        authorizationMethod: 'signup:password',
                        demo: true,
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        signUpAsAnonymous = () => dispatch => {
            const username = composeEmail(api.baseHost);
            const password = generateGuid();
            const basicCredentials = Buffer.from(`${username}:${password}`).toString('base64');

            api.identity(`Basic ${basicCredentials}`)
                .put({ email: username, password })
                .then(response => {
                    const identity = response.data;
                    const token = getToken(response.headers);

                    api.setCredentials(identity.id, token);

                    identity.token = token;

                    dispatch({
                        ...identity,
                        authorizationMethod: 'signup:password',
                        demo: true,
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        loginUser = (email, password) => dispatch => {
            const trimmedEmail = email.trim();

            const basicCredentials = Buffer.from(`${trimmedEmail}:${password}`).toString('base64');

            api.identity(`Basic ${basicCredentials}`)
                .get()
                .then(response => {
                    const identity = response.data;
                    const token = getToken(response.headers);

                    identity.token = token;

                    api.setCredentials(identity.id, identity.token);

                    return dispatch({
                        ...identity,
                        authorizationMethod: 'signin:password',
                        demo: false,
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        signOutPrivate = dispatch => {
            api.clearCredentials();
            CredentialsStorage.shared()
                .clearToken()
                .finally(() => dispatch(null));
        };

        signOut = () => (dispatch, flux) => {
            this.signOutPrivate(dispatch, flux);
        };

        delete = () => (dispatch, flux) => {
            api.user(api.userId)
                .roles.suspended.put()
                .then(() => {
                    this.signOutPrivate(dispatch, flux);
                })
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        signUpAppleViaOAuth2 = (system, token) => async (dispatch, flux) => {
            const tokenBeforeSignUp = await CredentialsStorage.shared().getToken();

            Logger.shared().log({
                service: 'auto-logout',
                message: `token before AppleOAuth2: ${tokenBeforeSignUp}`,
            });

            const email = `${system}://${token}`;

            console.log('system', system, token);

            api.identity(`${oauthSystems[system]} token="${token}"`)
                .get()
                .then(response => {
                    const identity = response.data;

                    identity.token = getToken(response.headers);

                    Logger.shared().log({
                        service: 'auto-logout',
                        message: `xhr token AppleOAuth2: ${token}`,
                    });

                    api.setCredentials(identity.id, identity.token);

                    return dispatch({
                        ...identity,
                        authorizationMethod: `signin:${system}`,
                        authorizationAction: 'signin',
                        demo: false,
                    });
                })
                .catch(err => {
                    Logger.shared().error({
                        service: 'auto-logout',
                        message: `xhr Apple OAuth error: ${err}`,
                        payload: err,
                    });

                    const currentIdentity = flux.getStore(Actions.displayName).getState();
                    const isDemo = currentIdentity.id && currentIdentity.demo;

                    if (isDemo) {
                        api.identity(`Token token="${token}"`)
                            .patch({ email })
                            .then(() => {
                                return api.identity(`Token token="${token}"`).get();
                            })
                            .then(response => {
                                const identity = response.data;
                                const tokenFromResponse = getToken(response.headers);

                                if (tokenFromResponse) {
                                    identity.token = tokenFromResponse;

                                    api.setCredentials(identity.id, identity.token);
                                }

                                return dispatch({
                                    ...identity,
                                    authorizationMethod: `signup:${system}`,
                                    authorizationAction: 'signup',
                                    demo: false,
                                });
                            })
                            .catch(error => dispatch(null, axiosErrorAdapter(error)));
                    } else {
                        api.identity(api.authHeaders.authorization)
                            .put({ email })
                            .then(response => {
                                const identity = response.data;

                                identity.token = getToken(response.headers);

                                api.setCredentials(identity.id, identity.token);

                                dispatch({
                                    ...identity,
                                    authorizationMethod: `signup:${system}`,
                                    authorizationAction: 'signup',
                                    demo: false,
                                    authTime: new Date(),
                                });
                            })
                            .catch(error => dispatch(null, axiosErrorAdapter(error)));
                    }
                });
        };

        signUpViaOAuth2 = (system, token, email) => async (dispatch, flux) => {
            const tokenBeforeSignUp = await CredentialsStorage.shared().getToken();

            Logger.shared().log({
                service: 'auto-logout',
                message: `token before OAuth2: ${tokenBeforeSignUp}`,
            });

            api.identity(`${oauthSystems[system]} token="${token}"`)
                .get()
                .then(response => {
                    const identity = response.data;

                    identity.token = getToken(response.headers);

                    api.setCredentials(identity.id, identity.token);

                    Logger.shared().log({
                        service: 'auto-logout',
                        message: `xhr token: ${token}`,
                    });

                    return dispatch({
                        ...identity,
                        authorizationMethod: `signin:${system}`,
                        demo: false,
                    });
                })
                .catch(err => {
                    Logger.shared().error({
                        service: 'auto-logout',
                        message: `xhr OAuth error: ${err}`,
                        payload: err,
                    });

                    const currentIdentity = flux.getStore(Actions.displayName).getState();
                    const isDemo = currentIdentity.id && currentIdentity.demo;
                    const trimmedEmail = email.trim();
                    const password = `${+`${Date.now()}${Math.floor(
                        Math.random() * 9000 + 1000,
                    )}`}`;

                    if (isDemo) {
                        api.identity(`Token token="${currentIdentity.token}"`)
                            .patch({ email: trimmedEmail, password })
                            .then(() =>
                                dispatch({
                                    email: trimmedEmail,
                                    password,
                                    authorizationMethod: `signup:${system}`,
                                    demo: false,
                                }),
                            )
                            .catch(error => dispatch(null, axiosErrorAdapter(error)));
                    } else {
                        const basicCredentials = Buffer.from(
                            `${trimmedEmail}:${password}`,
                        ).toString('base64');

                        api.identity(`Basic ${basicCredentials}`)
                            .put({ email: trimmedEmail, password })
                            .then(response => {
                                const identity = response.data;

                                identity.token = getToken(response.headers);

                                api.setCredentials(identity.id, identity.token);

                                dispatch({
                                    ...identity,
                                    authorizationMethod: `signup:${system}`,
                                    demo: false,
                                });
                            })
                            .catch(error => dispatch(null, axiosErrorAdapter(error)));
                    }
                });
        };

        signInViaOAuth2 = (system, token) => dispatch => {
            api.identity(`${oauthSystems[system]} token="${token}"`)
                .get()
                .then(response => {
                    const identity = response.data;

                    identity.token = getToken(response.headers);

                    api.setCredentials(identity.id, identity.token);

                    return dispatch({
                        ...identity,
                        authorizationMethod: `signin:${system}`,
                        demo: false,
                    });
                })
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        patch = data => dispatch => {
            const { ...changes } = data;

            if (typeof changes.email === 'string') {
                changes.email = changes.email.trim();
            }

            api.identity(api.authHeaders.authorization)
                .patch(changes)
                .then(() => dispatch(changes))
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };

        recover = email => dispatch => {
            api.reminders()
                .password.post({ type: 'password', email })
                .then(() => dispatch())
                .catch(err => dispatch(null, axiosErrorAdapter(err)));
        };
    }

    Actions.displayName = createIdentityActions.getDisplayName(id);

    return Actions;
}

createIdentityActions.getDisplayName = id => {
    return `identity.${id}`;
};

export default createIdentityActions;
