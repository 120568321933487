import classNames from 'classnames';
import { memo, useMemo } from 'react';
import ReactQRCode from 'react-qr-code';

import classes from './styles.module.scss';

type Props = {
    link: string;
    /**
     * Square size. Default: 256
     */
    size?: number;
    className?: string;
};

export const QRCode = memo((props: Props) => {
    const { link, size = 256, className } = props;

    const style = useMemo(() => ({ maxWidth: size }), [size]);

    return (
        <div style={style} className={classNames(className, classes.container)}>
            <ReactQRCode
                className={classes.code}
                size={size}
                value={link}
                viewBox={`0 0 ${size} ${size}`}
            />
        </div>
    );
});
