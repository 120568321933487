import Actions from './actions';

function createIdentityStore(id = 'default') {
    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions);

            this.flux = flux;
            this.state = {};

            this.bindAction(actions.loginUser, this.actualize);
            this.bindAction(actions.auth, this.actualize);
            this.bindAction(actions.signUp, this.actualize);
            this.bindAction(actions.signUpAsAnonymous, this.actualize);
            this.bindAction(actions.signOut, this.clear);
            this.bindAction(actions.delete, this.clear);
            this.bindAction(actions.signUpViaOAuth2, this.actualize);
            this.bindAction(actions.signInViaOAuth2, this.actualize);
            this.bindAction(actions.signUpAppleViaOAuth2, this.actualize);
            this.bindAction(actions.patch, this.actualize);
            this.bindAction(actions.updatePassword, this.actualize);
            this.bindAction(actions.changeEmailToAnonymous, this.actualize);
        }

        actualize = payload => {
            payload && this.setState(payload);
        };

        clear = () => {
            this.flux.recycle(Store);
        };
    }

    Store.displayName = createIdentityStore.getDisplayName(id);

    return Store;
}

createIdentityStore.getDisplayName = function(id) {
    return `identity.${id}`;
};

export default createIdentityStore;
