import { useCallback } from 'react';

import { CredentialsUpdater } from '@sdv/domain/user/credentials/updater';

import { useLocation, useNavigate } from 'web/src/hooks/navigation';
import { ScreenRoutes } from 'web/src/constants/routing';
import { Resources } from 'web/src/resources';
import { ModalRoute } from 'web/src/components/modal-route';
import { QueryParams } from 'web/src/screens/signIn/types';

import { SignUpForm } from './form';

const KEEP_FOR: ScreenRoutes[] = [ScreenRoutes.Terms, ScreenRoutes.Privacy];

export const SignUpScreen = () => {
    const navigate = useNavigate();
    const {
        query: { cameFrom, needCredsUpdating },
    } = useLocation<QueryParams>();

    const handleClose = useCallback(() => {
        CredentialsUpdater.shared().cancelled();
        if (needCredsUpdating && cameFrom) {
            navigate(cameFrom, { replace: true });
        } else {
            navigate(ScreenRoutes.Dashboard);
        }
    }, [navigate, needCredsUpdating, cameFrom]);

    return (
        <ModalRoute
            modalKey="sign-up-modal"
            title={Resources.strings['sign-up']}
            onClose={handleClose}
            content={<SignUpForm />}
            keepFor={KEEP_FOR}
        />
    );
};
