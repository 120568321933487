/* eslint-disable max-classes-per-file */
import EventEmitter from 'eventemitter3';
import { IdentityModel, State } from '@sdv/domain/identity/model';
import { api } from '@sdv/commons/api';
import { Flux } from '@sdv/domain/flux';
import Alt from '@sdv/alt';

const RECONNECT_INTERVAL = 2000;
const EVENT_NAME = 'event';

type EventListener = (...args: any[]) => void;

type MakeUrlFunc = (key: string, shard: string) => string;

class MessageEmitter {
    private source: EventEmitter;

    constructor(source: EventEmitter) {
        this.source = source;
    }

    addListener(callback: EventListener) {
        this.source.addListener(EVENT_NAME, callback);
    }

    removeListener(callback: EventListener) {
        this.source.removeListener(EVENT_NAME, callback);
    }

    removeAllListeners() {
        this.source.removeAllListeners(EVENT_NAME);
    }
}

class ReconnectableWebSocketConnection {
    private ws: WebSocket | null = null;

    private isOpened = false;

    private retry = 0;

    private readonly eventEmitter: EventEmitter;

    private readonly model: Alt.ModelType<typeof IdentityModel>;

    private identityId: string | null = null;

    private timeout: ReturnType<typeof setTimeout> | undefined;

    readonly messages: MessageEmitter;

    constructor(
        private makeUrl: MakeUrlFunc,
        private maxRetry: number,
        private fallback: () => void,
        private pollEvents: () => void,
    ) {
        this.eventEmitter = new EventEmitter();
        this.messages = new MessageEmitter(this.eventEmitter);

        this.model = Flux.get(IdentityModel);
        this.model.store.listen(this.update);
        this.update(this.model.store.getState());
    }

    close = () => {
        this.closeWebSocket();
        this.model.store.unlisten(this.update);
        this.messages.removeAllListeners();
    };

    private update = (state: State) => {
        if (state.id !== this.identityId) {
            this.identityId = state.id;

            if (this.isOpened) {
                this.closeWebSocket();
                this.retry = 0;
            }

            if (this.identityId) {
                this.connect(this.identityId);
            }
        }
    };

    connect(userId: string, lastFailedShard?: string) {
        if (this.isOpened) {
            return;
        }

        if (this.retry > this.maxRetry) {
            this.fallback();
            return;
        }

        this.retry += 1;

        api.shards(userId)
            .get(lastFailedShard ? { exclude: lastFailedShard } : undefined)
            .then(response => {
                if (!response.data) {
                    throw new Error('response.data is empty');
                }

                const { key, shard } = response.data;

                const url = this.makeUrl(key, shard);

                // Prevents a race condition in case if previous connection was started,
                // but still not established by this time and 'onopen' event was not triggered
                if (this.ws) {
                    this.closeWebSocket();
                }

                // @ts-expect-error
                this.ws = new WebSocket(url, null, { headers: { 'user-agent': this.useAgent } });
                this.ws.onopen = () => {
                    this.isOpened = true;
                };

                this.ws.onmessage = event => {
                    this.eventEmitter.emit(EVENT_NAME, event);
                };

                this.ws.onerror = () => {
                    this.isOpened = false;
                };

                this.ws.onclose = () => {
                    this.isOpened = false;
                    this.reconnect(userId, shard);
                };
            })
            .catch(() => {
                this.reconnect(userId, lastFailedShard);
            });
    }

    send(message: string) {
        if (this.isOpened) {
            this.ws!.send(message);
        }

        return this.isOpened;
    }

    reconnect(userId: string, lastFailedShard?: string) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        // poll events manually in case we missed some while reconnecting
        this.pollEvents();

        this.timeout = setTimeout(() => {
            this.connect(userId, lastFailedShard);
        }, RECONNECT_INTERVAL);
    }

    private closeWebSocket() {
        if (this.ws) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = undefined;
            this.ws.onopen = null;
            this.ws.onmessage = null;
            this.ws.onerror = null;
            this.ws.onclose = null;
            this.ws.close();
            this.ws = null;
            this.isOpened = false;
        }
    }
}

export default ReconnectableWebSocketConnection;
