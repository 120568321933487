import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, Path, NavigateOptions, useLocation } from 'react-router-dom';

import { ScreenRoutes } from 'web/src/constants/routing';
import { parse, QueryObject, stringify } from 'web/src/utils/query-string';
import { Action, history } from 'web/src/services/history';

type CustomPath = Partial<Omit<Path, 'search'>> & {
    params?: Record<string, string>;
    query?: QueryObject;
};

export type To = string | CustomPath;

const isPathObject = (to: To): to is CustomPath => typeof to === 'object';

interface NavigateFunction {
    (to: To | number, options?: NavigateOptions): void;
    (delta: number): void;
}

export const transformTo = (to: To) => {
    if (isPathObject(to)) {
        const { params, pathname, query, ...props } = to;

        const search = query ? `?${stringify(query)}` : undefined;

        let transformedPathname = pathname;
        if (params && pathname) {
            transformedPathname = Object.entries(params).reduce(
                (result, [key, value]) => result.replace(`:${key}`, value),
                pathname,
            );
        }
        return { ...props, search, pathname: transformedPathname };
    }
    return to;
};

const useNavigateCustom = (): NavigateFunction => {
    const navigate = useNavigate();

    return useCallback(
        (to: To | number, options?: NavigateOptions) => {
            if (typeof to === 'number') {
                // if there's a history state it means we can go back
                if (window.history.state?.key) {
                    return navigate(to);
                }
                // otherwise go to main screen
                return navigate(ScreenRoutes.Dashboard, { replace: true });
            }
            const transformedTo = transformTo(to);

            return navigate(transformedTo, options);
        },
        [navigate],
    );
};

export { useNavigateCustom as useNavigate };

const useLocationCustom = <Q extends QueryObject = QueryObject>() => {
    const { search, ...location } = useLocation();
    const query = useMemo(() => parse<Q>(search), [search]);
    return { ...location, search, query };
};

export { useLocationCustom as useLocation };

export const useBackHandler = (handler: () => void) => {
    useEffect(() => {
        return history.listen(({ action }) => {
            if (action === Action.Pop) {
                handler();
            }
        });
    }, [handler]);
};
