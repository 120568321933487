export enum UserTagType {
    Blocked = 'blocked',
    Subscription = 'subscription', // has subscription
    Membership = 'membership', // has no subscription
    Hidden = 'hidden',
    Boost = 'boost',
    IsPromoter = 'dialogs.streams.promoter',
    IsStreamer = 'dialogs.messages.promoter',
    FreeCheersAvaliable = 'cheers.onboarding',
    Customer = 'customer',
    IsOnboardingGranted = 'credits.accounts.onboarding.granted',
    EmailConfirmed = 'email.confirmed',
    SubscriptionDiscount = 'credits.mall.discount.subscription',
    SwipeCoinsGranted = 'credits.accounts.onboading.swipecoins.granted',
    Scam = 'scam',
    Like = 'like',
    Dislike = 'dislike',
}

export enum MediaTag {
    Thumbnail = 'thumbnail',
    Hidden = 'hidden',
    Verification = 'verification',
}

export type MediaOrigin = {
    basename: string;
    created: string;
    mediatype: string;
    tags: MediaTag[];
};

export type ApiGetUser = {
    birthday?: string;
    city?: string;
    country?: string;
    devices?: string[];
    gender?: string;
    id: string;
    languages?: string[];
    latitude?: number;
    longitude?: number;
    name?: string;
    presence?: number;
    realm?: string;
    'registration-date'?: string;
    tags?: UserTagType[];
    thumbnail?: string;
    about?: string;
    height?: number;
    bodytype?: string;
    kids?: string;
    pets?: string;
    occupation?: string;
    religion?: string;
    ethnicity?: string;
    education?: string;
    smoke?: string;
    drink?: string;
    study?: string;
    pronouns?: string[];
    badges?: {
        badgeId: string;
        place: number;
    }[];
    media: { baseName: string; mediaType: string; tags: MediaTag[] }[];
    isVerified: boolean;
};

export type ApiGetUserMedia = {
    files: MediaOrigin[];
};

export type ApiGetEventsCount =
    | {
          message: number;
          'message-fresh': number;
          value: number;
      }
    | {
          'like.user': number;
          'like.user-fresh': number;
          value: number;
      }
    | {
          'my.like.user': number;
          'my.like.use-fresh': number;
          value: number;
      }
    | {
          match: number;
          'match-fresh': number;
          value: number;
      };

export enum UserEventType {
    Contact = 'message',
    Like = 'like.user',
    MyLike = 'my.like.user',
    Match = 'match',
    Star = 'star',
}

export type WsUserEvent = {
    type: UserEventType;
    timestamp: string;
    'holder-id': string;
    'event-id': number;
    'user-id': string;
    fresh?: number;
    freshed?: string;
    stale: boolean;
    stack?: number;
    total?: number;
    payload: {
        content?: string;
        'sender-id': string;
        meta?: {
            attachments?: any[];
            reference?: string;
            boost?: boolean;
            super?: boolean;
            tariffication?: {
                'blurred-without-subscription'?: boolean;
            };
        };
        'text-id'?: string;
        text?: string; // on websocket event
    };
};

export type ApiGetEvents = WsUserEvent[];
