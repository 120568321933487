import { ButtonHTMLAttributes, memo } from 'react';
import classNames from 'classnames';

import { ActivityIndicator } from 'web/src/components/commons/activity-indicator';

import classes from './styles.module.scss';

type AppearanceType = 'primary' | 'secondary';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    appearance?: AppearanceType;
    loading?: boolean;
};

export const Button = memo((props: Props) => {
    const { className, appearance, loading, disabled, children, ...rest } = props;

    return (
        <button
            type="button"
            disabled={loading || disabled}
            className={classNames(className, classes.button, {
                [classes.primary]: appearance === 'primary',
                [classes.secondary]: appearance === 'secondary',
                [classes.loading]: !!loading,
            })}
            {...rest}
        >
            <span>{children}</span>
            {loading && <ActivityIndicator className={classes.loader} />}
        </button>
    );
});
