import url from 'url';
import { str as crc32 } from 'crc-32';

export default function sharding(path: string, shards: number, prefixes: string[]) {
    if (!path || !shards || !prefixes) {
        return path;
    }

    const uri = url.parse(path);
    for (let i = 0; i < prefixes.length; i++) {
        const prefix = prefixes[i];
        const shard = Math.abs(crc32(path)) % shards;
        if (uri.host?.startsWith(prefix)) {
            uri.host = uri.host.replace(prefix, `${prefix || ''}${shard}`);
            break;
        }
    }

    return url.format(uri);
}
