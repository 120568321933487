import { api, axiosErrorAdapter } from '@sdv/commons/api';

function createUserActions(userId) {
    class Actions {
        get = () => dispatch => {
            const resource = api.userId === userId ? api.user(userId).private : api.user(userId);

            resource
                .get()
                .then(response => dispatch(response.data))
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        patch = user => dispatch => {
            const { ...updatedUser } = user;
            if (updatedUser.name) {
                updatedUser.name = updatedUser.name.trim();
            }

            api.user(userId)
                .patch(updatedUser)
                .then(() => dispatch(updatedUser))
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        actualize = user => dispatch => {
            dispatch(user);
        };
    }

    Actions.displayName = createUserActions.getDisplayName(userId);

    return Actions;
}

createUserActions.getDisplayName = id => {
    return `user.${id}`;
};

export default createUserActions;
