import { Observable } from 'rxjs';

import Alt from '@sdv/alt';
import { Flux } from '@sdv/domain/flux';
import { IdentityModel } from '@sdv/domain/identity/model';
import { singleton } from '@sdv/commons/utils/singleton';

export class Identity {
    static shared = singleton(() => new Identity());

    private readonly model: Alt.ModelType<typeof IdentityModel>;

    constructor() {
        this.model = Flux.get(IdentityModel);
    }

    signOut(): Observable<void> {
        return new Observable(observer => {
            this.model.actions.signOut((error: any) => {
                if (error) {
                    observer.error();
                } else {
                    observer.next(undefined as void);
                    observer.complete();
                }
            });
        });
    }

    delete(): Observable<void> {
        return new Observable(observer => {
            this.model.actions.delete((error: any) => {
                if (error) {
                    observer.error();
                } else {
                    observer.next(undefined as void);
                    observer.complete();
                }
            });
        });
    }
}
