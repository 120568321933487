import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Resources } from 'web/src/resources';
import { useNavigate, useLocation } from 'web/src/hooks/navigation';
import { ModalRoute } from 'web/src/components/modal-route';
import { ScreenRoutes } from 'web/src/constants/routing';

import { PurchaseForm } from './form';

import classes from './styles.module.scss';
import { COINS_PACKS } from '../buy-coins/view/constants';

const KEEP_FOR: ScreenRoutes[] = [
    ScreenRoutes.Terms,
    ScreenRoutes.Privacy,
    ScreenRoutes.CancelationPolicy,
];

type QueryParams = {
    packId?: number;
};

export const PurchaseScreen = () => {
    const navigate = useNavigate();
    const { query } = useLocation<QueryParams>();
    const { current: packId } = useRef(query.packId);

    const pack = useMemo(() => COINS_PACKS.find(p => p.id === packId), [packId]);

    const handleClose = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (!pack) {
            handleClose();
        }
    }, [pack, handleClose]);

    if (!pack) {
        return null;
    }

    return (
        <ModalRoute
            modalKey="purchase-modal"
            title={Resources.strings['buy-coins']}
            onClose={handleClose}
            content={<PurchaseForm pack={pack} />}
            keepFor={KEEP_FOR}
            className={classes.buyCoinsModal}
        />
    );
};
