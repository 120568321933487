import { memo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { AlertState, CLOSE_ANIMATION_TIMEOUT } from 'web/src/services/alerts';
import { Button } from 'web/src/components/commons/button';
import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type Props = Omit<AlertState, 'key'> & {
    opened: boolean;
    onClose: () => void;
};

const transitionClassNames: CSSTransitionClassNames = {
    exitActive: classes.containerExit,
    exitDone: classes.containerExit,
};

export const Alert = memo((props: Props) => {
    const { message, onClose, opened } = props;

    return (
        <CSSTransition
            appear
            timeout={CLOSE_ANIMATION_TIMEOUT}
            in={opened}
            classNames={transitionClassNames}
        >
            <div className={classes.container}>
                <p className={classes.message}>{message}</p>
                <Button className={classes.closeBtn} onClick={onClose}>
                    <img src={Resources.images.closeIcon} alt="close icon" />
                </Button>
            </div>
        </CSSTransition>
    );
});
