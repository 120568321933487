import { Subscription } from 'rxjs';

import { PaymentCoinsBalance } from '@sdv/domain/payment/coins/balance';
import { Service } from '@sdv/commons/service';
import Flux from '@sdv/domain/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import { UpdatePayload } from '@sdv/domain/payment/coins/balance/model';

const WS_EVENT_NAME = 'account:credits';

const isUpdatePayload = (data?: unknown): data is UpdatePayload => {
    return !!data && typeof (data as UpdatePayload).balance === 'number';
};

export class PaymentCoinsBalanceUpdater implements Service {
    static shared = singleton((userId: string) => new PaymentCoinsBalanceUpdater(userId));

    private service: PaymentCoinsBalance;

    constructor(userId: string) {
        this.service = PaymentCoinsBalance.shared(userId);
    }

    start(unsubscription: Subscription) {
        Flux.events.addListener(WS_EVENT_NAME, this.onEventReceived);
        unsubscription.add(() => {
            Flux.events.removeListener(WS_EVENT_NAME, this.onEventReceived);
        });
    }

    private onEventReceived = (data?: unknown) => {
        if (isUpdatePayload(data)) {
            this.service.update(data);
        }
    };
}
