declare const __VERSION__: string | undefined | null;
declare global {
    interface Window {
        __VERSION__: string | undefined | null;
    }
}

/**
 * Injects the version of the application into the window.__VERSION__
 *
 * Used only for debug purposes on runtime builds
 */
export function injectVersion() {
    if (__VERSION__) {
        window.__VERSION__ = __VERSION__;
    }
}
