/* eslint-disable no-undef */
import { api } from '@sdv/commons/api';
import url from 'url';

import request from '@sdv/commons/utils/request';

function CreateActions(id) {
    class Actions {
        post = (path, files) => async dispatch => {
            const uri = url.format({
                protocol: 'https',
                hostname: api.baseHost.replace('//', ''),
                pathname: path,
            });

            const headers = {
                'content-type': 'multipart/form-data',
                accept: 'application/json',
                authorization: api.authHeaders.authorization,
            };
            if (api.userAgent) {
                headers['user-agent'] = api.userAgent;
            }

            try {
                const response = await request(
                    uri,
                    {
                        method: 'POST',
                        body: getFormData(files),
                        headers,
                    },
                    event => {
                        const progress = Math.round((event.loaded / event.total) * 100);

                        dispatch(progress);
                    },
                );

                try {
                    const json = JSON.parse(response.responseText);
                    // eslint-disable-next-line no-nested-ternary
                    const assets = json.basenames
                        ? json.basenames
                        : json.basename
                        ? [json.basename]
                        : json;
                    dispatch(assets);
                } catch (error) {
                    dispatch(null, error);
                }
            } catch (error) {
                dispatch(null, error);
            }
        };
    }

    Actions.displayName = CreateActions.getDisplayName(id);

    return Actions;
}

CreateActions.getDisplayName = id => {
    return `uploader.${id}`;
};

export default CreateActions;

function getFormData(files) {
    const formData = new FormData();

    if (files instanceof Blob) {
        formData.append('blob', files, '___.jpg');
    } else if (Array.isArray(files)) {
        files.forEach(file => formData.append(file.name, file));
    } else {
        formData.append(files.name, files);
    }

    return formData;
}
