import { memo, PropsWithChildren, useMemo } from 'react';

import { QRCode } from 'web/src/components/qr-code';
import { Resources } from 'web/src/resources';
import { Link } from 'web/src/components/commons/link';
import { ConfigService } from 'web/src/services/config';

import classes from './styles.module.scss';

import appStoreButtonImg from './resources/app-store-badge.svg';
import googlePlayButtonImg from './resources/google-play-badge.svg';

const QR_CODE_SIZE = 160;

const ListItem = (props: PropsWithChildren) => {
    const { children } = props;
    return (
        <li className={classes.listItem}>
            <img src={Resources.images.favoriteIcon} alt="" />
            {children}
        </li>
    );
};

export default memo(() => {
    const config = useMemo(() => ConfigService.shared().config, []);
    const appStore = config['stores-links']['app-store'];
    const googlePlay = config['stores-links']['google-play'];

    return (
        <div>
            <ul className={classes.list}>
                <ListItem>{Resources.strings['get-app-screen'].p1}</ListItem>
                <ListItem>{Resources.strings['get-app-screen'].p2}</ListItem>
                <ListItem>{Resources.strings['get-app-screen'].p3}</ListItem>
            </ul>
            <h3 className={classes.qrTitle}>{Resources.strings['get-app-screen']['qr-title']}</h3>
            <div className={classes.qrContainer}>
                <QRCode size={QR_CODE_SIZE} link={appStore} />
                <QRCode size={QR_CODE_SIZE} link={googlePlay} />
            </div>
            <div className={classes.buttonsContainer}>
                <Link className={classes.button} type="common" href={appStore}>
                    <img src={appStoreButtonImg} alt="app store" />
                </Link>
                <Link className={classes.button} type="common" href={googlePlay}>
                    <img src={googlePlayButtonImg} alt="google play" />
                </Link>
            </div>
        </div>
    );
});
