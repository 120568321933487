import { BehaviorSubject, Observable } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';
import { Logger } from '@sdv/domain/logger';
import { NetworkEnvironment } from '@sdv/network/config';
import { clientConfig, ClientConfig } from 'web/src/app/config/client';

type RemoteConfig = {
    apiHost: string;
    streaming: {
        environment: NetworkEnvironment;
    };
};

export class ConfigService {
    static readonly shared = singleton(() => new ConfigService());

    private readonly _config = clientConfig;

    private initializedSubject = new BehaviorSubject(false);

    get config(): Readonly<ClientConfig> {
        return this._config;
    }

    initialized: Observable<boolean>;

    private constructor() {
        this.initialized = this.initializedSubject.asObservable();
    }

    init = async (cb?: (config: ClientConfig) => void) => {
        if (process.env.NODE_ENV === 'production') {
            try {
                const response = await fetch(`${window.location.origin}/config/config.json`);
                const config: Partial<RemoteConfig> = await response.json();

                if (config.apiHost) {
                    this._config.host = config.apiHost;
                }
                if (config.streaming?.environment) {
                    this._config.networkEnvironment = config.streaming?.environment;
                }
            } catch (error: any) {
                Logger.shared().error({
                    service: 'config',
                    payload: error,
                    message: `remoteConfigLoad error: ${error?.message}`,
                });
            }
        }
        cb?.(this.config);

        this.initializedSubject.next(true);
    };
}
