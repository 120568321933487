import EventEmitter from 'eventemitter3';

import WebSocketConnection from './websocket-connection';
import EventsPoller from './poller';

const CONNECTION_MAX_RETRY = 3;

class EventInbox {
    private timestamp: string | null = null;

    private eventEmitter = new EventEmitter();

    private eventsPoller = new EventsPoller();

    private connection: WebSocketConnection | null;

    constructor(getHost: () => string) {
        this.connection = new WebSocketConnection(
            (key, shard) => this.makeUrl(key, shard, getHost()),
            CONNECTION_MAX_RETRY,
            () => this.startFallback(),
            () => this.pollEvent(true),
        );

        this.connection.messages.addListener(e => {
            if (e.data) {
                const event = JSON.parse(e.data);
                const eventName = event.label || event.type;

                if (eventName) {
                    const payload = event.payload || event.details;

                    this.eventEmitter.emit(eventName, payload);
                }

                if (event.sync) {
                    this.timestamp = event.sync;
                }
            }
        });
    }

    addListener(eventType: string, listener: (...args: any[]) => void) {
        return this.eventEmitter.addListener(eventType, listener);
    }

    removeListener(eventType: string, listener: (...args: any[]) => void) {
        this.eventEmitter.removeListener(eventType, listener);
    }

    close() {
        this.closeSocket();
        this.eventsPoller.stop();
    }

    private makeUrl(key: string, shard: string, host: string) {
        const shardedHost = `wss://rt${shard}.${host}/${key}`;

        return this.timestamp === null ? shardedHost : `${shardedHost}?sync=${this.timestamp}`;
    }

    private closeSocket() {
        if (this.connection) {
            this.connection.close();
            this.connection = null;
        }
    }

    private startFallback() {
        this.closeSocket();
        this.eventsPoller.stop();

        this.eventsPoller.setListener((eventType, payload) => {
            this.eventEmitter.emit(eventType, payload);
        });
        this.eventsPoller.start();
    }

    private pollEvent(force?: boolean) {
        if (this.eventsPoller.isStarted() || force) {
            this.eventsPoller.pollEvent();
        }
    }
}

export default EventInbox;
