import { singleton } from '@sdv/commons/utils/singleton';
import { Persistence } from '@sdv/domain/persistence';

const credentialsScope = 'user-credentials';
const tokenKey = 'user-token';

export class CredentialsStorage {
    static shared = singleton(() => new CredentialsStorage());

    private readonly persistence: Persistence;

    private constructor() {
        this.persistence = Persistence.shared(credentialsScope);
    }

    async getToken(): Promise<string | undefined> {
        return this.persistence
            .load<string>(tokenKey)
            .toPromise()
            .then(token => {
                if (!token) return undefined;

                return token;
            });
    }

    async setToken(token: string): Promise<void> {
        return this.persistence.store(tokenKey, token).toPromise();
    }

    async clearToken(): Promise<void> {
        return this.persistence.clear(tokenKey).toPromise();
    }
}
