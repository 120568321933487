import classnames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

import classes from './styles.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    error?: string | boolean;
    errorHighlight?: boolean;
};

export const TextInput = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const { className, error, errorHighlight = true, ...otherProps } = props;
    const isError = !!error;

    return (
        <div className={classnames(className, classes.container)}>
            <input
                type="text"
                className={classnames(classes.textInput, {
                    [classes.error]: errorHighlight && isError,
                })}
                {...otherProps}
                aria-invalid={errorHighlight && isError}
                ref={ref}
            />
            {typeof error === 'string' && (
                <span role="alert" className={classes.errorText}>
                    {error}
                </span>
            )}
        </div>
    );
});
