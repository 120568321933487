import { ReactNode } from 'react';
import { BehaviorSubject } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';

export type ModalProps = {
    key: string;
    title: string;
    onClose?: () => void;
    /**
     * Default: `true`
     */
    canClose?: boolean;
    content?: ReactNode;
    className?: string;
};

type ModalState = {
    opened: boolean;
};

export const CLOSE_ANIMATION_TIMEOUT = 300;

export class ModalsService {
    static shared = singleton(() => new ModalsService());

    private readonly stackSubject = new BehaviorSubject<(ModalProps & ModalState)[]>([]);

    readonly stack = this.stackSubject.asObservable();

    private get stackValue() {
        return this.stackSubject.getValue();
    }

    show = ({ canClose = true, ...state }: ModalProps) => {
        if (this.getModal(state.key)) {
            this.stackSubject.next(
                this.stackValue.map(modal => {
                    if (modal.key === state.key) {
                        return { ...modal, ...state };
                    }
                    return modal;
                }),
            );
        } else {
            this.stackSubject.next(
                this.stackValue.concat({
                    opened: true,
                    canClose,
                    ...state,
                }),
            );
        }
    };

    closeAll = () => {
        this.stackSubject.next(this.stackValue.map(modal => ({ ...modal, opened: false })));
        setTimeout(() => {
            this.stackSubject.next([]);
        }, CLOSE_ANIMATION_TIMEOUT);
    };

    close = (key: string, onClosed?: () => void) => {
        let hasModal = false;
        const updatedStack = this.stackValue.map(modal => {
            if (modal.key === key) {
                hasModal = true;
                return { ...modal, opened: false };
            }
            return modal;
        });
        if (!hasModal) {
            return;
        }
        this.stackSubject.next(updatedStack);
        setTimeout(() => {
            this.stackSubject.next(this.stackValue.filter(modal => modal.key !== key));
            onClosed?.();
        }, CLOSE_ANIMATION_TIMEOUT);
    };

    private getModal = (key: string) => {
        return this.stackValue.find(modal => modal.key === key);
    };
}
