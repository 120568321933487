import { context, ApiContext } from './api-context';

import { annals } from './annals';
import { credits } from './credits';
import { electronicmail } from './electronicmail';
import { events } from './events';
import { identity } from './identity';
import { location } from './location';
import { notifications } from './notifications';
import { reminders } from './reminders';
import { shards } from './shards';
import { streaming } from './streaming';
import { users } from './users';

type API = ApiContext &
    typeof annals &
    typeof events &
    typeof credits &
    typeof electronicmail &
    typeof identity &
    typeof location &
    typeof shards &
    typeof reminders &
    typeof notifications &
    typeof streaming &
    typeof users;

export const api: API = Object.assign(
    context,
    annals,
    credits,
    electronicmail,
    events,
    identity,
    location,
    shards,
    reminders,
    notifications,
    streaming,
    users,
);
