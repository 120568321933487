import { memo } from 'react';

import { Resources } from 'web/src/resources';
import { CoinsPack } from 'web/src/screens/buy-coins/view/constants';

import formMock from './moto-form.png';

import classes from './styles.module.scss';
import { PurchaseNote } from './purchase-note';

type Props = {
    pack: CoinsPack;
};

export const PurchaseForm = memo((props: Props) => {
    const { pack } = props;

    return (
        <div className={classes.form}>
            <img src={formMock} alt="" />
            <p className={classes.selected}>
                {Resources.strings.formatString(
                    Resources.strings.purchase.selected,
                    pack.coins,
                    `${pack.price} ${pack.currency}`,
                )}
            </p>
            <PurchaseNote className={classes.terms} />
        </div>
    );
});
