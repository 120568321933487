import { of } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';

export class PushNotificationTokenActualizer {
    static readonly shared = singleton(
        (userId: string) => new PushNotificationTokenActualizer(userId),
    );

    // @ts-expect-error
    private readonly userId: string;

    private constructor(userId: string) {
        this.userId = userId;
    }

    static updateDeviceTokens() {}

    // not implemented
    clear(arg?: any) {
        return of(undefined as void);
    }
}
