import Alt from '@sdv/alt';

// TODO: fill
export type State = {
    files: string[];
    progress?: number | null;
};

type File = {
    uri: string;
    name: string;
    type: string;
};

// TODO: fill
type Actions = {
    post: (path: string, files: Blob | File | File[], cb?: (err?: any) => void) => void;
};

type ActionsDeclaration = Alt.ActionsDeclaration<Actions>;
type StoreDeclaration = Alt.StoreDeclaration<Alt.Store<State>>;

/* eslint-disable global-require */
const actions = require('./actions').default as ActionsDeclaration;
const store = require('./store').default as StoreDeclaration;
/* eslint-enable global-require */

type ObjectStorageDeclaration = Alt.ModelDeclaration<ActionsDeclaration, StoreDeclaration>;

export const ObjectStorageModel: ObjectStorageDeclaration = {
    store,
    actions,
};
