import { memo, useCallback } from 'react';
// @ts-ignore
import { LeaderboardLayout } from '@sdv-streaming/web';

import { useNavigate } from 'web/src/hooks/navigation';

import classes from './styles.module.scss';

export const LeaderboardScreen = memo(() => {
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className={classes.overlay}>
            <div className={classes.inner}>
                <LeaderboardLayout onClose={handleClose} />
            </div>
        </div>
    );
});
