import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';

import { Gender } from '@sdv/domain/user/gender';
import { UserProfile } from '@sdv/domain/user/model';
import { resetUTCTime } from '@sdv/commons/utils/date';

import { TextInput } from 'web/src/components/commons/textInput';
import { Button } from 'web/src/components/commons/button';
import { Radio } from 'web/src/components/commons/radio';
import { TermsText } from 'web/src/screens/signIn/form/terms-text';
import { UserService } from 'web/src/services/user';
import { Resources } from 'web/src/resources';

import textInputClasses from 'web/src/components/commons/textInput/styles.module.scss';

import { validationSchema, minBirthDate } from './validation-schema';
import { FormFields } from './types';

import classes from './styles.module.scss';

type Props = {
    user: Partial<UserProfile>;
};

const formatBirthday = (date: Date) => format(date, 'yyyy-MM-dd');

const minBirthDateStr = formatBirthday(minBirthDate);

export const CompleteProfileForm = (props: Props) => {
    const { user } = props;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<FormFields>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            gender: user?.gender,
            name: user?.name || '',
            birthday: user?.birthday && formatBirthday(new Date(user.birthday)),
        },
    });

    const handleFormSubmit = useCallback(
        async ({ birthday, ...data }: FormFields) => {
            await UserService.shared(user.id!).updateUser({
                ...data,
                birthday: resetUTCTime(new Date(birthday)).toISOString(),
            });
        },
        [user],
    );

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form} noValidate>
            <TextInput
                {...register('name')}
                className={classes.name}
                placeholder={Resources.strings['your-name']}
                error={errors.name?.message}
            />
            <div className={classes.radioContainer}>
                <Radio
                    {...register('gender')}
                    error={!!errors.gender?.message}
                    value={Gender.Male}
                    label={Resources.strings.male}
                />
                <Radio
                    {...register('gender')}
                    error={!!errors.gender?.message}
                    value={Gender.Female}
                    label={Resources.strings.female}
                />
                {errors.gender?.message && (
                    <span role="alert" className={textInputClasses.errorText}>
                        {errors.gender?.message}
                    </span>
                )}
            </div>
            <TextInput
                {...register('birthday')}
                className={classes.birthDate}
                type="date"
                placeholder={Resources.strings['date-of-birth']}
                max={minBirthDateStr}
                error={errors.birthday?.message}
            />
            <TermsText className={classes.terms} />
            <Button
                type="submit"
                loading={isSubmitting}
                className={classes.signUpBtn}
                appearance="primary"
            >
                {Resources.strings['lets-start']}
            </Button>
        </form>
    );
};
