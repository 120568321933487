import axios from 'axios';
import { format } from 'url';

import { context, protocol } from '../api-context';

export const shards = {
    shards: (userId: string) => ({
        get: (params?: any) =>
            axios.get<{ key: string; shard: string }>(
                format({
                    protocol,
                    host: context.host,
                    pathname: `/shards/${userId}`,
                    query: params,
                }),
                { headers: context.authHeaders },
            ),
    }),
};
