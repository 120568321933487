import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

export function unwrap<T>() {
    return function (source: Observable<T | null | undefined>): Observable<T> {
        return source.pipe(
            filter(function (it: T | null | undefined): it is T {
                return typeof it !== 'undefined' && it !== null
            })
        )
    }
}
