import { combineLatest, of } from 'rxjs';
import { filter, take, switchMap, map } from 'rxjs/operators';

import { logger } from '@sdv/commons/logger';
import { Config } from '@sdv/domain/app/config';
import { singleton } from '@sdv/commons/utils/singleton';
import { Level, LogPayload, levelsOrder } from '@sdv/commons/logger/types';
import { NetworkConnection } from '@sdv/domain/system/network-connection';
import Session from '@sdv/domain/authorization/session';
import { operatingSystem, platform } from '@sdv/domain/system/platform';
import Persistence from '@sdv/domain/persistence';

const postponedLogActionKey = 'postponed-log.action';

type Action = {
    levelName: Level;
    payload: LogPayload;
    date: string;
};

export class Logger {
    static shared = singleton(() => new Logger());

    private readonly networkConnection = NetworkConnection.shared();

    private readonly userId = Session.shared().userId.pipe(filter(it => !!it));

    private readonly os = operatingSystem();

    private readonly persistence = Persistence.shared();

    constructor() {
        this.networkConnection.isConnected
            .pipe(
                switchMap(isConnected => {
                    if (isConnected) {
                        return this.persistence.rxValue(postponedLogActionKey);
                    }
                    return of('');
                }),
            )
            .subscribe(
                postponedActionsJSON => {
                    if (postponedActionsJSON) {
                        const postponedActions = (JSON.parse(postponedActionsJSON as string) ||
                            []) as Action[];

                        postponedActions.forEach(
                            (action: { levelName: Level; payload: LogPayload; date: string }) => {
                                this.action(action.levelName, action.payload, action.date);
                            },
                        );
                    }

                    return this.persistence.rxStore(postponedLogActionKey, JSON.stringify([]));
                },
                () => {},
            );
    }

    action(levelName: Level, payload: LogPayload, dateBeforePostpone?: string) {
        // TODO: temporary disabled action for web. Enable when datadog is configured for web
        if (platform() === 'web') {
            return;
        }

        const { currentVersion, productName, currentDevice, minLogLevel } = Config.shared();

        const logLevel = levelsOrder[levelName];

        minLogLevel
            .pipe(
                filter(minLevel => logLevel >= minLevel),
                switchMap(() =>
                    combineLatest(
                        this.userId,
                        this.networkConnection.type,
                        this.networkConnection.effectiveType,
                        currentVersion,
                        productName,
                        currentDevice,
                    ).pipe(take(1)),
                ),
            )
            .subscribe(([userId, type, effectiveType, appVersion, product, device]) => {
                const data = {
                    ...payload,
                    userId,
                    appVersion,
                    product,
                    device,
                    os: this.os,
                    connectionType: type,
                    connectionEffectiveType: effectiveType,
                    date: dateBeforePostpone,
                };

                const errHandler = () => this.storeAction(levelName, payload);

                logger.log(levelName, data, errHandler);
            });
    }

    log(payload: LogPayload) {
        this.action('debug', payload);
    }

    info(payload: LogPayload) {
        this.action('info', payload);
    }

    warn(payload: LogPayload) {
        this.action('warn', payload);
    }

    error(payload: LogPayload) {
        this.action('error', payload);
    }

    storeAction(levelName: Level, payload: LogPayload) {
        const date = new Date().toISOString();

        const action = {
            levelName,
            payload,
            date,
        };

        this.persistence
            .rxValue(postponedLogActionKey)
            .pipe(
                map(postponedLogActionsJSON => {
                    if (postponedLogActionsJSON) {
                        const postponedActions = JSON.parse(
                            postponedLogActionsJSON as string,
                        ) as Action[];

                        return [...postponedActions, action];
                    }

                    return [action];
                }),
                switchMap(actions => {
                    return this.persistence.rxStore(postponedLogActionKey, JSON.stringify(actions));
                }),
                take(1),
            )
            .subscribe(
                () => {},
                () => {},
            );
    }
}
