import { useEffect, useMemo, useState } from 'react';

import {
    TABLET_MIN_WIDTH,
    MOBILE_MAX_WIDTH,
    TABLET_MAX_WIDTH,
    DESKTOP_MIN_WIDTH,
} from 'web/src/constants/breakpoints';

type CustomSize = {
    minWidth?: number;
    maxWidth?: number;
};

export type MediaSize = 'mobile' | 'tablet' | 'desktop' | 'tabletOrDesktop' | CustomSize;

const isCustom = (size: MediaSize): size is CustomSize => typeof size === 'object';

const match = (size: MediaSize) => {
    const queryParts: string[] = ['screen'];

    if (isCustom(size)) {
        if (size.minWidth !== undefined) {
            queryParts.push(`(min-width: ${size.minWidth}px)`);
        }
        if (size.maxWidth !== undefined) {
            queryParts.push(`(max-width: ${size.maxWidth}px)`);
        }
    } else {
        switch (size) {
            case 'mobile':
                queryParts.push(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
                break;
            case 'tablet':
                queryParts.push(
                    `(min-width: ${TABLET_MIN_WIDTH}px)`,
                    `(max-width: ${TABLET_MAX_WIDTH}px)`,
                );
                break;
            case 'desktop':
                queryParts.push(`(min-width: ${DESKTOP_MIN_WIDTH}px)`);
                break;
            case 'tabletOrDesktop':
                queryParts.push(`(min-width: ${TABLET_MIN_WIDTH}px)`);
                break;
            default:
                break;
        }
    }

    return matchMedia(queryParts.join(' and '));
};

export const useMatchMedia = (size: MediaSize) => {
    const media = useMemo(() => match(size), [size]);

    const [matches, setMatches] = useState(media.matches);

    useEffect(() => {
        const handleMediaChange = (e: MediaQueryListEvent) => {
            if (e.matches !== matches) {
                setMatches(e.matches);
            }
        };

        media.addEventListener('change', handleMediaChange);

        return () => {
            media.removeEventListener('change', handleMediaChange);
        };
    }, [media, matches]);

    return matches;
};
