export type Level = 'debug' | 'info' | 'warn' | 'error';

export enum LevelPriority {
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
}

export const levelsOrder: {
    [key in Level]: number;
} = {
    debug: LevelPriority.Debug,
    info: LevelPriority.Info,
    warn: LevelPriority.Warn,
    error: LevelPriority.Error,
};

export type LogPayload = {
    service: string;
    message: string;
    payload?: any;
};

export type AdditionalLogPayload = LogPayload & {
    [key: string]: any;
};

export type AdditionalLogErrorHandler = (errCode?: string) => void;
