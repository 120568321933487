import { string, object, SchemaOf, mixed } from 'yup';
import { sub, startOfDay, isBefore } from 'date-fns';

import { Gender } from '@sdv/domain/user/gender';
import { Resources } from 'web/src/resources';

import { FormFields } from './types';

export const minBirthDate = sub(startOfDay(new Date()), { years: 18 });

export const validationSchema: SchemaOf<FormFields> = object({
    name: string()
        .trim()
        .required(
            Resources.strings.formatString(
                Resources.strings.validation.required,
                Resources.strings.name,
            ),
        )
        .min(1, Resources.strings.formatString(Resources.strings.validation.min, 1))
        .max(30, Resources.strings.formatString(Resources.strings.validation.max, 30, 's')),
    birthday: string()
        .required(
            Resources.strings.formatString(
                Resources.strings.validation.required,
                Resources.strings['birth-date'],
            ),
        )
        .matches(/^[1-9]\d{3}-\d{2}-\d{2}$/, {
            message: Resources.strings.formatString(
                Resources.strings.validation.incorrect,
                Resources.strings.date,
            ),
        })
        .test({
            name: 'minAge',
            test: date => isBefore(new Date(date as string), minBirthDate),
            message: Resources.strings.validation['min-age'],
        }),
    gender: mixed<Gender>()
        .oneOf(Object.values(Gender))
        .required(
            Resources.strings.formatString(
                Resources.strings.validation.required,
                Resources.strings.gender,
            ),
        ),
});
