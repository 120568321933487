import { memo, useCallback, useMemo } from 'react';

import { UserProfile } from '@sdv/domain/user/model';

import { Logo } from 'web/src/components/commons/logo';
import { Button } from 'web/src/components/commons/button';
import { Media } from 'web/src/components/commons/media';
import { Coins } from 'web/src/components/commons/coins';
import { ScreenRoutes } from 'web/src/constants/routing';
import { Resources } from 'web/src/resources';
import { useLocation, useNavigate } from 'web/src/hooks/navigation';
import { Search } from 'web/src/components/search';
import { UserAvatar } from 'web/src/components/user-avatar';

import classes from './styles.module.scss';

type Props = {
    onToggleSidebar: () => void;
    sidebarVisible?: boolean;
    user: Partial<UserProfile> | null;
    userBalance: number;
};

export const Header = memo((props: Props) => {
    const { onToggleSidebar, sidebarVisible, user, userBalance } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const navigateToSignIn = useCallback(() => {
        navigate({ pathname: ScreenRoutes.SignIn, query: { cameFrom: location.pathname } });
    }, [navigate, location.pathname]);

    const navigateToBuyCoins = useCallback(() => {
        navigate(ScreenRoutes.BuyCoins);
    }, [navigate]);

    const mainContent = useMemo(() => {
        if (user) {
            return (
                <div className={classes.content}>
                    <Coins amount={userBalance} />
                    <Button onClick={navigateToBuyCoins} appearance="primary">
                        {Resources.strings['buy-coins']}
                    </Button>
                    <Button className={classes.profileBtn} appearance="secondary">
                        <UserAvatar user={user} className={classes.avatar} placeholder="icon" />
                        <span className={classes.name}>{user.name}</span>
                    </Button>
                </div>
            );
        }

        return (
            <div className={classes.content}>
                <Button appearance="primary" onClick={navigateToBuyCoins}>
                    {Resources.strings['buy-coins']}
                </Button>
                <Button onClick={navigateToSignIn} appearance="secondary">
                    {Resources.strings['sign-in']}
                </Button>
            </div>
        );
    }, [navigateToSignIn, navigateToBuyCoins, user, userBalance]);

    return (
        <div className={classes.header}>
            <div className={classes.container}>
                <Media size="mobile">
                    <Logo onlyIcon />
                    {mainContent}
                    <Button className={classes.burger} onClick={onToggleSidebar}>
                        {sidebarVisible ? (
                            <img src={Resources.images.closeIcon} alt="close icon" />
                        ) : (
                            <img src={Resources.images.moreIcon} alt="more icon" />
                        )}
                    </Button>
                </Media>
                <Media size="tabletOrDesktop">
                    <Search position="header" />
                    {mainContent}
                </Media>
            </div>
        </div>
    );
});
