import axios from 'axios';

import { context } from '../api-context';

export const electronicmail = {
    electronicmail: {
        addresses: {
            get: (emailBase64: string) =>
                axios.get(`${context.server}/electronicmail/addresses/${emailBase64}`, {
                    headers: context.headers,
                }),
        },
    },
};
