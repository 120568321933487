import Alt from '@sdv/alt';
import EventEmitter from 'eventemitter3';

import { api } from '@sdv/commons/api';
import { Config } from '@sdv/commons/api/api-context';
import EventsInbox from '@sdv/domain/events/inbox';

type TFlux = Alt & {
    bus: EventEmitter;
    events: EventsInbox;
};

// @ts-expect-error
const flux = new Alt();

flux.AP = {};
flux.AP.wait = () => {};
flux.AP.done = () => {};
flux.bus = new EventEmitter();

export function initApi(config: Config) {
    api.init(config);

    flux.events = new EventsInbox(() =>
        api.baseHost.replace('//', '').replace('api.', '').replace('apu.', ''),
    );
}

export const Flux = flux as TFlux;

export default Flux;
