import classnames from 'classnames';
import { AnchorHTMLAttributes, memo } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { transformTo, To } from 'web/src/hooks/navigation';

import classes from './styles.module.scss';

type Props =
    | ({ type: 'common' } & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'type'>)
    | ({ type?: undefined } & Omit<LinkProps, 'type' | 'to'> & {
              to: To;
          });

export const Link = memo((props: Props) => {
    const { type, className, ...otherProps } = props;

    if (type === 'common') {
        return (
            <a
                target="_blank"
                rel="noreferrer"
                className={classnames(classes.link, className)}
                {...otherProps}
            />
        );
    }

    const { to, ...linkProps } = otherProps as LinkProps;

    const transformedTo = transformTo(to);

    return (
        <RouterLink
            className={classnames(classes.link, className)}
            to={transformedTo}
            {...linkProps}
        />
    );
});
