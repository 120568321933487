import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

function hookDepsAreEqual(lhs: readonly any[], rhs: readonly any[]) {
    const length = Math.max(lhs.length, rhs.length);

    for (let i = 0; i < length; i++) {
        if (!Object.is(lhs[i], rhs[i])) {
            return false;
        }
    }

    return true;
}

export function useObservable<T>(src: () => Observable<T>, deps: DependencyList, initial: T): T;
export function useObservable<T>(src: () => Observable<T>, deps: DependencyList): T | undefined;
export function useObservable<T>(
    src: () => Observable<T>,
    deps: DependencyList,
    initial?: T,
): T | undefined {
    const [latestValue, setLatestValue] = useState(initial);

    const subscription = useRef(undefined as any as Subscription);
    const prevDeps = useRef(deps);

    if (!subscription.current || !hookDepsAreEqual(deps, prevDeps.current)) {
        if (subscription.current) {
            prevDeps.current = deps;
            subscription.current.unsubscribe();
            setLatestValue(initial);
        }

        subscription.current = src().subscribe(setLatestValue, error => {
            console.warn(`useObservable: uncaught error: ${error}`);
        });
    }

    useEffect(
        () => () => {
            subscription.current.unsubscribe();
        },
        [],
    );

    return latestValue;
}

export function useObservableAction<Args extends any[]>(
    action: (...args: Args) => Observable<unknown>,
    deps: DependencyList,
): (...args: Args) => void {
    const subscription = useRef<Subscription>();

    useEffect(
        () => () => {
            if (subscription.current) {
                subscription.current.unsubscribe();
            }
        },
        [],
    );

    return useCallback((...args: Args) => {
        if (subscription.current) {
            subscription.current.unsubscribe();
        }
        subscription.current = action(...args).subscribe({
            error: error => {
                console.warn(`useObservableAction: uncaught error: ${error}`);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export function useSubscriptionIndicator(): [
    boolean,
    <T>(activity: Observable<T>) => Observable<T>,
] {
    const [subscriptionsCount, setSubscriptionsCount] = useState(0);
    const trackActivity: <T>(activity: Observable<T>) => Observable<T> = useCallback(
        activity =>
            new Observable(observer => {
                setSubscriptionsCount(count => count + 1);
                const subscription = activity.subscribe(observer);

                return () => {
                    setSubscriptionsCount(count => count - 1);
                    subscription.unsubscribe();
                };
            }),
        [],
    );

    return [subscriptionsCount > 0, trackActivity];
}
