import { memo } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';

import { transformTo, To } from 'web/src/hooks/navigation';

type Props = Omit<NavigateProps, 'to'> & {
    to: To;
};

const CustomNavigate = memo((props: Props) => {
    const { to, ...otherProps } = props;

    const transformedTo = transformTo(to);

    return <Navigate to={transformedTo} {...otherProps} />;
});

export { CustomNavigate as Navigate };
