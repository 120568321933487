import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, pairwise, startWith, take, takeWhile } from 'rxjs/operators';
import classNames from 'classnames';

import {
    initializeModule,
    DatingProduct,
    StreamingTheme,
    PaymentReason,
    PaymentSource,
    ShouldAuthorizeUserReason,
    StreamingModuleTheme,
    // @ts-ignore
} from '@sdv-streaming/web';

import { singleton } from '@sdv/commons/utils/singleton';
import { Service } from '@sdv/commons/service';
import { NetworkEnvironment } from '@sdv/network/config';
import { PaymentCoinsBalance } from '@sdv/domain/payment/coins/balance';
import { IdentityModel } from '@sdv/domain/identity/model';
import Flux from '@sdv/domain/flux';
import { UserAuthStatus } from '@sdv/domain/identity/auth-status';
import { CredentialsUpdater } from '@sdv/domain/user/credentials/updater';
import { StreamingCredentials } from '@sdv/domain/streaming/credentials';
import { StreamingAuthenticator } from '@sdv/domain/streaming/authenticator';
import { StreamingUserRoles } from 'web/src/platform/streaming/user-roles';

import { Colors } from 'web/src/styles/colors';
import { ConfigService } from 'web/src/services/config';
import { PaymentsService } from 'web/src/services/payments';

import packageJson from 'web/package.json';

// streaming module styles
import '@sdv-streaming/web/dist/main.css';
// the order of these imports is important, so you can override
// streaming module styles with yours without the `!important` keyword
import buttonClasses from 'web/src/components/commons/button/styles.module.scss';
import leaderboardClasses from 'web/src/screens/leaderboard/styles.module.scss';
import classes from './styles.module.scss';

const primaryButtonClassName = classNames(buttonClasses.primary, classes.primaryButton);

export enum SubTheme {
    messenger,
    streaming,
    videocalls,
}
export class StreamingInitializer implements Service {
    static shared = singleton(() => new StreamingInitializer());

    private readonly initializedSubject = new BehaviorSubject<boolean>(false);

    readonly initialized: Observable<boolean>;

    private constructor() {
        this.initialized = this.initializedSubject.asObservable();
    }

    private authChecked = false;

    shouldAuthorizeUser = (props: {
        onSuccess: () => void;
        onCancel?: () => void;
        beforeAuthorizeStart?: () => void;
        reason?: ShouldAuthorizeUserReason;
    }) => {
        const {
            onSuccess: onSuccessProp,
            onCancel: onCancelProp,
            beforeAuthorizeStart,
            reason,
        } = props;

        this.authChecked = false;

        const complete = () => {
            this.authChecked = true;
            StreamingAuthenticator.authenticatingOnSide = false;
        };
        const onCancel = () => {
            onCancelProp?.();
            complete();
        };
        const onSuccess = () => {
            onSuccessProp();
            complete();
        };

        UserAuthStatus.shared()
            .needToCredUpdating.pipe(
                takeWhile(() => !this.authChecked),
                startWith(undefined),
                distinctUntilChanged(),
                pairwise(),
            )
            .subscribe(([prevNeedToUpdate, needToUpdate]) => {
                if (needToUpdate) {
                    StreamingAuthenticator.authenticatingOnSide = true;
                    CredentialsUpdater.shared().updateCredentials(
                        () => {
                            StreamingCredentials.shared()
                                .token.pipe(
                                    filter(v => !!v),
                                    take(1),
                                )
                                .subscribe(token => {
                                    if (!token) {
                                        return onCancel();
                                    }
                                    StreamingAuthenticator.authenticate(token, err => {
                                        if (err) {
                                            onCancel();
                                        } else {
                                            onSuccess();
                                            if (reason === 'tap-stream-send-comment') {
                                                // it's a temporary workaround to resubscribe to streaming events
                                                // inside the streaming module after the user changed from anonymous
                                                // to real
                                                window.location.reload();
                                            }
                                        }
                                    });
                                });
                        },
                        onCancel,
                        beforeAuthorizeStart,
                        reason,
                    );
                } else if (prevNeedToUpdate && !needToUpdate) {
                    CredentialsUpdater.shared().completed();
                } else {
                    onSuccess();
                }
            });
    };

    start() {
        const clientConfig = ConfigService.shared().config;

        const onPaymentNeeded = (
            amount: number,
            paymentReason: PaymentReason,
            paymentSource: PaymentSource,
            onPaymentCompleted: (success: boolean) => void,
        ) => {
            PaymentsService.shared().startPayment(
                amount,
                paymentReason,
                paymentSource,
                onPaymentCompleted,
            );
        };

        const onPaymentNeedChecked = (amount: number, callback: (necessary: boolean) => void) => {
            const userId = Flux.get(IdentityModel).store.getState().id;
            PaymentCoinsBalance.shared(userId)
                .balance.pipe(take(1))
                .subscribe(balance => {
                    callback(amount > balance);
                });
        };

        const onUserAuthorized = (_: string, roles: string[]) => {
            StreamingUserRoles.shared().updateRoles(roles);
        };

        initializeModule(
            {
                environment: clientConfig.networkEnvironment as NetworkEnvironment,
                theme: 'dark' as StreamingModuleTheme,
                product: {
                    name: clientConfig.streaming.product as DatingProduct,
                    prettyName: clientConfig.productName,
                    webHost: clientConfig.endpoints['web-host'],
                    version: packageJson.version,
                    build: clientConfig.appVersionCode,
                },
                features: {
                    system: {
                        hooks: {
                            shouldAuthorizeUser: this.shouldAuthorizeUser,
                            onUserAuthorized,
                        },
                    },
                    payment: {
                        hooks: {
                            onPaymentNeeded,
                            onPaymentNeedChecked,
                        },
                    },
                    chats: true,
                },
            },
            {
                // @ts-ignore
                [SubTheme.streaming]: {
                    navigationButton: {
                        color: Colors.textColor,
                        fontWeight: 600,
                    },
                    navigationButtonActive: {
                        color: Colors.accentColor,
                        fontWeight: 600,
                        borderBottom: 'none',
                    },
                    shortInfoFollow: {
                        backgroundColor: Colors.accentColor,
                    },
                    streamChatFollow: {
                        backgroundColor: Colors.accentColor,
                    },
                    streamPausedFollow: {
                        backgroundColor: Colors.accentColor,
                    },
                    reportUserPanelButton: {
                        backgroundColor: Colors.accentColor,
                        borderRadius: 16,
                    },
                    liveLabel: {
                        backgroundColor: Colors.accentColor,
                    },
                    liveViews: {
                        backgroundColor: 'rgba(0,0,0,0.4)',
                    },
                    leaderboardListUserAvatar: {
                        size: 40,
                    },
                    classNames: {
                        streamPrevButton: classes.prevNextButton,
                        streamNextButton: classes.prevNextButton,
                        popupWindow: classes.popupWindow,
                        popupCloseButton: classes.popupCloseButton,
                        leaderboardFullTab: classes.leaderboardFullTab,
                        leaderboardFullTabActive: classes.leaderboardFullTabActive,
                        streamerCardSendButton: primaryButtonClassName,
                        goLiveButton: primaryButtonClassName,
                        goalStartButton: primaryButtonClassName,
                        goalSliderThumb: classes.goalSliderThumb,
                        goalInput: classes.goalInput,
                        currentUserStatusFormButton: primaryButtonClassName,
                        currentUserMorePopupButton: primaryButtonClassName,
                        paidBroadcastJoinPriceButton: primaryButtonClassName,
                        customModalActionButton: classes.customModalActionButton,
                        leaderboard: {
                            container: leaderboardClasses.container,
                            topMenuWrapper: leaderboardClasses.topMenuWrapper,
                            subMenuTab: leaderboardClasses.subMenuTab,
                            subMenuTabActive: leaderboardClasses.subMenuTabActive,
                            followButton: leaderboardClasses.followButton,
                            userFollowedButton: leaderboardClasses.userFollowedButton,
                            listUserAvatar: leaderboardClasses.listUserAvatar,
                            listUserName: leaderboardClasses.listUserName,
                            topThreeListContainer: leaderboardClasses.topThreeListContainer,
                        },
                    },
                } as StreamingTheme,
            },
        );

        this.initializedSubject.next(true);
    }
}
