import { memo, useCallback } from 'react';
// @ts-ignore
import { StreamerComponent } from '@sdv-streaming/web';

import { useNavigate } from 'web/src/hooks/navigation';
import { ScreenRoutes } from 'web/src/constants/routing';

import classes from './styles.module.scss';

export const BroadcastScreen = memo(() => {
    const navigate = useNavigate();

    const handleOnClosePress = useCallback(() => {
        navigate(ScreenRoutes.Dashboard);
    }, [navigate]);

    return (
        <div className={classes.container}>
            <StreamerComponent onClose={handleOnClosePress} />
        </div>
    );
});
