/* global window */
import { Dispatcher } from 'flux'

import * as StateFunctions from './utils/StateFunctions'
import * as fn from './functions'
import * as store from './store'
import * as utils from './utils/AltUtils'
import makeAction from './actions'

class Alt {
  constructor(config = {}) {
    this.config = config
    this.serialize = config.serialize || JSON.stringify
    this.deserialize = config.deserialize || JSON.parse
    this.dispatcher = config.dispatcher || new Dispatcher()
    this.batchingFunction = config.batchingFunction || (callback => callback())
    this.actions = { global: {} }
    this.stores = {}
    this.storeTransforms = config.storeTransforms || []
    this.trapAsync = false
    this._actionsRegistry = {}
    this._initSnapshot = {}
    this._lastSnapshot = {}
    this.eventsCallbacks = {}
  }

  dispatch(action, data, details) {
    this.batchingFunction(() => {
      const id = Math.random().toString(18).substr(2, 16)

      // support straight dispatching of FSA-style actions
      if (action.hasOwnProperty('type') && action.hasOwnProperty('payload')) {
        const fsaDetails = {
          id: action.type,
          namespace: action.type,
          name: action.type,
        }
        return this.dispatcher.dispatch(
          utils.fsa(id, action.type, action.payload, fsaDetails)
        )
      }

      if (action.id && action.dispatch) {
        return utils.dispatch(id, action, data, this)
      }

      return this.dispatcher.dispatch(utils.fsa(id, action, data, details))
    })
  }

  createUnsavedStore(StoreModel, ...args) {
    const key = StoreModel.displayName || ''
    store.createStoreConfig(this.config, StoreModel)
    const Store = store.transformStore(this.storeTransforms, StoreModel)

    return fn.isFunction(Store)
      ? store.createStoreFromClass(this, Store, key, ...args)
      : store.createStoreFromObject(this, Store, key)
  }

  createStore(StoreModel, id, ...args) {
    id = id || 'default'
    let name
    const isStoreEBI = !!StoreModel.getDisplayName
    if (isStoreEBI) {
      name = StoreModel.getDisplayName(id).split('.')[0]
    } else {
      name = StoreModel.displayName
    }

    const existedStore = this.stores[name] && this.stores[name][id]

    if (existedStore) {
      console.log(`You already have a store with name ${name}`)
      return existedStore
    }

    if (isStoreEBI) {
      StoreModel = new StoreModel(id)
    }

    store.createStoreConfig(this.config, StoreModel)
    const Store = store.transformStore(this.storeTransforms, StoreModel)

    args.push(this)
    const storeInstance = fn.isFunction(Store)
      ? store.createStoreFromClass(this, Store, id, ...args)
      : store.createStoreFromObject(this, Store, id)

    this.stores[name] = this.stores[name] || {}
    this.stores[name][id] = storeInstance

    StateFunctions.saveInitialSnapshot(this, name + '.' + id)

    return storeInstance
  }

  generateActions(...actionNames) {
    const actions = { name: 'global' }
    return this.createActions(actionNames.reduce((obj, action) => {
      obj[action] = utils.dispatchIdentity
      return obj
    }, actions))
  }

  createAction(name, implementation, obj) {
    return makeAction(this, 'global', null, name, implementation, obj)
  }

  createActions(ActionsClass, exportObj = {}, ...argsForConstructor) {
    let name

    const isActionsEBI = !!ActionsClass.getDisplayName
    const id = ActionsClass.id || 'default'

    if (isActionsEBI) {
      name = ActionsClass.getDisplayName(id).split('.')[0]
    } else {
      name = ActionsClass.displayName
    }

    const existedActions = this.actions[name] && this.actions[name][id]

    if (existedActions) {
      console.log(`You already have an action with name ${name}`)
      return existedActions
    }

    if (isActionsEBI) {
      ActionsClass = new ActionsClass(id)
    }

    const actions = {}

    if (fn.isFunction(ActionsClass)) {
      fn.assign(actions, utils.getPrototypeChain(ActionsClass))
      class ActionsGenerator extends ActionsClass {
        constructor(...args) {
          super(...args)
        }

        generateActions(...actionNames) {
          actionNames.forEach((actionName) => {
            actions[actionName] = utils.dispatchIdentity
          })
        }
      }

      fn.assign(actions, new ActionsGenerator(...argsForConstructor))
    } else {
      fn.assign(actions, ActionsClass)
    }

    this.actions[name] = this.actions[name] || {}
    this.actions[name][id] = this.actions[name][id] || {}

    fn.eachObject((actionName, action) => {
      if (!fn.isFunction(action)) {
        if (actionName === 'displayName' && action.indexOf('.') === -1) {
          action += '.default'
        }
        exportObj[actionName] = action
        return
      }

      // create the action
      exportObj[actionName] = makeAction(
        this,
        name,
        id,
        actionName,
        action,
        exportObj
      )

      // generate a constant
      const constant = utils.formatAsConstant(actionName)
      exportObj[constant] = exportObj[actionName].id
    }, [actions])

    if (!exportObj.displayName) {
      exportObj.displayName = `${name}.${id}`
    }

    return exportObj
  }

  takeSnapshot(...storeNames) {
    const state = StateFunctions.snapshot(this, storeNames)
    fn.assign(this._lastSnapshot, state)
    return this.serialize(state)
  }

  rollback() {
    StateFunctions.setAppState(
      this,
      this.serialize(this._lastSnapshot),
      storeInst => {
        storeInst.lifecycle('rollback')
        storeInst.emitChange()
      }
    )
  }

  recycle(...storeNames) {
    const initialSnapshot = storeNames.length
      ? StateFunctions.filterSnapshots(
          this,
          this._initSnapshot,
          storeNames
        )
      : this._initSnapshot

    StateFunctions.setAppState(
      this,
      this.serialize(initialSnapshot),
      (storeInst) => {
        storeInst.lifecycle('init')
        storeInst.emitChange()
      }
    )
  }

  flush() {
    const state = this.serialize(StateFunctions.snapshot(this))
    this.recycle()
    return state
  }

  bootstrap(data) {
    StateFunctions.setAppState(this, data, (storeInst, state) => {
      storeInst.lifecycle('bootstrap', state)
      storeInst.emitChange()
    })
  }

  prepare(storeInst, payload) {
    const data = {}
    if (!storeInst.displayName) {
      throw new ReferenceError('Store provided does not have a name')
    }
    data[storeInst.displayName] = payload
    return this.serialize(data)
  }

  // Instance type methods for injecting alt into your application as context

  addActions(name, ActionsClass, ...args) {
    this.actions[name] = Array.isArray(ActionsClass)
      ? this.generateActions.apply(this, ActionsClass)
      : this.createActions(ActionsClass, ...args)
  }

  addStore(name, StoreModel, ...args) {
    this.createStore(StoreModel, name, ...args)
  }

  getActions(ActionsModelOrString, id = 'default') {
    let name

    if (typeof ActionsModelOrString === 'string') {
      const keys = ActionsModelOrString.split('.')
      name = keys[0]
      id = keys[1] || 'default'
      return this.actions[name] && this.actions[name][id] || undefined
    }

    if (ActionsModelOrString.getDisplayName) {
      name = ActionsModelOrString.getDisplayName(id).split('.')[0]
      ActionsModelOrString.id = id
      return this.actions[name] && this.actions[name][id] || this.createActions(ActionsModelOrString)
    }

    if (!ActionsModelOrString.displayName) {
      utils.warn(
        `Actions ${ActionsModelOrString} don't have a displayName`
      )
    }
    name = ActionsModelOrString.displayName
    ActionsModelOrString.id = id
    return this.actions[name] && this.actions[name][id] || this.createActions(ActionsModelOrString)
  }

  getStore(StoreModelOrString, id = 'default') {
    let name

    if (typeof StoreModelOrString === 'string') {
      const keys = StoreModelOrString.split('.')
      name = keys[0]
      id = keys[1] || 'default'
      return this.stores[name] && this.stores[name][id] || undefined
    }

    if (StoreModelOrString.getDisplayName) {
      name = StoreModelOrString.getDisplayName(id).split('.')[0]
      return this.stores[name] && this.stores[name][id] || this.createStore(StoreModelOrString, id)
    }

    if (!StoreModelOrString.displayName) {
      utils.warn(
        `Store ${StoreModelOrString} doesn't have a displayName`
      )
    }
    name = StoreModelOrString.displayName
    return this.stores[name] && this.stores[name][id] || this.createStore(StoreModelOrString, id)
  }

  get(constructors, ...args) {
    let model = {};

    if(constructors.hasOwnProperty('store')){
      model.store = this.getStore.apply(this, [constructors.store, ...args]);
    }
    if(constructors.hasOwnProperty('actions')){
      model.actions = this.getActions.apply(this, [constructors.actions, ...args]);
    }
    return model;
  }

  on(event, cb) {
    if (!(cb instanceof Function)) {
      utils.warn(`on ${event} method callback must be function`)
      return
    }

    this.eventsCallbacks[event] = this.eventsCallbacks[event] || [];
    this.eventsCallbacks[event].push(cb)
  }

  off(event, cb) {
    this.eventsCallbacks[event] = this.eventsCallbacks[event].filter(item => item !== cb)
  }

  static debug(name, alt, win) {
    const key = 'alt.js.org'
    let context = win
    if (!context && typeof window !== 'undefined') {
      context = window
    }
    if (typeof context !== 'undefined') {
      context[key] = context[key] || []
      context[key].push({ name, alt })
    }
    return alt
  }
}

export default Alt
