import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useObservable } from '@sdv/presentation/rx/hooks';
import { UserAuthStatus } from '@sdv/domain/identity/auth-status';

import { useCurrentUser, useUserBalance } from 'web/src/hooks/user';
import { AuthService } from 'web/src/services/auth';
import { StreamsList } from 'web/src/components/streams-list';
import { Modals } from 'web/src/components/modals';
import { Alerts } from 'web/src/components/alerts';
import { ScreenRoutes } from 'web/src/constants/routing';
import { useNavigate } from 'web/src/hooks/navigation';
import { matches } from 'web/src/utils/navigation';
import { StreamScreen } from 'web/src/screens/stream';
import { BroadcastScreen } from 'web/src/screens/broadcast';
import { LeaderboardScreen } from 'web/src/screens/leaderboard';
import { useScreensStack } from 'web/src/hooks/screens-stack';

import View from './view';
import { useRoutesGuard } from './routes-guard';

export const Dashboard = memo(() => {
    const { user, loading } = useCurrentUser();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const screensStack = useScreensStack();

    const userBalance = useUserBalance(user?.id);
    const importantFieldsFilled = useObservable(
        () => UserAuthStatus.shared().importantFieldsIsFilled,
        [],
    );

    useRoutesGuard(!!user, !!importantFieldsFilled, loading || importantFieldsFilled === undefined);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pathname === ScreenRoutes.Leaderboard) {
            scrollContainerRef.current?.scrollTo(0, 0);
        }
    }, [pathname]);

    const handleSignOut = useCallback(async () => {
        const success = await AuthService.shared().signOut();
        if (success) {
            navigate(ScreenRoutes.Dashboard);
            // this is needed to refresh the app data (especially inside the streaming module)
            window.location.reload();
        }
    }, [navigate]);

    const secondLvlScreen = screensStack[1];

    const showBroadcast = useMemo(
        () => matches(secondLvlScreen, ScreenRoutes.Live),
        [secondLvlScreen],
    );

    const showStream = useMemo(
        () => !showBroadcast && matches(secondLvlScreen, ScreenRoutes.Stream),
        [showBroadcast, secondLvlScreen],
    );

    const showStreamsList = !showBroadcast && !showStream;

    const showLeaderboard = secondLvlScreen === ScreenRoutes.Leaderboard;

    return (
        <>
            <View
                userBalance={userBalance}
                scrollEnabled={pathname === ScreenRoutes.Dashboard}
                user={user}
                onSignOut={handleSignOut}
                scrollContainerRef={scrollContainerRef}
            >
                {showStreamsList && <StreamsList />}
                {showBroadcast && <BroadcastScreen />}
                {showStream && <StreamScreen />}
                {showLeaderboard && <LeaderboardScreen />}
                {/* it is an actual nested route */}
                <Outlet />
            </View>
            <Modals />
            <Alerts />
        </>
    );
});
