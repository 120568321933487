import Alt from '@sdv/alt';

type Media = {
    basename: string;
    tags: string[];
    mediatype?: string;
};

// TODO: fill
export type State = {
    media?: Media[];
};

// TODO: fill
type Actions = {
    actualize: (media: Media, cb?: (err?: any) => void) => void;
    addPhotoTag: (basename: string, tag: string, cb?: (err?: any) => void) => void;
    removePhotoTag: (basename: string, tag: string, cb?: (err?: any) => void) => void;
    putPhotos: (basenames: string[], cb?: (err?: any) => void) => void;
    putVideos: (basenames: string[], cb?: (err?: any) => void) => void;
    get: (cb?: (err?: any) => void) => void;
};

type ActionsDeclaration = Alt.ActionsDeclaration<Actions>;
type StoreDeclaration = Alt.StoreDeclaration<Alt.Store<State>>;

/* eslint-disable global-require */
const actions = require('./actions').default as ActionsDeclaration;
const store = require('./store').default as StoreDeclaration;
/* eslint-enable global-require */

type UserMediaDeclaration = Alt.ModelDeclaration<ActionsDeclaration, StoreDeclaration>;

export const UserMediaModel: UserMediaDeclaration = {
    store,
    actions,
};
