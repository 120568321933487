import { of } from 'rxjs';

import Flux from '@sdv/domain/flux';
import { IdentityModel } from '@sdv/domain/identity/model';
import { UserRepository } from '@sdv/domain/user/repositories';
import { useObservable } from '@sdv/presentation/rx/hooks';
import { PaymentCoinsBalance } from '@sdv/domain/payment/coins/balance';

export const useUser = (id: string | undefined) => {
    const user = useObservable(() => {
        if (!id) {
            return of(null);
        }
        return UserRepository.shared(id).user;
    }, [id]);

    if (user === undefined) {
        // observer not initialized
        return { user: null, loading: true };
    }

    return { user, loading: false };
};

export const useUserBalance = (id: string | undefined) => {
    const userBalance = useObservable(
        () => (id ? PaymentCoinsBalance.shared(id).balance : of(0)),
        [id],
        0,
    );

    return userBalance;
};

export const useCurrentUser = () => {
    const identity = useObservable(() => Flux.get(IdentityModel).store.rxState(), []);

    const user = useUser(identity?.demo ? undefined : identity?.id);

    if (identity === undefined) {
        // observer not initialized
        return { loading: true, user: null };
    }

    return user;
};

export const useCurrentUserBalance = () => {
    const { user } = useCurrentUser();

    const balance = useUserBalance(user?.id);

    return balance;
};
