import { CSSProperties, ImgHTMLAttributes, memo, useMemo } from 'react';
import classNames from 'classnames';

import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type ImgProps = ImgHTMLAttributes<HTMLImageElement>;

type Props = Omit<ImgProps, 'src'> & {
    uri?: string;
    /**
     * Needed to make initials string on placeholder
     */
    name?: string;
    size?:
        | number
        | {
              width: number;
              height: number;
          };
};

const getInitials = (name: string) => {
    const [first, second = ''] = name
        .split(/\s+/)
        .filter(w => w)
        .map(w => w[0]);

    return `${first}${second}`.toUpperCase();
};

export const Avatar = memo((props: Props) => {
    const { uri, name, size, style, className, ...rest } = props;

    const { width, height } = useMemo(() => {
        if (typeof size === 'object') {
            return size;
        }
        return { width: size, height: size };
    }, [size]);

    const sizeStyle = useMemo(
        () =>
            ({
                width,
                height,
                minWidth: width,
                minHeight: height,
                ...style,
            } as CSSProperties),
        [width, height, style],
    );

    const initials = useMemo(() => !uri && !!name && getInitials(name), [uri, name]);

    if (uri) {
        return (
            <img
                className={classNames(classes.avatar, className)}
                style={sizeStyle}
                src={uri}
                alt=""
                {...rest}
            />
        );
    }

    if (initials) {
        return (
            <div className={classNames(classes.initials, className)}>
                <span>{initials}</span>
            </div>
        );
    }

    return (
        <div className={classNames(classes.placeholder, className)}>
            <img src={Resources.images.userIcon} alt="" style={sizeStyle} {...rest} />
        </div>
    );
});
