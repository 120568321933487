import { useCallback } from 'react';

import { Resources } from 'web/src/resources';
import { useNavigate } from 'web/src/hooks/navigation';
import { ModalRoute } from 'web/src/components/modal-route';
import { ScreenRoutes } from 'web/src/constants/routing';
import { PaymentsService } from 'web/src/services/payments';

import View from './view';

import classes from './styles.module.scss';

export const BuyCoinsScreen = () => {
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        PaymentsService.shared().closePayment();
        navigate(-1);
    }, [navigate]);

    const handlePackSelected = useCallback(
        (packId: number) => {
            navigate({
                pathname: ScreenRoutes.Purchase,
                query: { packId },
            });
        },
        [navigate],
    );

    return (
        <ModalRoute
            modalKey="buy-coins-modal"
            title={Resources.strings['buy-coins']}
            onClose={handleClose}
            content={<View onPackSelected={handlePackSelected} />}
            className={classes.buyCoinsModal}
        />
    );
};
