import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type DivProps = HTMLAttributes<HTMLDivElement>;

type Props = DivProps & {
    amount: number;
};

export const Coins = (props: Props) => {
    const { amount, className, ...rest } = props;

    return (
        <div className={classNames(classes.container, className)} {...rest}>
            <img src={Resources.images.coinIcon} alt="coin icon" />
            <span>{amount}</span>
        </div>
    );
};
