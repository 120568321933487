import { ScreenRoutes } from 'web/src/constants/routing';

const paramRegExp = /\/:\w+/g;

/**
 * This function omits params (e.g: `:id`) from the route string
 */
export const purifyRoute = (route: ScreenRoutes) => route.replace(paramRegExp, '');
/**
 * This function omits params (e.g: `:id`) from the routes strings
 */
export const purifyRoutes = (routes: ScreenRoutes[]) => routes.map(purifyRoute);

export const matches = (path: string, route: ScreenRoutes) => {
    if (path) {
        if (path === route) {
            return true;
        }
        if (paramRegExp.test(route)) {
            return path.includes(purifyRoute(route));
        }
    }
    return false;
};
