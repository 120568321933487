import homeIcon from './assets/home.svg';
import crownIcon from './assets/crown.svg';
import playIcon from './assets/play.svg';
import qrCodeIcon from './assets/qr-code.svg';
import moreIcon from './assets/ic-more.svg';
import closeIcon from './assets/ic-close.svg';
import userIcon from './assets/ic-user.svg';
import coinIcon from './assets/ic-coin.svg';
import searchIcon from './assets/ic-search.svg';
import logoIcon from './assets/logo-icon.svg';
import logoText from './assets/logo-text.svg';
import favoriteIconFilled from './assets/ic-favorite-filled.svg';
import favoriteIcon from './assets/ic-favorite.svg';
import checkIcon from './assets/ic-check.svg';
import googleIcon from './assets/google-icon.svg';
import facebookIcon from './assets/facebook-icon.svg';

export default {
    homeIcon,
    crownIcon,
    playIcon,
    qrCodeIcon,
    moreIcon,
    closeIcon,
    userIcon,
    coinIcon,
    searchIcon,
    logoIcon,
    logoText,
    favoriteIconFilled,
    favoriteIcon,
    checkIcon,
    googleIcon,
    facebookIcon,
};
