import axios, { AxiosRequestHeaders } from 'axios';
import { format } from 'url';

import { platform } from '@sdv/domain/system/platform';

import { context, protocol } from '../api-context';
import { ApiGetEvents, ApiGetEventsCount, ApiGetUser, ApiGetUserMedia, UserTagType } from './types';

export const users = {
    user: (userId?: string) => ({
        get: () => {
            // init value for userId = default, don't send request for it value
            // it hot fix, before refactoring auth layer
            if (!userId || userId === 'default') {
                return Promise.reject();
            }

            return axios.get<ApiGetUser>(`${context.server}/users/${userId}`, {
                headers: context.headers,
            });
        },

        patch: (body: any) => {
            // init value for userId = default, don't send request for it value
            // it hot fix, before refactoring auth layer
            if (!userId || userId === 'default') {
                return Promise.reject();
            }

            return axios.patch(`${context.server}/users/${userId}`, body, {
                headers: context.patchHeaders,
            });
        },

        devices: {
            post: (body: any) =>
                axios.post(`${context.server}/users/${userId}/devices`, body, {
                    headers: context.patchHeaders,
                }),
        },

        photos: {
            get: () =>
                axios.get<ApiGetUserMedia>(`${context.server}/users/${userId}/photos`, {
                    headers: {
                        ...context.headers,
                        accept: 'application/vnd.sdv.filetags+json',
                    },
                }),
            put: (body: any) =>
                axios.put(`${context.server}/users/${userId}/photos`, body, {
                    headers: context.patchHeaders,
                }),
            post: ({ body }: { body: FormData }) =>
                axios.post(`${context.server}/users/${userId}/photos`, body, {
                    headers: { ...context.authHeaders, 'content-type': 'multipart/form-data' },
                }),

            origin: {
                get: () =>
                    axios.get<ApiGetUserMedia>(`${context.server}/users/${userId}/photos/origin`, {
                        headers: {
                            ...context.headers,
                            accept: 'application/vnd.sdv.filetags+json',
                        },
                    }),
            },

            tags: (basename: string) => ({
                put: (tag: string) =>
                    axios.put(
                        `${context.server}/users/${userId}/photos/${basename}/tags/${tag}`,
                        undefined,
                        {
                            headers: context.patchHeaders,
                        },
                    ),
                delete: (tag: string) =>
                    axios.delete(
                        `${context.server}/users/${userId}/photos/${basename}/tags/${tag}`,
                        { headers: context.authHeaders },
                    ),
            }),
        },

        videos: {
            get: () =>
                axios.get<ApiGetUserMedia>(`${context.server}/users/${userId}/videos`, {
                    headers: {
                        ...context.headers,
                        accept: 'application/vnd.sdv.filetags+json',
                    },
                }),
            put: (body: any) =>
                axios.put(`${context.server}/users/${userId}/videos`, body, {
                    headers: context.patchHeaders,
                }),
            post: ({ body }: { body: FormData }) =>
                axios.post(`${context.server}/users/${userId}/videos`, body, {
                    headers: { ...context.authHeaders, 'content-type': 'multipart/form-data' },
                }),

            origin: {
                get: () =>
                    axios.get<ApiGetUserMedia>(`${context.server}/users/${userId}/videos/origin`, {
                        headers: {
                            ...context.headers,
                            accept: 'application/vnd.sdv.filetags+json',
                        },
                    }),
            },
        },

        private: {
            get: () => {
                const headers: AxiosRequestHeaders = {
                    ...context.authHeaders,
                };
                if (platform() === 'mobile') {
                    headers.date = new Date().toISOString();
                }
                return axios.get<ApiGetUser>(`${context.server}/users/private/${userId}`, {
                    headers,
                });
            },
        },

        tags: {
            get: () =>
                axios.get<UserTagType[]>(`${context.server}/users/${userId}/tags`, {
                    headers: context.authHeaders,
                }),
            put: (tag: any) =>
                axios.put(
                    `${context.server}/users/${userId}/tags/${tag}`,
                    {},
                    {
                        headers: context.patchHeaders,
                    },
                ),
            delete: (tag: any) =>
                axios.delete(`${context.server}/users/${userId}/tags/${tag}`, {
                    headers: context.patchHeaders,
                }),
            vowels: {
                get: () =>
                    axios.get<string[]>(`${context.server}/users/${userId}/tags/vowels`, {
                        headers: context.authHeaders,
                    }),
            },
        },

        events: (type: string) => ({
            index: () =>
                axios.get<ApiGetEventsCount>(
                    `${context.server}/users/${userId}/events?types=%2B${type}`,
                    {
                        headers: {
                            ...context.authHeaders,
                            accept: 'application/json+vnd.sdv.numeric',
                        },
                    },
                ),
            get: (omit: number, select: number) =>
                axios.get<ApiGetEvents>(
                    format({
                        protocol,
                        host: context.host,
                        pathname: `/users/${userId}/events`,
                        query: {
                            types: `+${type}`,
                            omit,
                            select,
                        },
                    }),
                    { headers: context.authHeaders },
                ),
            put: (eventId: number, body: any) =>
                axios.put(`${context.server}/users/${userId}/events/${eventId}`, body, {
                    headers: context.patchHeaders,
                }),
            delete: (eventId: number) =>
                axios.delete(`${context.server}/users/${userId}/events/${eventId}`, {
                    headers: context.authHeaders,
                }),
        }),

        roles: {
            get: () => {
                // init value for userId = default, don't send request for it value
                // it hot fix, before refactoring auth layer
                if (!userId || userId === 'default') {
                    return Promise.resolve([]);
                }

                return axios.get<string[]>(`${context.server}/users/${userId}/roles`, {
                    headers: context.authHeaders,
                });
            },

            suspended: {
                put: () =>
                    axios.put(`${context.server}/users/${userId}/roles/suspended`, undefined, {
                        headers: context.patchHeaders,
                    }),
            },
        },
    }),
};
