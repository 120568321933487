import Alt from '@sdv/alt';

type ExternalSystem = 'google' | 'facebook' | 'apple';

type AuthorizationAction = 'signup' | 'signin';

export type State = {
    id: string;
    token?: string;
    roles: string[];
    demo?: boolean;
    authorizationMethod?: `${AuthorizationAction}:${ExternalSystem | 'token' | 'password'}`;
    authorizationAction?: AuthorizationAction;
    email?: string;
    password?: string;
    authTime?: Date;
};

// TODO: fill
type Actions = {
    auth: (token: string, cb?: (err?: any) => void) => void;
    loginUser: (email: string, password: string, cb?: (err?: any) => void) => void;
    signUp: (email: string, password: string, cb?: (err?: any) => void) => void;
    signUpAsAnonymous: (cb?: (err?: any) => void) => void;
    signOut: (cb?: (err?: any) => void) => (dispatch: Alt.Dispatch) => void;
    delete: (cb?: (err?: any) => void) => (dispatch: Alt.Dispatch) => void;
    signInViaOAuth2: (
        system: ExternalSystem,
        token: string,
        cb?: (err?: any) => void,
    ) => (dispatch: Alt.Dispatch) => void;
    signUpViaOAuth2: (
        system: ExternalSystem,
        token: string,
        email: string,
        cb?: (err?: any) => void,
    ) => (dispatch: Alt.Dispatch) => void;
    signUpAppleViaOAuth2: (
        system: ExternalSystem,
        token: string,
        cb?: (err?: any) => void,
    ) => (dispatch: Alt.Dispatch) => void;
};

type IMActionsDeclaration = Alt.ActionsDeclaration<Actions>;
type IMStoreDeclaration = Alt.StoreDeclaration<Alt.Store<State>>;

/* eslint-disable global-require */
const actions = require('./actions').default as IMActionsDeclaration;
const store = require('./store').default as IMStoreDeclaration;
/* eslint-enable global-require */

type IMDeclaration = Alt.ModelDeclaration<IMActionsDeclaration, IMStoreDeclaration>;

export const IdentityModel: IMDeclaration = {
    store,
    actions,
};
