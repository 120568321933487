import { string, object, SchemaOf } from 'yup';

import { Resources } from 'web/src/resources';

import { FormFields } from './types';

export const validationSchema: SchemaOf<FormFields> = object({
    email: string()
        .trim()
        .required(
            Resources.strings.formatString(
                Resources.strings.validation.required,
                Resources.strings.email,
            ),
        )
        .email(
            Resources.strings.formatString(
                Resources.strings.validation.incorrect,
                Resources.strings.email,
            ),
        ),
    password: string()
        .required(
            Resources.strings.formatString(
                Resources.strings.validation.required,
                Resources.strings.password,
            ),
        )
        .min(4, Resources.strings.formatString(Resources.strings.validation.min, 4, 's')),
});
