import { memo, useEffect } from 'react';

import { ModalProps, ModalsService } from 'web/src/services/modals';
import { ScreenRoutes } from 'web/src/constants/routing';
import { history } from 'web/src/services/history';
import { purifyRoutes } from 'web/src/utils/navigation';

type Props = Omit<ModalProps, 'key'> & {
    keepFor?: ScreenRoutes[];
    modalKey: string;
};

export const ModalRoute = memo((props: Props) => {
    const { keepFor, modalKey: key, canClose, title, onClose, content, className } = props;

    useEffect(() => {
        ModalsService.shared().show({
            key,
            title,
            onClose,
            content,
            className,
            canClose,
        });
    }, [key, title, onClose, content, className, canClose]);

    useEffect(
        () => () => {
            if (
                !keepFor ||
                !purifyRoutes(keepFor).includes(history.location.pathname as ScreenRoutes)
            ) {
                ModalsService.shared().close(key);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return null;
});
