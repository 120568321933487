import axios, { AxiosRequestHeaders } from 'axios';
import { format } from 'url';

import { platform } from '@sdv/domain/system/platform';

import { context, protocol } from '../api-context';
import { GetEventsParams, GetEventsResponse } from './types';

export const events = {
    events: {
        get: (params?: GetEventsParams) => {
            const headers: AxiosRequestHeaders = {
                ...context.authHeaders,
            };
            if (platform() === 'mobile') {
                headers.date = new Date().toISOString();
            }

            return axios.get<GetEventsResponse[]>(
                format({
                    protocol,
                    host: context.host,
                    pathname: `/events/${context.userId}`,
                    query: params,
                }),
                { headers },
            );
        },
    },
};
