import Alt from '@sdv/alt';

import { Gender } from '@sdv/domain/user/gender';

export type UserProfile = {
    id: string,
    name: string,
    gender?: Gender,
    birthday?: string,
    tags: string[],
    'registration-date': string
    'thumbnail-pending'?: string;
    thumbnail?: string;
}

type State = Partial<UserProfile>

type Actions = {
    get: () => (dispatch: Alt.Dispatch) => void
    patch: <Properties extends keyof State>(update: Pick<State, Properties>) => (dispatch: Alt.Dispatch) => void
    actualize: <Properties extends keyof State>(update: Pick<State, Properties>) => (dispatch: Alt.Dispatch) => void
}

const actions = require('./actions').default as (userId: string) => (new() => Actions)
const store = require('./store').default as (userId: string) => (new(flux: Alt) => Alt.Store<State, { isFilled(): boolean }>)

export const UserModel = {
    store: store,
    actions: actions
}
