import LocalizedStrings, { LocalizedStrings as LocalizedStringsType } from 'react-localization';

import commonStrings from './strings/strings.common.json';
import commonImages from './images/images.common';
import commonAnimations from './animations/animations.common';

type LocalizedStringsTypeOverride = Omit<
    LocalizedStringsType<typeof commonStrings['en-US']>,
    'formatString'
> & {
    formatString(value: string, ...args: (number | string)[]): string;
    formatString(value: string, ...args: (number | string | JSX.Element)[]): string | string[];
};

export const Resources = {
    strings: new LocalizedStrings(commonStrings) as LocalizedStringsTypeOverride,
    images: commonImages,
    animations: commonAnimations,
};
