/* eslint-disable react/no-unescaped-entities */
import { memo } from 'react';

import classes from './styles.module.scss';

export default memo(() => (
    <div className={classes.container}>
        <p>March, 2023</p>
        <p>
            These Terms of Use (“Terms”) govern your (“User”) use of Tubit and the web-site,
            applications for Android and IOS, content, and Service (collectively as “Tubit”,
            &quot;us&quot;, &quot;we&quot;, or &quot;our&quot;). These Terms is made by you and
            Reilin Technologies Limited located at Aglantzias, 62, EKATERINI NICOSIA BUSINESS
            CENTER, Flat/Office 109, Aglantzia, 2108, Nicosia, Cyprus (“The Company”).
        </p>
        <p>
            These Terms apply to all visitors, users and others who wish to access or use the
            Service.
        </p>
        <p>
            By accessing Tubit, you acknowledge that you have read, understood, and agree to be
            bound by the following Terms of Use, our Privacy Policy and our Refund Policy.
            At our sole discretion, we may amend these Terms from time to time, and the
            current version will be found at the corresponding link at our web-site and
            applications. By continuing to use Tubit, you agree to be bound by the amended Terms.
        </p>
        <ol>
            <li>
                <b>ELIGIBILITY</b>
                <p>
                    Tubit is intended solely for Users who are at least 18 years old. By using
                    Tubit, You represent and warrant that You have the right, authority, and
                    capacity to enter into these Terms and to abide by all of the terms and
                    conditions of these Terms. You may not assign or otherwise transfer Your account to any
                    other person or entity. Tubit may terminate your account at any time delete any
                    content or information you have submitted, and prohibit you from using the
                    Software or services provided by Tubit (hereinafter as the “Services”), if it
                    has any reason to believe that you are under 18 years old.
                </p>
            </li>
            <li>
                <b>USING TUBIT</b>
                <p>
                    Upon registering with the Tubit, a User shall have the right to purchase coins which can be spent on Tubit, particularly:
                </p>
                <br />
                <p>Sending Gifts starting from 99 Coins per Gift.</p>
            </li>
            <li>
                <b>OWNERSHIP</b>
                <p>
                    Tubit owns licenses or otherwise retains all intellectual property rights in the
                    Website/ Application and the Service, unconditionally. Those rights include, but
                    are not limited to, database rights, copyright, design rights (whether
                    registered or unregistered), patents, trademarks (whether registered or
                    unregistered), trade secrets and other similar rights, wherever existing in the
                    world, together with the right to apply for protection of the same of Tubit and
                    its licensors. Except for that information which is in the public domain or for
                    which You have been given written permission, You may not copy, modify, publish,
                    transmit, distribute, perform, display, or sell any such proprietary
                    information.
                </p>
            </li>
            <li>
                <b>RESTRICTIONS OF USE AND USER WARRANTIES</b>
                <ol>
                    <li>
                        You agree to use the Service in a manner consistent with any and all
                        applicable laws and regulations.
                    </li>
                    <li>
                        You agree that Tubit may at its sole discretion store for review and delete
                        any content, messages, photos, videos, audio and video calls or profiles
                        (collectively, “Content”) that in the sole judgment of Tubit violate these
                        Terms or which might be offensive, illegal, or that might violate the rights
                        of, harm, or threaten the safety of other Users or any third parties.
                    </li>
                    <li>
                        By posting Content to any public area of Tubit You automatically grant,
                        represent and warrant that You have the right to grant to Tubit an
                        irrevocable, perpetual, non-exclusive, fully paid, worldwide license to use,
                        copy, perform, display, and distribute such information and Content and to
                        prepare derivative works of, or incorporate into other works, such
                        information and content, and to grant and authorize sublicenses of the
                        foregoing.
                    </li>
                    <li>
                        You are solely responsible for the Content that You publish, stream,
                        transmit, display or transfer to other Users via Tubit. In case there is
                        another person seen in Your Content then such person should be notified
                        about that and the prior written consent should be received from them.
                    </li>
                    <li>
                        Content that is illegal or prohibited on Tubit is as follows - the Content
                        that
                        <ul>
                            <li>
                                promotes racism, hatred, abuse or physical harm of any kind against
                                any group or individual;
                            </li>
                            <li>harasses or advocates harassment of another person;</li>
                            <li>
                                publicly displays inappropriate Content or material, involving, for
                                example, nudity, bestiality, pornography, graphic violence, or
                                criminal activity;
                            </li>
                            <li>
                                involves the transmission of “junk mail”, “chain letters”, or
                                unsolicited mass mailing or “spamming”;
                            </li>
                            <li>
                                provides instructional information about illegal activities such as
                                making or buying illegal weapons, violating someone’s privacy, or
                                providing or creating computer viruses;
                            </li>
                            <li>
                                provides material that exploits people under the age of 18 in a
                                sexual or violent manner, or solicits personal information from
                                anyone under 18;
                            </li>
                            <li>
                                solicits passwords or personal identifying information for
                                commercial or unlawful purposes from other users;
                            </li>
                            <li>
                                engages in commercial activities and/or sales without Tubit prior
                                written consent such as contests, sweepstakes, barter, advertising,
                                and pyramid schemes.
                            </li>
                            <li>
                                promotes or facilitates human trafficking, sex trafficking, or abusе.
                            </li>
                        </ul>
                        Tubit reserves the right to investigate and take corresponding legal action
                        at its sole discretion against anyone who violates this provision including
                        without limitation removing the offending communication from the Service and
                        terminating use of such violators.
                    </li>
                    <li>
                        You may not engage in advertising to, or solicitation of, other Users to buy
                        or sell any products or services through the Service. You may not transmit
                        any chain letters or junk email to other Users.
                    </li>
                    <li>
                        To ensure the quality of the Service provided, Your communication through
                        the Website/ Application may be recorded.
                    </li>
                    <li>
                        You may not collect data from Tubit through automated means (bots, spiders,
                        scrapers or equivalent means), or by establishing fake user accounts or by
                        anonymous proxy servers or equivalent measures, or by circumventing any
                        technical measures to prevent forbidden activity on Tubit. You may not
                        collect data from Tubit authorized zones by any other means including manual
                        collection of data.
                    </li>
                    <li>
                        You agree not to use third person identity (name, photos, other personal
                        information). If we reveal profile with a third person’s identity, we are
                        entitled to delete such profile.
                    </li>
                    <li>
                        In the event, a User is found on an anti-scam site, or in case we receive
                        reliable proof of the User's illegal activities from another User, such
                        User's profile may be deleted from the Website/ Application provided that
                        Tubit, in its sole and unfettered discretion, considers the supplied
                        information substantial and irrefutable. Tubit may issue a partial refund to
                        the User who provided such information.
                    </li>
                    <li>
                        In case You violate clause 4 or clause 12 of the Terms you shall be liable
                        us a contractual penalty in the amount that can be determined by us
                        according to according to according to according to its equitable discretion
                        equitable discretion equitable discretion and the appropriateness
                        appropriateness be reviewed by the competent court in the event of doubt.
                    </li>
                </ol>
            </li>
            <li>
                <b>THIRD PARTIES</b>
                <p>
                    To provide the Service, Tubit may purchase services from various third parties
                    (collectively, “Third parties”).
                </p>
                <p>
                    The Third parties are independent contractors and are not agents, or employees
                    of Tubit. Tubit is not responsible for the willful or negligent acts and/or
                    omissions of any Third party or any of its respective employees, agents,
                    servants or representatives including, without limitation, their failure to
                    deliver Services, or partial or inadequate delivery of Services. According to
                    terms of agreement with its Third parties, Tubit is prohibited from rendering
                    the Third parties’ contact information. Services provided by the Third parties
                    include but are not limited to the following:
                </p>
                <ol>
                    <li>
                        Third parties provide full, accurate and legitimate information required for
                        the registration of the Users at Tubit prior to their registration according
                        to the terms of Agreement.
                    </li>
                    <li>
                        Third parties provide full, correct and quality translation of all
                        correspondence of their Users.
                    </li>
                    <li>
                        Third parties provide additional services in accordance to the terms of
                        their agreement with Tubit.
                    </li>
                    <li>
                        Any additional services requested by Tubit Users, which fall into the Third
                        parties scope of activity, but not defined by Tubit terms of Agreement with
                        a Third Party, may be processed by Third parties, but are not automatically
                        granted.
                    </li>
                </ol>
                Tubit is not in any way responsible for the quality of any additional services
                provided by the Third parties.
            </li>
            <li>
                <b>WARRANTY DISCLAIMER</b>
                <ol>
                    <li>
                        Tubit provides the service on an “as is” and “as available” basis with no
                        warranties of any kind. To the maximum extent prescribed by law, the Parties
                        hereby disclaim all warranties, terms, and conditions, express or implied,
                        either in fact or by operation of law, statutory or otherwise, including,
                        without limitation, warranties, terms or conditions of merchantability,
                        fitness for a particular purpose, satisfactory quality, correspondence with
                        description, title, non- infringement, and accuracy of information
                        generated.
                    </li>
                    <li>
                        Furthermore, Tubit does not warrant that your use of the service will be
                        secure, uninterrupted, always available, complete or free of errors, or that
                        the service will meet your requirements or that any defects in the service
                        will be corrected. Tubit disclaims liability for, and no warranty is made
                        with regards to, connectivity and availability. Tubit does not warrant that
                        the Software or Services will work on all devices. You are solely
                        responsible for obtaining and maintaining compatible devices necessary to
                        access and use Tubit.
                    </li>
                    <li>
                        Tubit can neither guarantee that each User is at least 18 years old, nor
                        does it accept responsibility or liability for any content, communication or
                        other use or access of the Service by persons under the age of 18 in
                        violation hereof , although each User must agree to these Terms. Moreover,
                        it is possible that other Users may post or transmit offensive or obscene
                        materials through Tubit and that You may be involuntarily exposed to such
                        offensive or obscene materials.
                    </li>
                    <li>
                        Tubit is not responsible for the use of any personal information that You
                        disclose on the Service. Please carefully select the type of information
                        that You post on the Service or release to others.
                    </li>
                    <li>
                        Users understand and agree that they transfer, display, store, view or
                        receive Content through the Software and Services at their own discretion
                        and risk and that they will be solely responsible for any damage that
                        results from the Software and Service or any content, including any loss of
                        data or damage to their property (including your device and computer
                        system).
                    </li>
                    <li>
                        Tubit is not responsible for the conduct, whether online or offline, of any
                        user of Tubit and for communication between Users whether online or offline.
                    </li>
                    <li>
                        Tubit is not liable for any loss or damage, including injury or death,
                        resulting from anyone’s use of Tubit, any Content posted on Tubit or
                        transmitted to Users.
                    </li>
                </ol>
            </li>
            <li>
                <b>TERM</b>
                <p>
                    These Terms will remain in full force and effect while You use Tubit and/or are
                    a User of Tubit.
                </p>
            </li>
            <li>
                <b>LIMITATION OF LIABILITY</b>
                <p>
                    In no event shall Tubit be liable to Users or any third party for any indirect,
                    consequential, exemplary, incidental, special or punitive damages, including
                    lost profits resulting from their use of Tubit, as well as damages for loss of
                    data, loss of programs, cost of obtaining substitute services, or service
                    interruptions, except in jurisdictions where such provisions are restricted. In
                    addition to using our services, Tubit is not liable for any costs associated
                    with connecting with Users on our website (including, but not limited to
                    expenses related to travel, deliveries, meetings etc.).
                </p>
            </li>
            <li>
                <b>EXPORT AND SANCTIONS</b>
                <p>
                    Software from Tubit is further subject to United States export controls. No
                    Software may be downloaded from Tubit or otherwise exported or re-exported
                </p>
                <p>
                    (i) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran,
                    Syria, Republic of Belarus, Russian Federation, territories occupied by the
                    Russian Federation, or any other Country to which the U.S. has embargoed goods;
                    or
                </p>
                <p>
                    (ii) to anyone who is listed on the U. S. Treasury Department's list of
                    Specially Designated Nationals or on the U. S. Commerce Department's Table of
                    Deny Orders. By downloading or using the Software, You represent and warrant
                    that You are not located in, under the control of, or a national or resident of
                    any such country or on any such list.
                </p>
            </li>
            <li>
                <b>DISPUTE RESOLUTION</b>
                <p>
                    <u>If You live in US or Canada:</u>
                </p>
                <p>
                    Read this section carefully because it requires the parties to submit their
                    disputes to binding arbitration and limits the manner in which you can seek
                    relief from Tubit.
                </p>
                <p>
                    You thus give up your right to go to court to assert or defend your rights under
                    the Terms of Use (except for matters that may be taken to small claims court).
                    Your rights shall be determined by an neutral arbitrator and not a judge or
                    jury. You are entitled to a fair hearing but the arbitration procedures are
                    simpler and more limited than rules applicable in court. Arbitrator decisions
                    are as enforceable as any court order and are subject to very limited review by
                    a court. This arbitration agreement further includes an agreement that all
                    claims will be brought only in an individual capacity (and not as a class action
                    or other representative proceedings).
                </p>
                <p>
                    Any dispute or claim relating in any way to your visit to or use of Service sold
                    or distributed by Tubit or through Tubit shall be resolved in the following
                    manner.
                </p>
                <ul>
                    <li>
                        Informal Process First. You agree to first contact Tubit Customer Support by
                        email and attempt to resolve the dispute with us informally for a period of
                        60 days.
                    </li>
                    <li>
                        Binding Arbitration. If the dispute has not been resolved in 60 days, we
                        each agree to resolve any claim, dispute, or controversy arising out of or
                        in connection with or relating to the Service and/or this Terms, or the
                        breach or alleged breach thereof, including disputes related to the
                        applicability, enforceability, interpretation or formation of this Terms to
                        arbitrate, by binding arbitration.
                    </li>
                    <li>
                        Arbitration Tribunal. The American Arbitratoin Association (“AAA”) will
                        administer the arbitration. In the event the AAA declines to administer the
                        arbitration, the arbitrator(s) will be appointed by the New York Supreme
                        Court located in New York County, New York pursuant to New York Civil
                        Practice Law and Rules (“CPLR”) § 7504. The parties irrevocable consent to
                        the jurisdiction of the courts of the State of New York for the purpose of
                        administering an arbitration under Article 75 of the CPLR. Nothing in this
                        Section shall prevent either party from seeking injunctive or other
                        equitable relief from the courts as necessary to prevent the actual or
                        threatened infringement, misappropriation, or violation of that party’s data
                        security, intellectual property rights, or other proprietary rights, or to
                        seek other relief in connection with the arbitration.
                    </li>
                    <li>
                        Arbitrator and Rules. If the arbitration involves claims totaling less than
                        US $250,000, the arbitration will be conducted before a neutral single
                        arbitrator, whose decision will be final and binding, and the arbitratal
                        proceedings will be governed by the United States Federal Arbitration Act
                        and the AAA Consumer Arbitration Rules and AAA Consumer Due Process
                        Protocol. If the arbitration involves claims totaling more than US $250,000,
                        the arbitration will be conducted before three neutral arbitrators, whose
                        decision will be final and binding, and the arbitral proceedings will be
                        governed by the United States Federal Arbitration Act and the AAA Commercial
                        Arbitration Rules and AAA Consumer Due Process Protocol. The AAA rules may
                        be found online at{' '}
                        <a target="_blank" rel="noreferrer" href="https://www.adr.org">
                            www.adr.org
                        </a>
                        .
                    </li>
                    <li>
                        Location of Arbitration. The arbitration will be conducted in New York City,
                        New York, USA, or such other place as chosen by the Arbitration Tribunal. If
                        you live in the United States, you have the right to ask the Arbitration
                        Tribunal to conduct the arbitration near your home if the arbitration
                        involves an in-person hearing.
                    </li>
                    <li>
                        Costs and Fees. Payment of all filing, administration and arbitrator fees
                        and costs will be governed by AAA rules, but if you are financially unable
                        to pay any of them, Tubit will pay them for You.
                    </li>
                    <li>
                        Starting an Arbitration. A party who intends to seek arbitration must file a
                        claim with the American Arbitration Association and pay the required fee.
                        The instructions for filing a claim may be found online at{' '}
                        <a target="_blank" rel="noreferrer" href="https://www.adr.org">
                            www.adr.org
                        </a>
                        .
                    </li>
                    <li>
                        Your Right to Sue in Small Claims Court. Provided you live in the United
                        States, you may sue for US $5,000 or less in a small claims court of
                        competent jurisdiction as an alternative to engaging in arbitration, but
                        this does not absolve You of Your commitment to engage in the informal
                        dispute resolution process, and engaging in the informal dispute resolution
                        process is a condition precedent of your right to sue or arbitrate against
                        us.
                    </li>
                    <li>
                        Class action and jury trial waiver. you and Tubit agree that any and all
                        claims must be brought in the parties’ individual capacity, and not as a
                        plaintiff or class member in any purported class action, collective action,
                        private attorney general action or other representative proceeding. this
                        waiver applies to class arbitration, and, unless we agree otherwise, the
                        arbitrator may not consolidate more than one person’s claims. you agree
                        that, by entering into this agreement, you and Tubit are each waiving the
                        right to a trial by jury or to participate in a class action, collective
                        action, private attorney general action or other representative proceeding.
                    </li>
                </ul>
                <p>
                    <u>If You live in the European Union or anywhere else:</u>
                </p>
                <p>
                    These Terms shall be governed and construed in accordance with the laws of
                    Republic of Cyprus. Our failure to enforce any right or provision of the Terms
                    of Use will not be considered a waiver of those rights. If any provision hereof
                    is held to be invalid or unenforceable by a court, the remaining provisions of
                    the Terms of Use will remain in effect.
                </p>
                <p>
                    You will attempt to resolve all disputes with respect to the Services offered by
                    us through communication with our Customer Support Team. If parties are unable
                    to resolve the dispute in 60 days, then such dispute or difference arising out
                    of or in connection with this Terms shall first be referred to and finally
                    resolved by Nicosia District Court.
                </p>
                <p>
                    Please also note should you chargeback any transactions billed to your account,
                    we may be required to disclose any and/or all private communications
                    sent/received by you through our Service in order to prove the validity of the
                    charges billed to your account and our privacy policy will therefore not
                    restrain any such disclosures.
                </p>
            </li>
            <li>
                <b>USERS’ DISPUTE RESOLUTION</b>
                <p>
                    Users are solely responsible for their interactions with other Tubit Users.
                    Tubit reserves the right, but has no obligation, to monitor disputes between
                    Users.
                </p>
            </li>
            <li>
                <b>INDEMNITY</b>
                <p>
                    Hereby you agree to indemnify Tubit, our affiliates, subsidiaries, partners,
                    officers, directors, employees, contractors and suppliers from and against any
                    and all claims, obligations, actions, losses, liability, demands, costs and any
                    other damages including but not limited to reasonable attorneys’ fees made by
                    such parties, connected with or arising out of:
                </p>
                <p>(a) your violation of any rights of any third party, or</p>
                <p>
                    (b) any other party’s access and use of Tubit, its Software with your phone
                    number or username, or
                </p>
                <p>
                    (c) your use or misuse of Tubit, its Software your violation or breach of any
                    term hereof or of any applicable law, rule or regulation, whether or not
                    referenced herein, or
                </p>
                <p>
                    (d) your Content or other communication displayed or transmitted by means of
                    Tubit, its Software, or
                </p>
                <p>
                    (e) your violation or breach of any term hereof or of any applicable law, rule
                    or regulation, whether or not referenced herein.
                </p>
            </li>
            <li>
                <b>SEVERABILITY</b>
                <p>
                    Shall any of the provisions of the Terms of Use be deemed invalid, void, or for
                    any reason unenforceable, that provision shall be deemed severable and shall not
                    affect the validity or enforceability of any remaining provisions of the Terms
                    of Use.
                </p>
            </li>
        </ol>
    </div>
));
