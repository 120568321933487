import { BehaviorSubject } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';

export class StreamingUserRoles {
    static readonly shared = singleton(() => new StreamingUserRoles());

    private readonly rolesBehaviourSubject = new BehaviorSubject<{
        isFilled: boolean;
        list: string[];
    }>({
        isFilled: false,
        list: [],
    });

    readonly roles = this.rolesBehaviourSubject.asObservable();

    updateRoles(roles: string[]): void {
        this.rolesBehaviourSubject.next({
            isFilled: true,
            list: roles,
        });
    }
}
