import { Observable } from 'rxjs';
import AltStore from '@sdv/alt/src/store/AltStore';

AltStore.prototype.rxState = function () {
    return new Observable(observer => {
        if (typeof this.isFilled !== 'function' || this.isFilled()) {
            observer.next(this.getState())
        }
        const dispose = this.listen(state => {
            let nextState = state

            if (Array.isArray(nextState)) {
                nextState = [...nextState]
            } else if (typeof nextState === 'object') {
                nextState = { ...nextState }
            }

            //When you use setState method working with AltStore, the store doesn't change the reference to its state
            //by default. So, when you apply rx operators which store pipeline values (e.g. distinctUntilChanged,
            //pairwise) to a reactive state of an AltStore, you will have the same objects in mapping functions.
            observer.next(nextState)
        });

        return dispose;
    });
};
