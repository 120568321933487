import axios from 'axios';
import { format } from 'url';

import { context, protocol } from '../api-context';
import { ApiGetIdentityParams } from './types';

export const identity = {
    identity: (authorization: string) => ({
        get: (query?: ApiGetIdentityParams) =>
            axios.get(
                format({
                    protocol,
                    host: context.host,
                    pathname: 'identity',
                    ...(query && { query }),
                }),
                {
                    headers: {
                        ...context.headers,
                        authorization,
                    },
                },
            ),
        put: (body?: any) =>
            axios.put(`${context.server}/identity`, body, {
                headers: {
                    ...context.patchHeaders,
                    authorization,
                },
            }),
        patch: (body?: any) =>
            axios.patch(`${context.server}/identity`, body, {
                headers: {
                    ...context.patchHeaders,
                    authorization,
                },
            }),
    }),
};
