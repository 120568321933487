import { api, axiosErrorAdapter } from '@sdv/commons/api';

function createBalanceActions(id) {
    class BalanceActions {
        get = () => dispatch => {
            api.credits
                .accounts(id)
                .balance.get()
                .then(response => dispatch(response.data))
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        update = amount => dispatch => {
            dispatch(amount);
        };
    }

    BalanceActions.displayName = createBalanceActions.getDisplayName(id);

    return BalanceActions;
}

createBalanceActions.getDisplayName = id => {
    return `credits-accounts-balance.${id}`;
};

export default createBalanceActions;
