import classNames from 'classnames';

import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type Props = {
    className?: string;
    onlyIcon?: boolean;
};

export const Logo = (props: Props) => {
    const { className, onlyIcon } = props;

    return (
        <div className={classNames(classes.logo, className)}>
            <img className="logo-icon" src={Resources.images.logoIcon} alt="logo-icon" />
            {!onlyIcon && (
                <img className="logo-text" src={Resources.images.logoText} alt="logo-text" />
            )}
        </div>
    );
};
