import merge from 'merge/merge';
import Actions from './actions';

function createMediaStore(id) {
    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.state = {
                media: undefined,
            };

            this.bindAction(actions.addPhotoTag, this.addTag);
            this.bindAction(actions.removePhotoTag, this.removeTag);
            this.bindAction(actions.get, this.get);
            this.bindAction(actions.putPhotos, this.updatePhotos);
            this.bindAction(actions.putVideos, this.updateVideos);
            this.bindAction(actions.actualize, this.actualize);
        }

        actualize(payload) {
            if (!payload) {
                return;
            }

            const files = (this.state.media || []).slice();

            const fileIndex = files.findIndex(file => file.basename === payload.basename);

            if (fileIndex >= 0) {
                files[fileIndex] = merge.recursive(true, files[fileIndex], payload);
            } else {
                files.unshift(merge.recursive(true, {}, payload));
            }

            this.setState({
                media: files,
            });
        }

        addTag(payload) {
            if (!payload) {
                return;
            }

            const files = (this.state.media || []).slice();

            const fileIndex = files.findIndex(file => file.basename === payload.basename);

            if (fileIndex >= 0) {
                const { ...file } = files[fileIndex];

                file.tags = (file.tags || [])
                    .slice()
                    .filter(tag => tag !== payload.tag)
                    .concat([payload.tag]);

                files[fileIndex] = file;
            }

            this.setState({
                media: files,
            });
        }

        removeTag(payload) {
            if (!payload) {
                return;
            }

            const files = (this.state.media || []).slice();

            const fileIndex = files.findIndex(file => file.basename === payload.basename);

            if (fileIndex >= 0) {
                const { ...file } = files[fileIndex];

                file.tags = (file.tags || []).slice().filter(tag => tag !== payload.tag);

                files[fileIndex] = file;
            }

            this.setState({
                media: files,
            });
        }

        updatePhotos(basenames) {
            if (!basenames) {
                return;
            }

            const files = (this.state.media || [])
                .slice()
                .filter(
                    file =>
                        !file.mediatype?.startsWith('image') ||
                        basenames.indexOf(file.basename) >= 0,
                );

            this.setState({
                media: files,
            });
        }

        updateVideos(basenames) {
            if (!basenames) {
                return;
            }

            const files = (this.state.media || [])
                .slice()
                .filter(
                    file =>
                        !file.mediatype?.startsWith('video') ||
                        basenames.indexOf(file.basename) >= 0,
                );

            this.setState({
                media: files,
            });
        }

        get(files) {
            if (files) {
                this.setState({
                    media: files,
                });
            }
        }
    }

    Store.displayName = createMediaStore.getDisplayName(id);

    return Store;
}

createMediaStore.getDisplayName = function (id) {
    return `user-media.${id}`;
};

export default createMediaStore;
