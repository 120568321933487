import { ComponentProps, memo, useMemo } from 'react';

import { UserProfile } from '@sdv/domain/user/model';

import { Avatar } from 'web/src/components/commons/avatar';
import { getPhotoUrl } from 'web/src/utils/user';

type Props = Omit<ComponentProps<typeof Avatar>, 'name' | 'uri'> & {
    user?: Partial<Pick<UserProfile, 'id' | 'name' | 'thumbnail' | 'thumbnail-pending'>>;
    isPrivate?: boolean;
    isPreview?: boolean;
    /**
     * Default: `initials`
     */
    placeholder?: 'initials' | 'icon';
};

export const UserAvatar = memo((props: Props) => {
    const { user, isPrivate, isPreview, size, placeholder, ...avatarProps } = props;

    const uri = useMemo(() => {
        if (user) {
            const { id, thumbnail, 'thumbnail-pending': thumbnailPending } = user;
            const basename = thumbnail || thumbnailPending;
            const width = typeof size === 'object' ? size.width : size;
            const height = typeof size === 'object' ? size.height : size;

            if (id && basename) {
                return getPhotoUrl(id, basename, {
                    isPrivate,
                    isPreview,
                    width,
                    height,
                });
            }
        }

        return undefined;
    }, [user, isPreview, isPrivate, size]);

    return (
        <Avatar
            {...avatarProps}
            uri={uri}
            name={placeholder === 'initials' ? user?.name : undefined}
        />
    );
});
