import { Observable, defer, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { Flux } from '@sdv/domain/flux';
import { UserModel, UserProfile } from '@sdv/domain/user/model';
import { IdentityModel } from '@sdv/domain/identity/model';
import { singleton } from '@sdv/commons/utils/singleton';
import {
    PushNotificationTokenActualizer,
} from '@sdv/domain/notifications/push/token/actualizer';
import { RemoveDeviceReason } from '@sdv/domain/notifications/push/token/removing/reason';
import { Identity } from '@sdv/domain/identity';

export class UserRepository {
    static shared = singleton(userId => new UserRepository(userId));

    private readonly identityRxState: Observable<any>;

    private readonly userId: string;

    readonly user: Observable<Partial<UserProfile>>;

    constructor(userId: string) {
        const identityModel = Flux.get(IdentityModel);

        this.identityRxState = identityModel.store.rxState();

        this.userId = userId;
        this.user = defer(() => {
            const model = Flux.get(UserModel, this.userId);

            if (!model.store.isFilled()) {
                model.actions.get();
            }

            return model.store.rxState();
        });
    }

    private isStreamerRole = (roles: string[], tags: string[]): boolean => {
        return roles.includes('promoter') || tags.includes('dialogs.messages.promoter');
    };

    private getUserData = (userID: string, state: Observable<any>) => {
        return combineLatest([
            UserRepository.shared(userID).user.pipe(
                map(({ id = '', tags = [] }) => ({ id, tags })),
            ),
            state.pipe(map(({ roles = [] }) => roles)),
        ]);
    };

    isStreamer(): Observable<boolean> {
        return this.getUserData(this.userId, this.identityRxState).pipe(
            map(([{ tags }, roles]) => this.isStreamerRole(roles, tags)),
            take(1),
        );
    }

    delete(): Observable<void> {
        return of(this.userId).pipe(
            switchMap(userId => {
                return PushNotificationTokenActualizer.shared(userId)
                    .clear(RemoveDeviceReason.Logout)
                    .pipe(
                        catchError(() => of(undefined)),
                        switchMap(() => Identity.shared().delete()),
                    );
            }),
            take(1),
        );
    }
}
