import { memo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import classNames from 'classnames';

import { ModalProps, CLOSE_ANIMATION_TIMEOUT } from 'web/src/services/modals';
import { Button } from 'web/src/components/commons/button';
import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

type Props = Omit<ModalProps, 'key'> & {
    opened: boolean;
};

const transitionClassNames: CSSTransitionClassNames = {
    appearActive: classes.overlayEnter,
    appearDone: classes.overlayEnter,
    enterActive: classes.overlayEnter,
    enterDone: classes.overlayEnter,
    exitActive: classes.overlayExit,
    exitDone: classes.overlayExit,
};

export const Modal = memo((props: Props) => {
    const { title, content, onClose, canClose, opened, className } = props;

    return (
        <CSSTransition
            appear
            timeout={CLOSE_ANIMATION_TIMEOUT}
            in={opened}
            classNames={transitionClassNames}
        >
            <div className={classes.overlay}>
                <div className={classNames(className, classes.container)}>
                    {canClose && (
                        <Button className={classes.closeBtn} onClick={onClose}>
                            <img src={Resources.images.closeIcon} alt="close icon" />
                        </Button>
                    )}
                    <h2 className={classes.title}>{title}</h2>
                    <div className={classes.content}>{content}</div>
                </div>
            </div>
        </CSSTransition>
    );
});
