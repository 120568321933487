import axios from 'axios';

import { context } from '../api-context';

export const annals = {
    annals: (event?: string) => ({
        add: (body?: any) =>
            axios
                .post(`${context.server}/annals/${context.userId}/${event}`, body, {
                    headers: context.patchHeaders,
                })
                .catch(() => {}),

        appsflyer: {
            add: (body?: any) =>
                axios
                    .post(`${context.server}/annals/appsflyer`, body, {
                        headers: context.patchHeaders,
                    })
                    .catch(() => {}),
        },
    }),
};
