import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import equal from 'fast-deep-equal';
import { authenticate } from '@sdv/domain/streaming/authentication';
import { singleton } from '@sdv/commons/utils/singleton';
import { Service } from '@sdv/commons/service';
import { StreamingInitializer } from '@sdv/domain/streaming/initializer';
import { deviceFingerprint } from '@sdv/domain/device/fingerprint';
import { StreamingCredentials } from '@sdv/domain/streaming/credentials';
import { api } from '@sdv/commons/api';

export class StreamingAuthenticator implements Service {
    static shared = singleton((userId: string) => new StreamingAuthenticator(userId));

    static authenticatingOnSide = false;

    static authenticate(token: string, cb?: (err: unknown) => void) {
        authenticate(token, deviceFingerprint(), cb);
    }

    private readonly userId: string;

    private constructor(userId: string) {
        this.userId = userId;
    }

    start(unsubscription: Subscription) {
        // clear previous token to avoid of accidental using old token
        StreamingCredentials.shared().clearToken();

        api.streaming.identity
            .get(this.userId)
            .then(response => {
                StreamingCredentials.shared().setToken(response.data.jwt);
                unsubscription.add(
                    combineLatest([
                        StreamingInitializer.shared().initialized,
                        StreamingCredentials.shared().token,
                    ])
                        .pipe(
                            filter(([moduleInitialized, token]) => !!moduleInitialized && !!token),
                            distinctUntilChanged(equal),
                        )
                        .subscribe(([_, token]) => {
                            if (!StreamingAuthenticator.authenticatingOnSide) {
                                StreamingAuthenticator.authenticate(token!);
                            }
                        }),
                );
            })
            .catch(() => {
                // TODO: add proper error handling here
            });
    }
}
