import { useObservable } from '@sdv/presentation/rx/hooks';

import { AlertsService } from 'web/src/services/alerts';
import { Alert } from 'web/src/components/commons/alert';

import classes from './styles.module.scss';

export const Alerts = () => {
    const alerts = useObservable(() => AlertsService.shared().stack, [], []);

    const onAlertClose = (key: number) => () => AlertsService.shared().close(key);

    return (
        <div className={classes.container}>
            {alerts.map(({ key, ...modalProps }) => (
                <Alert key={key} onClose={onAlertClose(key)} {...modalProps} />
            ))}
        </div>
    );
};
