import { api } from '@sdv/commons/api';
import { GetEventsParams } from '@sdv/commons/api/types';

const POLLING_INTERVAL = 2000;

type Listener = (eventName: string, payload: any) => void;

class EventsPoller {
    private token: string | null = null;

    private fetching = false;

    private shouldStop = true;

    private listener: Listener | null = null;

    start = () => {
        this.shouldStop = false;
        this.pollEvent();
    };

    stop = () => {
        this.shouldStop = true;
    };

    isStarted = () => !this.shouldStop;

    pollEvent = () => {
        if (this.fetching) {
            return;
        }
        this.fetching = true;

        const params: GetEventsParams = {};
        if (this.token) {
            params.sync = this.token;
        }

        api.events
            .get(params)
            .then(response => {
                const { data } = response;

                if (data && data.length) {
                    let lastToken: string | null = null;

                    data.forEach(event => {
                        const eventName = event.label || event.type;

                        if (eventName) {
                            const payload = event.payload || event.details;

                            this.listener?.(eventName, payload);
                        }
                        if (event.sync) {
                            lastToken = event.sync;
                        }
                    });
                    if (lastToken) {
                        this.token = lastToken;
                    }
                }
            })
            .catch(() => {})
            .finally(() => {
                this.fetching = false;

                if (!this.shouldStop) {
                    setTimeout(() => {
                        this.pollEvent();
                    }, POLLING_INTERVAL);
                }
            });
    };

    setListener(listener: Listener) {
        this.listener = listener;
    }
}

export default EventsPoller;
