import { useRef } from 'react';

import { ScreenRoutes } from 'web/src/constants/routing';
import { useLocation } from 'web/src/hooks/navigation';
import { history, Action } from 'web/src/services/history';
import { matches } from 'web/src/utils/navigation';

const SECOND_LEVEL_SCREENS: ScreenRoutes[] = [
    ScreenRoutes.Live,
    ScreenRoutes.Stream,
    ScreenRoutes.Leaderboard,
];

/**
 * This is an emulation of a screens stack (hierarchy of the app screens)
 * There's static hierarchy. These are screens that are inside the `SECOND_LEVEL_SCREENS` list.
 * For example:
 * ScreenRoutes.Dashboard -> ['/']
 * ScreenRoutes.Live -> ['/', '/live']
 * ScreenRoutes.Stream -> ['/', '/streams/:id']
 * And dynamic hierarchy (All the other screens)
 * They can be shown dynamically above the static screens. For example:
 * ScreenRoutes.Terms -> ['/', '/streams/:id', '/terms']
 * ScreenRoutes.Terms -> ['/', '/live', '/terms']
 * ScreenRoutes.BuyCoins -> ['/', '/buy-coins']
 * ScreenRoutes.BuyCoins -> ['/', 'streams/:id', '/buy-coins']
 */
export const useScreensStack = () => {
    const { pathname } = useLocation();

    const screensStack = useRef<string[]>([ScreenRoutes.Dashboard]);

    if (pathname !== screensStack.current[screensStack.current.length - 1]) {
        if (pathname === ScreenRoutes.Dashboard) {
            // the Dashboard is always the root screen in stack
            screensStack.current = [pathname];
        } else if (SECOND_LEVEL_SCREENS.some(screen => matches(pathname, screen))) {
            // these are the 2nd level screens, they're always next to dashboard
            screensStack.current = [ScreenRoutes.Dashboard, pathname];
        } else {
            // all other screens can be dynamically placed above the 2nd level screens
            switch (history.action) {
                case Action.Pop:
                    screensStack.current.pop();
                    break;
                case Action.Replace:
                    screensStack.current[screensStack.current.length - 1] = pathname;
                    break;
                case Action.Push:
                default:
                    screensStack.current.push(pathname);
                    break;
            }
        }
    }

    return screensStack.current;
};
