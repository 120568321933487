import { memo, useCallback } from 'react';

import { Resources } from 'web/src/resources';
import { ModalRoute } from 'web/src/components/modal-route';
import { useNavigate } from 'web/src/hooks/navigation';

import View from './view';

export const GetAppScreen = memo(() => {
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <ModalRoute
            modalKey="get-app-modal"
            onClose={handleClose}
            title={Resources.strings['get-tubit-app']}
            content={<View />}
        />
    );
});
