// @ts-ignore
import { updateUserName } from '@sdv-streaming/web';

import Alt from '@sdv/alt';
import { singleton } from '@sdv/commons/utils/singleton';
import Flux from '@sdv/domain/flux';

import { UserModel, UserProfile } from '@sdv/domain/user/model';
import {
    ObjectStorageModel,
    State as ObjectStorageModelState,
} from '@sdv/domain/object.storage/model';
import { UserMediaModel } from '@sdv/domain/user/media/model';

import { AlertsService } from 'web/src/services/alerts';
import { Resources } from 'web/src/resources';

export class UserService {
    static readonly shared = singleton((userId: string) => new UserService(userId));

    private readonly model: Alt.ModelType<typeof UserModel>;

    private userId: string;

    private constructor(userId: string) {
        this.model = Flux.get(UserModel, userId);
        this.userId = userId;
    }

    /**
     * Fill the store with the user data
     */
    loadUser = () =>
        new Promise<Partial<UserProfile>>((resolve, reject) => {
            if (this.model.store.isFilled()) {
                return resolve(this.model.store.getState());
            }
            this.model.actions.get(err => {
                if (err) {
                    reject(err);
                }
                resolve(this.model.store.getState());
            });
        });

    updateUser = (data: Partial<UserProfile>) =>
        new Promise<boolean>(resolve => {
            this.model.actions.patch(data, error => {
                if (error) {
                    AlertsService.shared().show(
                        Resources.strings.network.errors['profile-update-failed'],
                    );
                    return resolve(false);
                }
                if (data.name) {
                    updateUserName(data.name);
                }
                resolve(true);
            });
        });

    addImage = (uri: string) =>
        new Promise<void>((resolve, reject) => {
            const objectStorageModel = Flux.get(ObjectStorageModel, `user-media:${this.userId}`);

            const update = (state: ObjectStorageModelState) => {
                if (state.files.length) {
                    const basename = state.files[0];

                    Flux.get(UserMediaModel, this.userId).actions.addPhotoTag(
                        basename,
                        'thumbnail',
                    );
                    this.model.actions.actualize({
                        'thumbnail-pending': basename,
                    });

                    objectStorageModel.store.unlisten(update);

                    return resolve();
                }

                if (typeof state.progress !== 'number') {
                    objectStorageModel.store.unlisten(update);

                    reject(new Error('Uploading failed'));
                }
            };

            objectStorageModel.store.listen(update);

            objectStorageModel.actions.post(
                `/users/${this.userId}/photos`,
                {
                    uri,
                    name: `${this.userId}_thumbnail_.jpeg`,
                    type: 'image/jpeg',
                },
                error => {
                    if (error) {
                        objectStorageModel.store.unlisten(update);
                        reject(error);
                    }
                },
            );
        });
}
