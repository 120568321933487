import { BehaviorSubject, Observable } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';

export type AlertState = {
    key: number;
    message: string;
    opened: boolean;
};

type TimeoutState = {
    timer?: NodeJS.Timeout;
};

// a timeout after the alert will close itself
const CLOSE_TIMEOUT = 3000;
// a time for the close animation to run before the actual close will happen
export const CLOSE_ANIMATION_TIMEOUT = 300;

export class AlertsService {
    static shared = singleton(() => new AlertsService());

    private stackSubject = new BehaviorSubject<(AlertState & TimeoutState)[]>([]);

    readonly stack = this.stackSubject.asObservable() as Observable<AlertState[]>;

    private get stackValue() {
        return this.stackSubject.getValue();
    }

    show = (message: string, autoClose = true) => {
        const key = this.getKey();

        const alert: AlertState & TimeoutState = {
            message,
            key,
            opened: true,
        };

        if (autoClose) {
            alert.timer = setTimeout(() => {
                this.close(key);
            }, CLOSE_TIMEOUT);
        }

        this.stackSubject.next(this.stackValue.concat(alert));
    };

    close = (key: number) => {
        this.stackSubject.next(
            this.stackValue.map(alert => {
                if (alert.key === key) {
                    clearTimeout(alert.timer);
                    return { ...alert, opened: false };
                }
                return alert;
            }),
        );
        setTimeout(() => {
            this.stackSubject.next(this.stackValue.filter(alert => alert.key !== key));
        }, CLOSE_ANIMATION_TIMEOUT);
    };

    private count = 0;

    private getKey = () => {
        this.count += 1;
        return this.count;
    };
}
