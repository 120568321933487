import axios from 'axios';

import { context } from '../api-context';
import { ApiGetJWT } from '../types';

export const streaming = {
    streaming: {
        identity: {
            get: (userId: string) =>
                axios.get<ApiGetJWT>(`${context.server}/exo/streaming/identity/${userId}`, {
                    headers: context.authHeaders,
                }),
        },
    },
};
