import { useEffect, useState } from 'react';
import { Subscription, Observable, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { StreamingAuthenticator } from '@sdv/domain/streaming/authenticator';
import Session from '@sdv/domain/authorization/session';
import { StreamingInitializer } from '@sdv/domain/streaming/initializer';
import { PaymentCoinsBalanceUpdater } from '@sdv/domain/payment/coins/balance/updater';
import { StreamingBalanceUpdater } from '@sdv/domain/streaming/balance/updater';
import { CredentialsSaver } from '@sdv/domain/authorization/credentials/saver';
import { useObservable } from '@sdv/presentation/rx/hooks';
import { initApi } from '@sdv/domain/flux';

import { ConfigService } from 'web/src/services/config';
import { AuthService } from 'web/src/services/auth';

export enum SubTheme {
    messenger,
    streaming,
    videocalls,
}

export const useAppBootstrap = () => {
    const [authChecked, setAuthChecked] = useState(false);

    const streamingInitialized = useObservable(
        () => StreamingInitializer.shared().initialized,
        [],
        false,
    );
    const configInitialized = useObservable(() => ConfigService.shared().initialized, [], false);

    useEffect(() => {
        if (!configInitialized) {
            ConfigService.shared().init(initApi);
            return;
        }

        AuthService.shared()
            .checkAuth()
            .then(() => setAuthChecked(true));

        const globalSubscription = new Subscription();

        CredentialsSaver.shared().start(globalSubscription);
        StreamingInitializer.shared().start();

        const sessionUnsubscriber = Session.shared()
            .userId.pipe(
                switchMap(userId => {
                    if (!userId) {
                        return EMPTY;
                    }

                    return new Observable(() => {
                        const userSubscription = new Subscription();

                        StreamingAuthenticator.shared(userId).start(userSubscription);
                        StreamingBalanceUpdater.shared(userId).start(userSubscription);
                        PaymentCoinsBalanceUpdater.shared(userId).start(userSubscription);

                        return userSubscription;
                    });
                }),
            )
            .subscribe();

        return () => {
            globalSubscription.unsubscribe();
            sessionUnsubscriber.unsubscribe();
        };
    }, [configInitialized]);

    return streamingInitialized && configInitialized && authChecked;
};
