import { generateGuid } from '@sdv/commons/utils/guid';

function getDomain(host) {
    const domain = host.split('.')[1];
    return domain.replace(/^www\.|^beta\.|\.[A-z]+|:[0-9]+/gm, '');
}

export function isDemoEmail(email, host) {
    const regexp = new RegExp(`([0-9])-demo@${getDomain(host)}.(.*?)`, 'i');
    return regexp.test(email);
}

export function composeEmail(host) {
    return `${generateGuid()}-demo@${getDomain(host)}.com`;
}
