export function dateWithYearOffset(date: Date, offset: number) {
    return new Date(date.getFullYear() + offset, date.getMonth(), date.getDate());
}

export function dateWithDateOffset(date: Date, offset: number) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset);
}

export function resetUTCTime(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}
