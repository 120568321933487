// @ts-ignore
import { PaymentReason, PaymentSource } from '@sdv-streaming/web';
import { singleton } from '@sdv/commons/utils/singleton';

import { ScreenRoutes } from 'web/src/constants/routing';
import { history } from 'web/src/services/history';

export class PaymentsService {
    static readonly shared = singleton(() => new PaymentsService());

    private onPaymentCompleted?: (success: boolean) => void;

    private constructor() {
        //
    }

    startPayment = (
        amount: number,
        paymentReason: PaymentReason,
        paymentSource: PaymentSource,
        onPaymentCompleted: (success: boolean) => void,
    ) => {
        // TODO: add payment logic
        const { pathname, hash, search } = history.location;
        this.onPaymentCompleted = (success: boolean) => {
            history.replace({ pathname, hash, search });
            onPaymentCompleted(success);
        };
        history.push(ScreenRoutes.BuyCoins);
    };

    completePayment = () => {
        // hardcoded false until the payment logic is implemented
        this.onPaymentCompleted?.(false);
        this.onPaymentCompleted = undefined;
    };

    closePayment = () => {
        this.onPaymentCompleted?.(false);
        this.onPaymentCompleted = undefined;
    };
}
