/* eslint-disable max-classes-per-file */
import { Observable, of, defer } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import { Persistence } from '@sdv/domain/persistence.contracts';

const appDataKey = '__appdata';
const persistenceKey = 'persistence';

class PersistenceImplementation implements Persistence {
    static shared = singleton(
        (scope?: string, userId?: string) => new PersistenceImplementation(scope, userId),
    );

    private readonly additionToKey: string;

    constructor(scope?: string, userId?: string) {
        this.additionToKey = [userId, scope].filter(Boolean).join('.');
    }

    getFullKey = (key: string) => `${this.additionToKey}.${key}`;

    store<T>(key: string, value: T): Observable<void> {
        return defer(() => {
            const storedAppData = localStorage.getItem(appDataKey);
            const appData: { [key: string]: any } =
                (storedAppData && JSON.parse(storedAppData)) || {};
            const persistence: { [key: string]: any } = appData[persistenceKey] || {};

            persistence[this.getFullKey(key)] = value;
            appData[persistenceKey] = persistence;
            localStorage.setItem(appDataKey, JSON.stringify(appData));

            return of(undefined as void);
        });
    }

    load<T>(key: string) {
        const storedAppData = localStorage.getItem(appDataKey);
        const appData: { [key: string]: any } = (storedAppData && JSON.parse(storedAppData)) || {};
        const persistence: { [key: string]: any } = appData[persistenceKey] || {};
        const value = persistence[this.getFullKey(key)];

        return of(typeof value !== 'undefined' ? (value as T) : null);
    }

    clear(key: string) {
        const storedAppData = localStorage.getItem(appDataKey);
        const appData: { [key: string]: any } = (storedAppData && JSON.parse(storedAppData)) || {};
        const persistence: { [key: string]: any } = appData[persistenceKey] || {};
        const value = persistence[this.getFullKey(key)];

        if (value) {
            delete persistence[this.getFullKey(key)];
            appData[persistenceKey] = persistence;
            localStorage.setItem(appDataKey, JSON.stringify(appData));
        }

        return of(undefined as void);
    }
}

export { PersistenceImplementation as Persistence };

/**
 * @deprecated use Persistence instead
 */
export default class UserDefaultsPersistence {
    static shared = singleton(
        (scope?: string, userId?: string) => new UserDefaultsPersistence(scope, userId),
    );

    private readonly persistence: Persistence;

    constructor(scope?: string, userId?: string) {
        this.persistence = PersistenceImplementation.shared(scope, userId);
    }

    store = (key: string, value: string) => {
        return this.persistence.store(key, value).toPromise();
    };

    value = (key: string) => {
        return this.persistence.load(key).toPromise();
    };

    rxValue = (key: string) => this.persistence.load(key);

    rxStore = (key: string, value: string) => this.persistence.store(key, value);
}
