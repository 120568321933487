import { BehaviorSubject, Observable } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';

export class StreamingCredentials {
    static shared = singleton(() => new StreamingCredentials());

    private readonly tokenSubject = new BehaviorSubject<string | null>(null);

    readonly token: Observable<string | null>;

    private constructor() {
        this.token = this.tokenSubject.asObservable();
    }

    setToken(jwt: string) {
        this.tokenSubject.next(jwt);
    }

    clearToken() {
        this.tokenSubject.next(null);
    }
}
