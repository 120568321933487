export type CoinsPack = {
    id: number;
    coins: number;
    popular?: boolean;
    price: number;
    currency: string;
    discount?: number;
};

export const COINS_PACKS: CoinsPack[] = [
    {
        id: 1,
        coins: 300,
        price: 4,
        currency: 'USD',
    },
    {
        id: 2,
        coins: 1500,
        price: 14,
        currency: 'USD',
        popular: true,
        discount: 50,
    },
    {
        id: 3,
        coins: 15000,
        price: 40,
        currency: 'USD',
        discount: 50,
    },
];
