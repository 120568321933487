import { platform } from '@sdv/domain/system/platform';

export const protocol = 'https';

export type Config = {
    host: string;
    productName?: string;
    appVersion?: string;
    currentDevice?: string;
    httpEngine?: string;
    customUserAgent?: boolean;
};

export class ApiContext {
    private _userId: string = '';

    private _token: string = '';

    private _baseHost: string = '';

    private _host: string = '';

    private _server: string = '';

    private _userAgent: string = '';

    get userId() {
        return this._userId;
    }

    get token() {
        return this._token;
    }

    get protocol() {
        return protocol;
    }

    get baseHost() {
        return this._baseHost;
    }

    get host() {
        return this._host;
    }

    get server() {
        return this._server;
    }

    get userAgent() {
        return this._userAgent;
    }

    init(config: Config) {
        const { host, customUserAgent } = config;

        this._host = host.split('//').pop() || host;
        this._baseHost = `//${this._host}`;
        this._server = `${protocol}://${this._host}`;

        if (customUserAgent) {
            const { productName, appVersion, currentDevice, httpEngine } = config;
            this._userAgent = `Dating/1.0.0 ${productName}/${appVersion} (${currentDevice}) ${httpEngine}`;
        }
    }

    setBaseHost(baseHost: string) {
        this._baseHost = baseHost;
        this._host = this._baseHost.split('//').pop() || this._baseHost;
        this._server = `${protocol}://${this._host}`;
    }

    setHostAndServer(newHost: string) {
        this._host = newHost;
        this._baseHost = `//${newHost}`;
        this._server = `${protocol}://${newHost}`;
    }

    setCredentials(userId: string, token: string) {
        this._userId = userId;
        this._token = token;
    }

    clearCredentials() {
        this._userId = '';
        this._token = '';
    }

    get headers() {
        const headers = {
            'content-type': 'application/json',
            accept: 'application/json',
        };
        if (platform() === 'mobile') {
            return { ...headers, host: this._host, 'user-agent': this._userAgent };
        }
        return headers;
    }

    get authHeaders() {
        return {
            ...this.headers,
            authorization: `Token token="${this._token}"`,
        };
    }

    get patchHeaders() {
        return {
            ...this.headers,
            authorization: `Token token="${this._token}"`,
        };
    }
}

export const context = new ApiContext();
