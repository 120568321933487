export function userPhotoPath(userId: string, basename: string, isPrivate: boolean, isPreview: boolean) {
    if (!userId || !basename) {
        console.warn(`userPhotoPath can't be resolved (userId:${userId}, basename:${basename})`);

        return undefined;
    }

    const previewModifier = isPreview ? '/preview' : '';

    return `/users/${userId}/photos${isPrivate ? '/private' : ''}${previewModifier}/${basename}`;
}
