// @ts-ignore
import { loginUserWithJwt } from '@sdv-streaming/web';

import { Flux } from '@sdv/domain/flux';
import { IdentityModel } from '@sdv/domain/identity/model';

export function authenticate(
    token: string,
    fingerprint: string,
    callback?: (err: unknown) => void,
) {
    const { token: serverToken } = Flux.get(IdentityModel).store.getState();

    if (serverToken) {
        loginUserWithJwt(token, token, fingerprint, serverToken, callback);
    }
}
