import { memo, useCallback, useMemo } from 'react';

import { useNavigate } from 'web/src/hooks/navigation';
// @ts-ignore
import { StreamComponent } from '@sdv-streaming/web';

import { ScreenRoutes } from 'web/src/constants/routing';
import { useScreensStack } from 'web/src/hooks/screens-stack';

import classes from './styles.module.scss';

export const StreamScreen = memo(() => {
    const navigate = useNavigate();
    const [, pathname] = useScreensStack();

    const streamId = useMemo(() => pathname && pathname.split('/').slice(-1)[0], [pathname]);

    const handleActiveStreamIdChange = useCallback(
        (id: string) => {
            if (streamId !== id) {
                navigate({
                    pathname: ScreenRoutes.Stream,
                    params: { id },
                });
            }
        },
        [navigate, streamId],
    );

    const handleOnClosePress = useCallback(() => {
        navigate(ScreenRoutes.Dashboard);
    }, [navigate]);

    const handleOwnStreamStart = useCallback(() => {
        navigate(ScreenRoutes.Live);
    }, [navigate]);

    if (!streamId) return null;

    return (
        <div className={classes.container}>
            <StreamComponent
                onClose={handleOnClosePress}
                streamId={streamId}
                hideGiftCurrency={false}
                onOwnStreamStart={handleOwnStreamStart}
                onActiveStreamIdChange={handleActiveStreamIdChange}
            />
        </div>
    );
});
