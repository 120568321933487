import { api, axiosErrorAdapter } from '@sdv/commons/api';

function createMediaActions(id) {
    class Actions {
        actualize = media => dispatch => {
            setTimeout(() => {
                dispatch(media);
            }, 0);
        };

        addPhotoTag = (basename, tag) => dispatch => {
            api.user(id)
                .photos.tags(basename)
                .put(tag)
                .then(() =>
                    dispatch({
                        tag,
                        basename,
                    }),
                )
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        removePhotoTag = (basename, tag) => dispatch => {
            api.user(id)
                .photos.tags(basename)
                .delete(tag)
                .then(() =>
                    dispatch({
                        tag,
                        basename,
                    }),
                )
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        putPhotos = basenames => dispatch => {
            api.user(id)
                .photos.put(basenames)
                .then(() => dispatch(basenames))
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        putVideos = basenames => dispatch => {
            api.user(id)
                .videos.put(basenames)
                .then(() => dispatch(basenames))
                .catch(error => dispatch(null, axiosErrorAdapter(error)));
        };

        get = () => async dispatch => {
            const photoResource =
                api.userId === id ? api.user(id).photos.origin : api.user(id).photos;

            const videoResource =
                api.userId === id ? api.user(id).videos.origin : api.user(id).videos;

            try {
                const photos = await new Promise((resolve, reject) => {
                    photoResource
                        .get()
                        .then(response => {
                            const { data } = response;

                            return resolve(data.files);
                        })
                        .catch(error => reject(axiosErrorAdapter(error)));
                });

                const videos = await new Promise((resolve, reject) => {
                    videoResource
                        .get()
                        .then(response => {
                            const { data } = response;

                            return resolve(data.files);
                        })
                        .catch(error => reject(axiosErrorAdapter(error)));
                });

                dispatch(
                    photos.concat(videos).filter(file => !file.tags || file.tags.indexOf('vr') < 0),
                );
            } catch (error) {
                dispatch(null, error);
            }
        };
    }

    Actions.displayName = createMediaActions.getDisplayName(id);

    return Actions;
}

createMediaActions.getDisplayName = id => {
    return `user-media.${id}`;
};

export default createMediaActions;
