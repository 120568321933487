import { Observable, Subscription } from 'rxjs';
import { filter, shareReplay, switchMap, take } from 'rxjs/operators';

import { Config } from '@sdv/domain/app/config';
import { PaymentCoinsBalance } from '@sdv/domain/payment/coins/balance';
import { Service } from '@sdv/commons/service';
import { singleton } from '@sdv/commons/utils/singleton';
import { updateStreamingBalance } from './updating';

export class StreamingBalanceUpdater implements Service {
    static shared = singleton((userId: string) => new StreamingBalanceUpdater(userId));

    private streamingModuleEnabled: Observable<boolean>;

    private service: PaymentCoinsBalance;

    constructor(userId: string) {
        this.service = PaymentCoinsBalance.shared(userId);

        this.streamingModuleEnabled = Config.shared().streamingModuleEnabled.pipe(
            filter(enabled => !!enabled),
            take(1),
            shareReplay(1),
        );
    }

    start(unsubscription: Subscription) {
        const unsubscribe = this.streamingModuleEnabled
            .pipe(switchMap(() => this.service.balance))
            .subscribe(balance => {
                updateStreamingBalance(balance);
            });

        unsubscription.add(unsubscribe);
    }
}
