import { filter, map } from 'rxjs/operators';

import { StreamingUserRoles } from 'web/src/platform/streaming/user-roles';
import { useObservable } from '@sdv/presentation/rx/hooks';

export const useUserHasStreamingRole = (role: string) => {
    return useObservable(() => {
        return StreamingUserRoles.shared().roles.pipe(
            filter(roles => roles.isFilled),
            map(roles => roles.list.includes(role)),
        );
    }, []);
};
