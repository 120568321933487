import '@sdv/commons/extensions';

import { useAppBootstrap } from 'web/src/hooks/appBootstrap';
import { AppLoading } from 'web/src/components/app-loading';

import { AppRouter } from './router';

export function App() {
    const initialized = useAppBootstrap();

    if (!initialized) {
        return <AppLoading />;
    }

    return <AppRouter />;
}
