import { useCallback } from 'react';

import { Resources } from 'web/src/resources';
import { useNavigate } from 'web/src/hooks/navigation';
import { ModalRoute } from 'web/src/components/modal-route';

import View from './view';
import classes from './styles.module.scss';

export const CancelationPolicyScreen = () => {
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <ModalRoute
            modalKey="cancelation-policy-modal"
            title={Resources.strings['cancelation-policy']}
            onClose={handleClose}
            content={<View />}
            className={classes.modal}
        />
    );
};
