import { Observable, of } from 'rxjs';

import { singleton } from '@sdv/commons/utils/singleton';
import {
    NetworkConnection,
    NetworkEffectiveConnectionType,
    NetworkConnectionType,
} from '@sdv/domain/system/network-connection.contracts';

class NetworkConnectionImplementation implements NetworkConnection {
    static shared = singleton(() => new NetworkConnectionImplementation());

    readonly type: Observable<NetworkConnectionType | null>;

    readonly effectiveType: Observable<NetworkEffectiveConnectionType | null>;

    readonly isConnected: Observable<Boolean | null | undefined>;

    // TODO fill it!
    constructor() {
        this.type = of('wifi');

        this.effectiveType = of('unknown');

        this.isConnected = of(true);
    }
}

export { NetworkConnectionImplementation as NetworkConnection };
