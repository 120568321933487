import { memo, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextInput } from 'web/src/components/commons/textInput';
import { Button } from 'web/src/components/commons/button';
import { Link } from 'web/src/components/commons/link';
import { AuthService } from 'web/src/services/auth';
import { Resources } from 'web/src/resources';
import { SocialLoginResponse } from 'web/src/services/auth/social-providers/types';
import { ConfigService } from 'web/src/services/config';
import { useLocation } from 'web/src/hooks/navigation';
import { ScreenRoutes } from 'web/src/constants/routing';
import { QueryParams } from 'web/src/screens/signIn/types';
import { GoogleButton } from 'web/src/components/google-button';

import { validationSchema } from './validation-schema';
import { FormFields } from './types';
import { TermsText } from './terms-text';

import classes from './styles.module.scss';

export const SignInForm = memo(() => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<FormFields>({
        resolver: yupResolver(validationSchema),
    });
    const [loading, setLoading] = useState(false);
    const {
        query: { cameFrom, needCredsUpdating },
    } = useLocation<QueryParams>();

    const signUpTo = useMemo(
        () => ({
            pathname: ScreenRoutes.SignUp,
            query: {
                cameFrom,
                needCredsUpdating,
            },
        }),
        [cameFrom, needCredsUpdating],
    );

    /**
     * This is needed to refresh the app data (especially inside the streaming module)
     *
     * @see https://issues.sdventures.com/browse/STREAM-11664
     */
    const onAuthorizeResponse = useCallback((isSuccess: boolean) => {
        if (isSuccess) {
            window.location.reload();
        }
    }, []);

    const handleFormSubmit = useCallback(
        async ({ email, password }: FormFields) => {
            const isSuccess = await AuthService.shared().signIn(email, password);
            onAuthorizeResponse(isSuccess);
        },
        [onAuthorizeResponse],
    );

    const withLoader = async (loginFn: () => Promise<boolean>) => {
        setLoading(true);
        const success = await loginFn();
        if (!success) {
            setLoading(false);
        }
        return success;
    };

    const handleGoogleLoginSuccessResponse = useCallback(
        async (response: SocialLoginResponse) => {
            const isSuccess = await withLoader(() =>
                AuthService.shared().signInWithSocialResponse('google', response),
            );
            onAuthorizeResponse(isSuccess);
        },
        [onAuthorizeResponse],
    );

    const handleFacebookLogin = useCallback(async () => {
        const isSuccess = await withLoader(() => AuthService.shared().signInViaOAuth2('facebook'));
        onAuthorizeResponse(isSuccess);
    }, [onAuthorizeResponse]);

    const submitting = loading || isSubmitting;

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form} noValidate>
            <TextInput
                {...register('email')}
                type="email"
                placeholder={Resources.strings.email}
                error={errors.email?.message}
                disabled={submitting}
            />
            <TextInput
                {...register('password')}
                type="password"
                placeholder={Resources.strings.password}
                error={errors.password?.message}
                disabled={submitting}
            />
            <div className={classes.btnsContainer}>
                {ConfigService.shared().config.features['facebook-signup-enabled'] && (
                    <Button
                        className={classes.socialButton}
                        disabled={submitting}
                        onClick={handleFacebookLogin}
                    >
                        <img src={Resources.images.facebookIcon} alt="facebook icon" />
                    </Button>
                )}
                {ConfigService.shared().config.features['google-auth-enabled'] && (
                    <GoogleButton
                        className={classes.socialButton}
                        disabled={submitting}
                        onSuccess={handleGoogleLoginSuccessResponse}
                    />
                )}
            </div>
            <TermsText className={classes.terms} />
            <Button
                type="submit"
                loading={submitting}
                disabled={!isDirty}
                className={classes.signInBtn}
                appearance="primary"
            >
                {Resources.strings['sign-in']}
            </Button>
            <Link to={signUpTo} className={classes.signUpBtn}>
                {Resources.strings['create-account']}
            </Link>
        </form>
    );
});
