import { Datadog } from './datadog';
import { Level, AdditionalLogPayload, AdditionalLogErrorHandler } from './types';

export const logger = {
    log(
        level: Level = 'debug',
        payload: AdditionalLogPayload,
        errHandler?: AdditionalLogErrorHandler,
    ) {
        // any services for logging
        Datadog.log(level, payload, errHandler);
    },
};
