import { defer, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import Alt from '@sdv/alt';
import { singleton } from '@sdv/commons/utils/singleton';
import { Flux } from '@sdv/domain/flux';
import { PaymentCoinsBalanceModel, UpdatePayload } from '@sdv/domain/payment/coins/balance/model';

export class PaymentCoinsBalance {
    static shared = singleton((userId: string) => new PaymentCoinsBalance(userId));

    public readonly balance: Observable<number>;

    model: Alt.ModelType<typeof PaymentCoinsBalanceModel>;

    constructor(userId: string) {
        this.model = Flux.get(PaymentCoinsBalanceModel, userId);

        this.balance = defer(() => {
            this.model.actions.get();

            return this.model.store.rxState().pipe(map(it => it.amount || 0));
        }).pipe(shareReplay(1));
    }

    public refill = () => {
        this.model.actions.get();
    };

    public update = (data: UpdatePayload) => {
        this.model.actions.update(data);
    };
}
