import Alt from '@sdv/alt';

import { singleton } from '@sdv/commons/utils/singleton';
import { Flux } from '@sdv/domain/flux';
import { IdentityModel, State } from '@sdv/domain/identity/model';
import { CredentialsStorage } from '@sdv/domain/authorization/credentials/storage';
import { Logger } from '@sdv/domain/logger';

import { AlertsService } from 'web/src/services/alerts';
import { UserService } from 'web/src/services/user';
import { Resources } from 'web/src/resources';
import { InitialParameters } from 'web/src/utils/types';

import { GoogleLoginClient } from './social-providers/google';
import { FacebookLoginClient } from './social-providers/facebook';
import { SocialLoginClient, SocialLoginResponse, SocialProvider } from './social-providers/types';

export enum LoginErrorCodes {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
}

type IdentityModelType = Alt.ModelType<typeof IdentityModel>;

export class AuthService {
    static readonly shared = singleton(() => new AuthService());

    private readonly identityModel: IdentityModelType;

    private socialProviders: Record<SocialProvider, SocialLoginClient>;

    private constructor() {
        this.identityModel = Flux.get(IdentityModel);

        this.socialProviders = {
            google: GoogleLoginClient.shared(),
            facebook: new FacebookLoginClient(),
        };
    }

    public checkAuth = async () => {
        try {
            const token = await CredentialsStorage.shared().getToken();

            Logger.shared().log({
                service: 'auto-logout',
                message: `checkAuth token: ${token}`,
            });

            if (token) {
                try {
                    const identity = await this.asyncAction('auth', token);

                    await UserService.shared(identity.id).loadUser();

                    return;
                } catch (error: any) {
                    if (
                        error.status === LoginErrorCodes.UNAUTHORIZED ||
                        error.status === LoginErrorCodes.FORBIDDEN
                    ) {
                        await CredentialsStorage.shared().clearToken();
                    } else {
                        throw error;
                    }
                }
            }

            await this.asyncAction('signUpAsAnonymous');
        } catch (error: any) {
            Logger.shared().error({
                service: 'auto-logout',
                message: `checkAuth error: ${error}`,
                payload: error,
            });

            AlertsService.shared().show(Resources.strings.network.errors.common);
        }
    };

    public signUp = async (email: string, password: string) => {
        try {
            await this.asyncAction('signUp', email, password);

            AlertsService.shared().show(Resources.strings.network['check-email']);

            return true;
        } catch (error: any) {
            let message = Resources.strings.network.errors.common;

            if (error.status === LoginErrorCodes.BAD_REQUEST && error.src === 'email') {
                message = Resources.strings.network.errors['wrong-credentials'];
            } else if (!error.message && error.src) {
                message = `${error.src} ${error.desc}`;
            }
            AlertsService.shared().show(message);

            return false;
        }
    };

    signInViaOAuth2 = async (provider: SocialProvider) => {
        const client = this.socialProviders[provider];

        const result = await client.login();

        return this.signInWithSocialResponse(provider, result);
    };

    signInWithSocialResponse = async (provider: SocialProvider, response: SocialLoginResponse) => {
        try {
            const { data, error } = response;

            if (error) {
                throw error;
            }

            if (!data) {
                return false;
            }

            const {
                token,
                user: { picture, email, ...profile },
            } = data;

            const identity = await this.asyncAction('signUpViaOAuth2', provider, token, email);

            const currentUserInfo = await UserService.shared(identity.id).loadUser();

            const newInfo: Partial<typeof profile> = {};
            Object.keys(profile).forEach(k => {
                const key = k as keyof typeof profile;
                if (profile[key] && profile[key] !== currentUserInfo[key]) {
                    newInfo[key] = profile[key] as any;
                }
            });
            if (Object.keys(newInfo).length) {
                await UserService.shared(identity.id).updateUser(newInfo);
            }

            if (picture && (!currentUserInfo.thumbnail || !currentUserInfo['thumbnail-pending'])) {
                UserService.shared(identity.id)
                    .addImage(picture)
                    .catch(err => {
                        Logger.shared().error({
                            service: 'registration',
                            message: `signUpWith${provider} upload picture error: ${err?.message}`,
                            payload: err,
                        });
                    });
            }
            return true;
        } catch (error: any) {
            this.socialProviders[provider].logout();
            Logger.shared().error({
                service: 'registration',
                message: `signUpWith${provider} error: ${error?.message}`,
                payload: error,
            });
            AlertsService.shared().show(Resources.strings.network.errors.common);

            return false;
        }
    };

    public signIn = async (email: string, password: string) => {
        try {
            const identity = await this.asyncAction('loginUser', email, password);

            await UserService.shared(identity.id).loadUser();

            return true;
        } catch (error: any) {
            let message = Resources.strings.network.errors.common;

            if (error.status === LoginErrorCodes.UNAUTHORIZED) {
                message = Resources.strings.network.errors['incorrect-credentials'];
            } else if (error.status === LoginErrorCodes.FORBIDDEN) {
                // TODO: check for better text here
                message = Resources.strings.network.errors['access-forbidden'];
            }

            AlertsService.shared().show(message);

            return false;
        }
    };

    public signOut = async () => {
        try {
            const { authorizationMethod } = this.identityModel.store.getState();
            await this.asyncAction('signOut');

            const match = authorizationMethod?.match(/google|facebook/);
            if (match?.[0]) {
                const provider = match[0] as SocialProvider;
                this.socialProviders[provider].logout();
            }
            return true;
        } catch (error) {
            AlertsService.shared().show(Resources.strings.network.errors.common);
            return false;
        }
    };

    private asyncAction = <Name extends keyof IdentityModelType['actions']>(
        name: Name,
        ...args: InitialParameters<IdentityModelType['actions'][Name]>
    ) =>
        new Promise<State>((resolve, reject) => {
            this.identityModel.actions[name](
                // @ts-ignore
                ...args,
                error => {
                    if (error) {
                        return reject(error);
                    }

                    return resolve(this.identityModel.store.getState());
                },
            );
        });
}
