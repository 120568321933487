import { useLayoutEffect, useState } from 'react';
import { Route, Routes, Router } from 'react-router-dom';

import { ScreenRoutes } from 'web/src/constants/routing';
import { Dashboard } from 'web/src/components/dashboard';
import { SignInScreen } from 'web/src/screens/signIn';
import { SignUpScreen } from 'web/src/screens/signup';
import { CompleteProfileScreen } from 'web/src/screens/complete-profile';
import { NotFoundScreen } from 'web/src/screens/not-found';
import { TermsAndConditionsScreen } from 'web/src/screens/terms-and-conditions';
import { PrivacyPolicyScreen } from 'web/src/screens/privacy-policy';
import { BuyCoinsScreen } from 'web/src/screens/buy-coins';
import { history } from 'web/src/services/history';
import { PurchaseScreen } from 'web/src/screens/purchase';
import { CancelationPolicyScreen } from 'web/src/screens/cancelation-policy';
import { GetAppScreen } from 'web/src/screens/get-app';

export const AppRouter = () => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), []);

    return (
        <Router location={state.location} navigator={history} navigationType={state.action}>
            <Routes>
                <Route path={ScreenRoutes.Dashboard} element={<Dashboard />}>
                    <Route
                        path={ScreenRoutes.Live}
                        // the actual <BroadcastScreen /> component is inside <Dashboard />
                        element={null}
                    />
                    <Route
                        path={ScreenRoutes.Stream}
                        // the actual <StreamScreen /> component is inside <Dashboard />
                        element={null}
                    />
                    <Route
                        path={ScreenRoutes.Leaderboard}
                        // the actual <StreamScreen /> component is inside <Dashboard />
                        element={null}
                    />
                    <Route path={ScreenRoutes.SignIn} element={<SignInScreen />} />
                    <Route path={ScreenRoutes.SignUp} element={<SignUpScreen />} />
                    <Route
                        path={ScreenRoutes.CompleteProfile}
                        element={<CompleteProfileScreen />}
                    />
                    <Route path={ScreenRoutes.Terms} element={<TermsAndConditionsScreen />} />
                    <Route path={ScreenRoutes.Privacy} element={<PrivacyPolicyScreen />} />
                    <Route path={ScreenRoutes.BuyCoins} element={<BuyCoinsScreen />} />
                    <Route path={ScreenRoutes.Purchase} element={<PurchaseScreen />} />
                    <Route
                        path={ScreenRoutes.CancelationPolicy}
                        element={<CancelationPolicyScreen />}
                    />
                    <Route path={ScreenRoutes.GetApp} element={<GetAppScreen />} />
                </Route>
                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        </Router>
    );
};
