import { memo } from 'react';

import { ConfigService } from 'web/src/services/config';

import classes from './styles.module.scss';

export default memo(() => {
    const { email: supportEmail } = ConfigService.shared().config['customer-support'];
    return (
        <div className={classes.container}>
            <p>Last updated March, 2023</p>
            <h3>Requesting Refund</h3>
            <p>
                For enforcement of the credit or monetary refund please
                contact our support team directly via email{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>. Once your request is
                approved, you will receive a refund to your designated account, as set forth above.
                We will review and get back to you with an initial reply to your request within 72
                (seventy two) hours.
            </p>
            <h3>Declaration</h3>
            <p>
                Please also note should you chargeback any transactions billed to your account, we
                may be required to disclose any and/or all private communications sent/received by
                you through our Service in order to prove the validity of the charges billed to your
                account our Privacy Policy will therefore not restrain any such disclosures.
            </p>
            <h2>Refund</h2>
            <h3>Monetary Refund</h3>
            <p>
                In the following circumstances you are entitled to request a monetary refund:
                <ol className={classes.list}>
                    <li>
                        If it is proven that your card was used fraudulently on our website your
                        money will be refunded. Should you suspect credit card fraud, please contact
                        us immediately and we will investigate.
                    </li>
                    <li>
                        For erroneous purchases made by you, provided that these are not systematic
                        requests (one time only):
                        <ol className={classes.innerList}>
                            <li>
                                If the purchased coins are not spent, then you are entitled to a
                                full refund.
                            </li>
                            <li>
                                If the purchased coins are spent partially, then you are entitled to
                                a partial refund.
                            </li>
                        </ol>
                    </li>
                </ol>
            </p>
            <p>
                In such a case, you shall receive a refund, pro rata, of any paid but unused fees
                that we have received from you.
            </p>
            <p>
                The money refunded will NOT exceed the amount paid for a particular service or
                product.
            </p>
        </div>
    );
});
