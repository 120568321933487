import { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ScreenRoutes } from 'web/src/constants/routing';
import { Logo } from 'web/src/components/commons/logo';
import { Media } from 'web/src/components/commons/media';
import { Link } from 'web/src/components/commons/link';
import { Button } from 'web/src/components/commons/button';
import { Resources } from 'web/src/resources';
import { purifyRoutes } from 'web/src/utils/navigation';
import { Search } from 'web/src/components/search';
import { useUserHasStreamingRole } from 'web/src/hooks/useUserRoles';

import classes from './styles.module.scss';

type NavItem = {
    key: ScreenRoutes;
    title: string;
    icon: string;
};

type Props = {
    visible: boolean;
    onClose: () => void;
    onSignOut: () => void;
    isLoggedIn: boolean;
};

const DASHBOARD_ROUTES = purifyRoutes([ScreenRoutes.Stream]);

const navItems: NavItem[] = [
    {
        key: ScreenRoutes.Dashboard,
        title: Resources.strings.home,
        icon: Resources.images.homeIcon,
    },
    {
        key: ScreenRoutes.Leaderboard,
        title: Resources.strings.leaderboards,
        icon: Resources.images.crownIcon,
    },
    {
        key: ScreenRoutes.Live,
        title: Resources.strings['go-live'],
        icon: Resources.images.playIcon,
    },
    {
        key: ScreenRoutes.GetApp,
        title: Resources.strings['get-the-app'],
        icon: Resources.images.qrCodeIcon,
    },
];

const checkActive = (key: ScreenRoutes, pathname: string) =>
    key === ScreenRoutes.Dashboard
        ? pathname === ScreenRoutes.Dashboard ||
          DASHBOARD_ROUTES.some(route => pathname.includes(route))
        : pathname === key;

export const Sidebar = memo((props: Props) => {
    const { visible, onClose, onSignOut, isLoggedIn } = props;

    const location = useLocation();

    const handleLinkClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            // if clicked on a link close the sidebar
            if ((e.target as Element).tagName === 'A') {
                onClose();
            }
        },
        [onClose],
    );

    const isStreamer = useUserHasStreamingRole('streamer');
    const filteredNavItems = useMemo(() => {
        return navItems.filter(item => {
            if (!isStreamer) {
                return item.key !== ScreenRoutes.Live;
            }
            return item;
        });
    }, [isStreamer]);

    return (
        <>
            <div
                className={classNames(classes.sidebar, {
                    [classes.visible]: visible,
                })}
                onClick={handleLinkClick}
            >
                <Logo className={classes.sidebarLogo} />
                <ul className={classes.navList}>
                    {filteredNavItems.map(({ key, title, icon }) => (
                        <li
                            key={key}
                            className={classNames(classes.navItem, {
                                [classes.active]: checkActive(key, location.pathname),
                                [classes.disabled]: key === location.pathname,
                            })}
                        >
                            <Link to={key}>
                                <img src={icon} alt="nav-item-icon" />
                                {title}
                            </Link>
                        </li>
                    ))}
                    {isLoggedIn && (
                        <li>
                            <Button onClick={onSignOut} appearance="secondary">
                                {Resources.strings['sign-out']}
                            </Button>
                        </li>
                    )}
                </ul>
                <Media size="mobile">
                    <Search position="sidebar" className={classes.search} onStreamClick={onClose} />
                </Media>
                <div className={classes.footer}>
                    <div className={classes.links}>
                        <Link to={ScreenRoutes.Terms}>
                            {Resources.strings['terms-and-conditions']}
                        </Link>
                        ,{' '}
                        <Link to={ScreenRoutes.Privacy}>{Resources.strings['privacy-policy']}</Link>
                    </div>
                </div>
            </div>
            {visible && (
                <Media size="mobile">
                    <div className={classes.backdrop} onClick={onClose} />
                </Media>
            )}
        </>
    );
});
