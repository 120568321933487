import {
    ChangeEvent,
    CSSProperties,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';

import { useObservable } from '@sdv/presentation/rx/hooks';

import { Resources } from 'web/src/resources';
import { useBackHandler, useNavigate } from 'web/src/hooks/navigation';
import { useMatchMedia } from 'web/src/hooks/mediaQuery';
import { ScreenRoutes } from 'web/src/constants/routing';
import { SearchService } from 'web/src/services/search';

import dashboardClasses from 'web/src/components/dashboard/styles.module.scss';

import { SearchList } from './list';
import classes from './styles.module.scss';

type Props = {
    className?: string;
    position: 'header' | 'sidebar';
    onStreamClick?: () => void;
};

export const Search = memo((props: Props) => {
    const { className, position, onStreamClick } = props;

    const [value, setValue] = useState('');
    const service = useMemo(() => SearchService.shared(), []);
    const container = useRef<HTMLDivElement>(null);
    const overlay = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        service.setQuery(value);
    }, [value, service]);

    const opened = useObservable(() => service.opened, [], false);
    const resultsVisible = useObservable(() => service.resultsVisible, [], false);
    const loading = useObservable(() => service.loading, [], false);
    const searchResults = useObservable(() => service.searchResults, [], []);

    const handleClose = useCallback(() => {
        service.closeSearchBar();
        setValue('');
    }, [service]);

    useEffect(() => handleClose, [handleClose]);

    const handleBackBtnPress = useCallback(() => {
        if (opened) {
            handleClose();
        }
    }, [handleClose, opened]);

    useBackHandler(handleBackBtnPress);

    const handleClickOutside = useCallback(
        (e: MouseEvent) => {
            const dashboardElem = document.querySelector(`.${dashboardClasses.dashboard}`);
            if (
                e.target === overlay.current ||
                (dashboardElem?.contains(e.target as Node) &&
                    !container.current?.contains(e.target as Node))
            ) {
                handleClose();
            }
        },
        [handleClose],
    );

    useEffect(() => {
        if (opened) {
            window.addEventListener('click', handleClickOutside);
        }
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [opened, handleClickOutside]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    const handleStreamSelected = useCallback(
        (id: string) => {
            handleClose();
            onStreamClick?.();
            navigate({ pathname: ScreenRoutes.Stream, params: { id } });
        },
        [navigate, onStreamClick, handleClose],
    );

    const openedInHeader = position === 'header' && opened;
    const openedInSidebar = position === 'sidebar' && opened;

    const isTablet = useMatchMedia('tablet');

    const { containerStyle, listStyle } = useMemo(() => {
        if (container.current) {
            const { top, left, width, height } = container.current.getBoundingClientRect();
            if (openedInHeader) {
                const inputWidth = isTablet ? window.innerWidth - left - 32 : width;
                return {
                    containerStyle: {
                        top,
                        left,
                        width: inputWidth,
                        position: 'absolute',
                        transform: 'unset',
                    } as CSSProperties,
                };
            }
            if (openedInSidebar) {
                const listHeight = window.innerHeight - top - height - 32;
                return {
                    listStyle: { maxHeight: listHeight } as CSSProperties,
                };
            }
        }
        return {};
    }, [openedInHeader, openedInSidebar, isTablet]);

    return (
        <div
            className={classNames(classes.overlay, {
                [classes.fromHeader]: position === 'header',
                [classes.active]: openedInHeader,
            })}
            ref={overlay}
        >
            <div
                className={classNames(classes.container, className, {
                    focused: opened,
                })}
                ref={container}
                style={containerStyle}
            >
                <img className={classes.searchIcon} src={Resources.images.searchIcon} alt="" />
                <input
                    value={value}
                    onFocus={service.openSearchBar}
                    onChange={handleChange}
                    type="text"
                    placeholder={Resources.strings['search-streams']}
                />
                {opened && (resultsVisible || loading) && (
                    <SearchList
                        onStartPrivateChat={handleClose}
                        onStreamSelected={handleStreamSelected}
                        loading={loading}
                        list={searchResults}
                        position={position}
                        style={listStyle}
                    />
                )}
            </div>
        </div>
    );
});
