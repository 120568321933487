import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

import classes from './styles.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    error?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const { className, label, error, ...inputProps } = props;

    return (
        <label
            className={classNames(className, classes.container, {
                [classes.error]: !!error,
            })}
        >
            <input {...inputProps} ref={ref} type="radio" />
            <span className={classes.checkmark} />
            <span className={classes.label}>{label}</span>
        </label>
    );
});
