import sharding from '@sdv/commons/utils/sharding';
import { userPhotoPath } from '@sdv/domain/resources/remote';

import { ConfigService } from 'web/src/services/config';

type PhotoUrlProps = {
    isPrivate?: boolean;
    isPreview?: boolean;
    width?: number;
    height?: number;
};

const DEFAULT_THUMB_SIZE = 50;

export const getPhotoUrl = (id: string, basename: string, params: PhotoUrlProps = {}) => {
    const {
        isPrivate = false,
        isPreview = false,
        width = DEFAULT_THUMB_SIZE,
        height = DEFAULT_THUMB_SIZE,
    } = params;

    const path = userPhotoPath(id, basename, isPrivate, isPreview);
    const { host, sharding: shardingConfig } = ConfigService.shared().config;

    return sharding(
        `${host}${path}.${width}x${height}.thumb-fd`,
        shardingConfig.shards,
        shardingConfig.prefixes,
    );
};
