import equal from 'fast-deep-equal';
import { combineLatest, EMPTY, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    take,
} from 'rxjs/operators';

import { Flux } from '@sdv/domain/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import { Identity } from '@sdv/domain/identity';
import { IdentityModel } from '@sdv/domain/identity/model';
import { PushNotificationTokenActualizer } from '@sdv/domain/notifications/push/token/actualizer';
import { RemoveDeviceReason } from '@sdv/domain/notifications/push/token/removing/reason';

export default class Session {
    static shared = singleton(() => new Session());

    constructor() {
        const identity = Flux.get(IdentityModel)
            .store.rxState()
            .pipe(shareReplay(1));

        this.userId = identity.pipe(
            map(state => state.id),
            distinctUntilChanged(),
            shareReplay(1),
        );

        this.authorizationDetails = identity.pipe(
            map(state => ({
                demo: state.demo,
                method: state.authorizationMethod,
                action: state.authorizationAction,
            })),
            // We need to add custom 'distinctUntilChanged' because when user goes from demo to registered user,
            // 'authorizationMethod' doesn't change, it is still 'signup:password'. When this situation happens we
            // need fire 'authorizationMethod'
            distinctUntilChanged(equal),
            shareReplay(1),
        );

        this.userIsUsingDemoMode = this.authorizationDetails.pipe(
            map(state => state.demo),
            shareReplay(1),
        );

        this.userIsModerator = identity.pipe(
            map(state => (state.roles || []).includes('streams-operator')),
            distinctUntilChanged(),
        );
    }

    demoUser(user) {
        return combineLatest([this.authorizationDetails, this.userId]).pipe(
            filter(([, userId]) => user === userId),
            map(([details]) => !!details.demo),
        );
    }

    authorizationMethod(user) {
        return combineLatest([this.authorizationDetails, this.userId]).pipe(
            filter(([, userId]) => user === userId),
            switchMap(([details]) => (details.method !== undefined ? of(details.method) : EMPTY)),
        );
    }

    logout() {
        return this.userId.pipe(
            switchMap(userId => {
                if (!userId) {
                    return of(undefined);
                }

                return PushNotificationTokenActualizer.shared(userId)
                    .clear(RemoveDeviceReason.Logout)
                    .pipe(
                        catchError(() => of(undefined)),
                        switchMap(() => Identity.shared().signOut()),
                    );
            }),
            take(1),
        );
    }
}
