import merge from 'merge/merge';
import UserActions from './actions';

function createUserStore(userId) {
    class Store {
        constructor(flux) {
            const actions = flux.getActions(UserActions, userId);

            this.state = {};

            this.bindAction(actions.get, this.replace);
            this.bindAction(actions.actualize, this.actualize);
            this.bindAction(actions.patch, this.actualize);

            this.publicMethods = {
                isFilled() {
                    return Object.keys(this.state).length > 0;
                },
            };
        }

        replace(payload) {
            if (!payload) {
                return;
            }

            this.setState(payload);
        }

        actualize(payload) {
            if (!payload) {
                return;
            }

            this.setState(merge.recursive(true, this.state, payload));
        }
    }

    Store.displayName = createUserStore.getDisplayName(userId);

    return Store;
}

createUserStore.getDisplayName = id => {
    return `user.${id}`;
};

export default createUserStore;
