import Lottie from 'lottie-react';

import { Resources } from 'web/src/resources';

import classes from './styles.module.scss';

export const AppLoading = () => (
    <div className={classes.container}>
        <Lottie
            className={classes.loader}
            animationData={Resources.animations.appLoadingAnimation()}
            loop
        />
    </div>
);
