import { useObservable } from '@sdv/presentation/rx/hooks';

import { Modal } from 'web/src/components/commons/modal';
import { ModalsService } from 'web/src/services/modals';

export const Modals = () => {
    const modals = useObservable(() => ModalsService.shared().stack, [], []);

    return (
        <div>
            {modals.map(({ key, ...modalProps }) => (
                <Modal key={key} {...modalProps} />
            ))}
        </div>
    );
};
