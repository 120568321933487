import { useCallback } from 'react';

import { CredentialsUpdater } from '@sdv/domain/user/credentials/updater';

import { ScreenRoutes } from 'web/src/constants/routing';
import { Resources } from 'web/src/resources';
import { useNavigate, useLocation, useBackHandler } from 'web/src/hooks/navigation';
import { ModalRoute } from 'web/src/components/modal-route';

import { SignInForm } from './form';
import { QueryParams } from './types';

const KEEP_FOR: ScreenRoutes[] = [ScreenRoutes.Terms, ScreenRoutes.Privacy];

export const SignInScreen = () => {
    const navigate = useNavigate();
    const {
        query: { cameFrom, needCredsUpdating },
    } = useLocation<QueryParams>();

    const onClosed = useCallback(() => {
        CredentialsUpdater.shared().cancelled();
    }, []);

    useBackHandler(onClosed);

    const handleClose = useCallback(() => {
        onClosed();
        if (needCredsUpdating && cameFrom) {
            navigate(cameFrom, { replace: true });
        } else {
            navigate(ScreenRoutes.Dashboard);
        }
    }, [navigate, needCredsUpdating, onClosed, cameFrom]);

    return (
        <ModalRoute
            modalKey="sign-in-modal"
            title={Resources.strings['sign-in']}
            onClose={handleClose}
            content={<SignInForm />}
            keepFor={KEEP_FOR}
        />
    );
};
