import axios from 'axios';

import { context } from '../api-context';

export const reminders = {
    reminders: (userId?: string) => ({
        post: (body?: any) =>
            axios.post(`${context.server}/reminders/${userId}`, body, {
                headers: context.patchHeaders,
            }),

        password: {
            post: (body?: any) =>
                axios.post(`${context.server}/reminders/password`, body, {
                    headers: context.headers,
                }),
        },
    }),
};
