import classNames from 'classnames';

import { Resources } from 'web/src/resources';
import { ScreenRoutes } from 'web/src/constants/routing';
import { Link } from 'web/src/components/commons/link';

import classes from './styles.module.scss';

type Props = {
    className?: string;
};

export const TermsText = (props: Props) => {
    const { className } = props;

    return (
        <p className={classNames(className, classes.termsText)}>
            {Resources.strings.formatString(
                Resources.strings['terms-text'],
                <Link to={ScreenRoutes.Terms}>{Resources.strings['terms-and-conditions']}</Link>,
                <Link to={ScreenRoutes.Privacy}>{Resources.strings['privacy-policy']}</Link>,
            )}
        </p>
    );
};
