import { useCurrentUser } from 'web/src/hooks/user';
import { Resources } from 'web/src/resources';

import { ModalRoute } from 'web/src/components/modal-route';
import { ScreenRoutes } from 'web/src/constants/routing';
import { Navigate } from 'web/src/components/commons/navigate';

import { CompleteProfileForm } from './form';

const KEEP_FOR: ScreenRoutes[] = [ScreenRoutes.Terms, ScreenRoutes.Privacy];

export const CompleteProfileScreen = () => {
    const { user, loading } = useCurrentUser();

    if (loading) {
        return null;
    }

    if (!user) {
        return <Navigate to={ScreenRoutes.Dashboard} />;
    }

    return (
        <ModalRoute
            modalKey="complete-profile-modal"
            title={Resources.strings['complete-your-profile']}
            canClose={false}
            content={<CompleteProfileForm user={user} />}
            keepFor={KEEP_FOR}
        />
    );
};
