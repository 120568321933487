import EventEmitter from 'eventemitter3';
import { take } from 'rxjs/operators';

// @ts-ignore
import { ShouldAuthorizeUserReason } from '@sdv-streaming/web';

import { singleton } from '@sdv/commons/utils/singleton';
import Session from '@sdv/domain/authorization/session';
import { history } from 'web/src/services/history';
import { ScreenRoutes } from 'web/src/constants/routing';
import { QueryParams } from 'web/src/screens/signIn/types';
import { stringify } from 'web/src/utils/query-string';

const CANCELED_EVENT = 'event.credentials.update.cancelled';
const COMPLETED_EVENT = 'event.credentials.update.completed';

export class CredentialsUpdater {
    static shared = singleton(() => new CredentialsUpdater());

    private bus = new EventEmitter();

    private constructor() {
        //
    }

    cancelled = () => {
        this.bus.emit(CANCELED_EVENT);
    };

    completed = () => {
        this.bus.emit(COMPLETED_EVENT);
    };

    updateCredentials = (
        successCallback: () => void,
        cancelCallback?: () => void,
        initialCallback?: () => void,
        reason?: ShouldAuthorizeUserReason,
    ) => {
        const onUpdated = () => {
            successCallback();
            unmount();
        };

        const onCancelled = () => {
            cancelCallback?.();
            unmount();
        };

        const unmount = () => {
            this.bus.removeListener(COMPLETED_EVENT, onUpdated);
            this.bus.removeListener(CANCELED_EVENT, onCancelled);
        };

        this.bus.addListener(COMPLETED_EVENT, onUpdated);
        this.bus.addListener(CANCELED_EVENT, onCancelled);

        initialCallback?.();

        Session.shared()
            .userIsUsingDemoMode.pipe(take(1))
            .subscribe(isDemoUser => {
                history.push(
                    {
                        pathname: isDemoUser ? ScreenRoutes.SignIn : ScreenRoutes.CompleteProfile,
                        search: stringify({
                            cameFrom: history.location.pathname,
                            needCredsUpdating: true,
                        } as QueryParams),
                    },
                    { reason },
                );
            });
    };
}
