// @ts-expect-error
import XMLHttpRequest from 'xhr2';
import { Level, AdditionalLogPayload, AdditionalLogErrorHandler } from '../types';

const apiKey = '7b58a99c6712905b57726b9d4b987e4d';
const apiUrl = 'https://http-intake.logs.datadoghq.com/v1/input';

export class Datadog {
    static async log(
        logLevel: Level,
        data: AdditionalLogPayload,
        errHandler?: AdditionalLogErrorHandler,
    ) {
        const date = data.date || new Date().toISOString();
        const message = `${data.message} in ${date}`;
        const payload = {
            service: data.service,
            message: message,
            // ddsource: 'ddsource',
            // ddtags: 'ddtags',
            hostname: data.product,
            status: logLevel,

            payload: data.payload,
            user: data.userId,
            connectionType: data.connectionType,
            device: data.device,
            os: data.os,
            connectionEffectiveType: data.connectionEffectiveType,
        };

        try {
            const xhr = new XMLHttpRequest();

            xhr.onerror = () => {
                errHandler?.();
            };
            xhr.open('post', apiUrl, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('DD-API-KEY', apiKey);

            xhr.send(JSON.stringify(payload));
            return;
        } catch (error) {
            console.error(error);
        }
    }
}
