import * as fn from '../functions'

export function setAppState(instance, data, onStore) {
  const obj = instance.deserialize(data)
  fn.eachObject((key, value) => {
    const keys = key.split('.')

    let store

    if (keys.length > 1) {
      store = instance.stores[keys[0]][keys[1]]
    } else {
      store = instance.stores[key]
    }

    if (store) {
      const { config } = store.StoreModel
      const state = store.state
      if (config.onDeserialize) obj[key] = config.onDeserialize(value) || value
      if (fn.isMutableObject(state)) {
        fn.eachObject(k => delete state[k], [state])
        fn.assign(state, obj[key])
      } else {
        store.state = obj[key]
      }
      onStore(store, store.state)

    }
  }, [obj])
}

export function snapshot(instance, storeNames = []) {
  storeNames = storeNames.length ? storeNames : Object.keys(instance.stores)

  const obj = {}

  function getStoreSnapshot(store) {
    if (store.state === undefined) return

    const { config } = store.StoreModel
    store.lifecycle('snapshot')
    const customSnapshot = config.onSerialize &&
      config.onSerialize(store.state)
    obj[store.displayName] = customSnapshot ? customSnapshot : store.getState()
  }

  storeNames.forEach(name => {
    const storesIds = Object.keys(instance.stores[name])
    storesIds.forEach((id) => {
      const store = instance.stores[name][id]
      if (!store.displayName) {
        console.error(new Error('Snapshot: displayName does not exist'))
        return
      }
      getStoreSnapshot(store)
    })
  })
  return obj
}

export function saveInitialSnapshot(instance, key) {
  if (!instance.getStore(key) || instance.getStore(key).state === undefined) return

  const state = instance.deserialize(
    instance.serialize(instance.getStore(key).state)
  )
  instance._initSnapshot[key] = state
  instance._lastSnapshot[key] = state
}

export function filterSnapshots(instance, state, stores) {
  return stores.reduce((obj, store) => {
    const storeName = store.displayName || store
    if (!state[storeName]) {
      throw new ReferenceError(`${storeName} is not a valid store`)
    }
    obj[storeName] = state[storeName]
    return obj
  }, {})
}
