import classNames from 'classnames';
import { CSSProperties, memo, useRef } from 'react';

// @ts-ignore
import { UserPreview, UserPreviewProps, UserWithViewers } from '@sdv-streaming/web';

import { Resources } from 'web/src/resources';
import { ActivityIndicator } from 'web/src/components/commons/activity-indicator';
import { ROOT_ID } from 'web/src/constants/selectors';

import classes from './styles.module.scss';

type Props = {
    loading: boolean;
    list: UserWithViewers[];
    position: 'header' | 'sidebar';
    onStreamSelected: (id: string) => void;
    onStartPrivateChat: () => void;
    style?: CSSProperties;
};

/**
 * These empty items are needed to fill white space at the end of the list
 * to prevent from justifying items in the last row if it isn't complete
 */
const emptyItems = new Array(8).fill(null);

const previewClassNames: UserPreviewProps['classNames'] = {
    wrapper: classes.item,
    container: classes.container,
    image: classes.avatar,
    name: classes.name,
};

const popupClassNames: UserPreviewProps['popupClassNames'] = {
    overlay: classes.profileOverlay,
    popup: classes.popup,
};

export const SearchList = memo((props: Props) => {
    const { list, loading, style, position, onStreamSelected, onStartPrivateChat } = props;

    const root = useRef(document.getElementById(ROOT_ID));

    const positionClasses = {
        [classes.fromHeader]: position === 'header',
    };

    if (loading) {
        return (
            <div style={style} className={classNames(classes.loading, positionClasses)}>
                <ActivityIndicator className={classes.loader} />
            </div>
        );
    }

    if (!list.length) {
        return (
            <div style={style} className={classNames(classes.empty, positionClasses)}>
                <span>{Resources.strings['no-results']}</span>
            </div>
        );
    }

    return (
        <ul style={style} className={classNames(classes.list, positionClasses)}>
            {list.map(item => (
                <UserPreview
                    key={item.id}
                    user={item}
                    classNames={previewClassNames}
                    onStreamSelected={onStreamSelected}
                    onStartPrivateChat={onStartPrivateChat}
                    popupMountNode={root.current!}
                    popupClassNames={popupClassNames}
                />
            ))}
            {emptyItems.map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className={classes.emptyItem} key={i} />
            ))}
        </ul>
    );
});
