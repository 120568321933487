export enum ScreenRoutes {
    Dashboard = '/',
    Live = '/live',
    Leaderboard = '/leaderboard',
    GetApp = '/get-app',
    SignIn = '/signin',
    SignUp = '/signup',
    CompleteProfile = '/complete-profile',
    Stream = '/streams/:id',
    Terms = '/terms',
    Privacy = '/privacy',
    CancelationPolicy = '/cancelation-policy',
    BuyCoins = '/buy-coins',
    Purchase = '/purchase',
}
