import { memo, useCallback } from 'react';
// @ts-ignore
import { StreamsList as StreamsListModule } from '@sdv-streaming/web';

import { ScreenRoutes } from 'web/src/constants/routing';
import { useNavigate } from 'web/src/hooks/navigation';

import classes from './styles.module.scss';

export const StreamsList = memo(() => {
    const navigate = useNavigate();

    const onStreamSelected = useCallback(
        (id: string) => {
            navigate({
                pathname: ScreenRoutes.Stream,
                params: { id },
            });
        },
        [navigate],
    );

    const openLeaderboard = useCallback(() => navigate(ScreenRoutes.Leaderboard), [navigate]);

    return (
        <div className={classes.container}>
            <StreamsListModule
                key="streams-list"
                onStreamSelected={onStreamSelected}
                onBannerPress={openLeaderboard}
            />
        </div>
    );
});
